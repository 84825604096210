import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import ListHeader from "../../../components/Headers/ListHeader";
import AdminLayout from "../../../layouts/AdminLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetService from "../../../services/getservice";
import Loader from "../../../components/Loader/Loader";
import usePostService from "../../../services/postservice";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
const CategoryStep3 = () => {
  const [formValues,setformvalues]=useState([{
    DisplayName: "",
    Id: "",
    immediateParentId:"",
    categoryId:null
  }]);
  const navigate=useNavigate();
  const [searchParams] = useSearchParams();
  const [inputListStep3, setinputListStep3] = useState([]);
  const [handleChange, sethandleChange] = useState(false);
  const [buttonState, setbuttonState] = useState(false);
  const [errorcategory,seterrorcategory]=useState("");
  const [payload,setpayload]=useState({
    parentIdFromStep1:"",
    step3CategoryArray:[]
  })
  let {response,isFetchItems}=useGetService(`/api/category/getCategoriesInputListStep3/${searchParams.get("topParentId")}`);
  let response2=usePostService(payload,'api/category/createCategoryStep3',buttonState);
   console.log("response2",response2)
  useEffect(() => {
    if(response?.status ==="200-"){
      setinputListStep3(response?.data?.inputListForStep3)
      if(response?.data?.existingCategoryList?.length >0 ){
      setformvalues(response?.data?.existingCategoryList)
      }
    }
  }, [response]);
  useEffect(() => {
    if (response2?.status ==="200-" && buttonState){
     toast.success(response2?.data?.message);
     navigate(`/categorystep?step=4&topParentId=${searchParams.get("topParentId")}`)
     setbuttonState(false)
    }
    else if(buttonState) {
      seterrorcategory(response2?.data?.message)
      setbuttonState(false)
    }
  }, [response2]);
  const handleInput=(e,i,type)=>{
    let newFormValues = [...formValues];
    let idExists = newFormValues.some(obj => obj.Id === e.target.value);
    if (type ==="id" && !idExists){
      const value=inputListStep3?.filter(x=>x.Id ===e.target.value)
      newFormValues[i].Id = value[0].Id;
      newFormValues[i].DisplayName=value[0].DisplayName;
      newFormValues[i].immediateParentId=value[0].immediateParentId;
    }
     else if(newFormValues[i].Id !==""){
    newFormValues[i].DisplayName=e.target.value;
     }
     setformvalues(newFormValues);
     seterrorcategory("");
     sethandleChange(true)
  }
  console.log("formvalues",formValues)
  const handlemore=()=>{
    let temp=[...formValues]
    temp.push({DisplayName: "",Id: "",categoryId:null,immediateParentId:""})
    setformvalues(temp)
    seterrorcategory("")
   }
   const validate=()=>{
    let isError=false;
    let validation = formValues?.every(item => item.DisplayName && item.Id);
    let valueArr = formValues.map(function(item){ return item.DisplayName });
    let isDuplicate = valueArr.some(function(item, idx){ 
     return valueArr.indexOf(item) !== idx 
     });
   if(!validation){
    seterrorcategory("Normal category can't be empty")
    isError=true;
   } 
   if(isDuplicate){
    seterrorcategory("Displayname should be unique for normalcategories")
    isError=true;
   } 
   return isError;
  }
  const handlenext=()=>{
    let isError = validate();
    if (!isError) {
      if(handleChange){
        setbuttonState(true)
      setpayload({
        parentIdFromStep1:searchParams.get("topParentId"),
        step3CategoryArray:formValues
      })
      sethandleChange(false)
      }
      else {
        navigate(`/categorystep?step=4&topParentId=${searchParams.get("topParentId")}`)
        sethandleChange(false)
      }
     
    // navigate('/categorystep?step=4')
    }
  }
  const handleDelete=(i)=>{
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setformvalues(newFormValues);
    seterrorcategory("")
    sethandleChange(true)
  }
  return (
    <AdminLayout name="Category">
    <>
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      {/* <ToastContainer /> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col" >
            <div className="row">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                        Choose normal categories
                        {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                          {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                  <Form className="justify-content-center">
                    {formValues?.map((item,i)=>(
                        <Row className="d-flex justify-content-around" key={i}>
                        <Col lg="5">
                              <FormGroup className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Normal Category
                                </label>
                                <Input
                                  type="select"
                                  name="role_id"
                                  id="selectMulti"
                                  value={item?.Id || ""}
                                  onChange={(e) => handleInput(e,i,"id")}
                                >
                                  <option value="">Choose from normal category</option>
                                  {inputListStep3?.map((item2,i)=>(
                                      <option value={item2?.Id}
                                       key={i}
                                       className={formValues.some(obj => obj.Id === item2.Id)?"option-select-property":null}
                                       >
                                      {item2.DisplayName}
                                    </option>
                                  ))}
                                </Input>
                                {/* {error.role_id ? (
                                  <ErrorMessage errorMsg={error.role_id} />
                                ) : null} */}
                              </FormGroup>
                            </Col>
                            <Col lg="5">
                              <FormGroup className="form-group">
                              <label
                                  className="form-control-label"
                                  // htmlFor="input-first-name"
                                >
                                  Display Name
                                </label>
                                <input 
                                type="text" 
                                className="form-control" 
                                placeholder="Display Name" 
                                value={item.DisplayName}
                                onChange={(e)=>handleInput(e,i,"name")}
                                />
                              </FormGroup>
                            </Col>
                          
                            <Col lg="2">
                              {formValues?.length > 1 && 
                                <div className="btn btn-lg btn-icon" onClick={()=>handleDelete(i)}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                               <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                               </svg>
                                </div>
                              }
                           
                            </Col>
                        </Row>
                    ))}
                    <Row>
                    <Col lg="12">
                    {errorcategory!=="" ? (
                                 <ErrorMessage errorMsg={errorcategory} />
                               ) : null}
                    <div className="add-more-icon" onClick={handlemore}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                          </svg>
                          <span>Add More</span>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                     
                    <FormGroup className="d-flex justify-content-between">
                      <Button color="info" className="no-transform" onClick={()=>navigate(`/categorystep?step=2&topParentId=${searchParams.get("topParentId")}`)}>
                        &#10229; Previous Step 
                      </Button>
                      <Button color="info" className="no-transform" onClick={handlenext}>
                        Next Step &#10230;
                      </Button>
                    </FormGroup>
                   
                  </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
    </>
    </AdminLayout>
  );
};

export default CategoryStep3;
