import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import logo from "../../assets/images/header-logo.png";
import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import { toast } from "react-toastify";
// import { setSessionStorage } from "../../helpers/apihelper";
const ResetPassword = () => {
  const searchParams = useParams();
  const navigate = useNavigate();
  const [buttonState, setbuttonState] = useState(false);
  let [passwordObj, setPasswordObj] = useState({
    new_password: "",
    confirm_password: "",
    token: ""
  });
  const [inputErrorData, setInputErrorData] = useState({
    password: "",
    confirm_password: ""
  });
  const handleInput = (e) => {
    const temp = { ...passwordObj };
    temp[e.target.name] = e.target.value;
    setPasswordObj(temp);
  }

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (passwordObj?.new_password === "") {
      temp.password = "New password can't be empty";
      isError = true;
    }
    if (passwordObj?.new_password?.length > 0 && passwordObj?.new_password?.length < 6) {
      temp.password = "New password must be at least 6 characters";
      isError = true;
    }
    if (passwordObj?.confirm_password === "") {
      temp.confirm_password = "Confirm password can't be empty";
      isError = true;
    }
    if (passwordObj?.confirm_password !== "" && passwordObj?.new_password !== passwordObj?.confirm_password) {
      temp.confirm_password = "New password and confirm password can't be different";
      isError = true;
    }
    setInputErrorData(temp);
    return isError;
  }
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };
  let response = usePostService(passwordObj, '/api/auth/forgot-password', buttonState);
  useEffect(() => {
    if (response !== "") {
      if (response.status !== "200-" && buttonState) {
        setbuttonState(false)
        toast.error(response.data.message)
      }
      else {
        toast.success(response.data.message)
        setbuttonState(false)
        navigate('/signin')
      }
    }
  }, [response]);
  const handleSubmit = () => {
    let isError = validate();
    if (!isError) {
      setPasswordObj({
        ...passwordObj,
        token: searchParams?.token
      })
      setbuttonState(true)
    }
  }
  return (
    <AuthLayout data="Reset Password">
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="text-center" style={{ marginBottom: "25px" }}>
                  <Col className="collapse-brand" xs="12">
                    <img
                      alt="..."
                      src={logo}
                    />
                  </Col>
                </Row>

                <Form role="form">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="new_password"
                        autoComplete="nope"
                        value={passwordObj.new_password}
                        onChange={handleInput}
                        onFocus={handleErrorRemove}
                      />
                    </InputGroup>
                    {inputErrorData.password ? <ErrorMessage errorMsg={inputErrorData.password} /> : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        name="confirm_password"
                        autoComplete="nope"
                        value={passwordObj.confirm_password}
                        onChange={handleInput}
                        onFocus={handleErrorRemove}
                      />
                    </InputGroup>
                    {inputErrorData.confirm_password ? <ErrorMessage errorMsg={inputErrorData.confirm_password} /> : null}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
                <Row className="justify-content-center">
                  <small role="button" onClick={() => navigate('/signin')}>Just remembered? Sign in</small>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </AuthLayout>
  );
};

export default ResetPassword;
