import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import Loader from "../../../components/Loader/Loader";
import ListHeader from "../../../components/Headers/ListHeader";
import AdminLayout from "../../../layouts/AdminLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import useGetService from "../../../services/getservice";
import usePostService from "../../../services/postservice";
import { toast } from "react-toastify";
import CategoryStep2Modal from "../../../components/Modal/CategoryStep2Modal";

const CategoryStep2 = () => {
  const [searchParams] = useSearchParams();
  const [formValues,setformvalues]=useState([{
    Id: "",
    categoryId:null
  }]);
  const [payload,setpayload]=useState({
    parentIdFromStep1:"",
    step2CategoryArray:[]
  })
  const [errorcategory,seterrorcategory]=useState("");
  const [buttonState, setbuttonState] = useState(false);
  const [handleChange, sethandleChange] = useState(false);
  let [UploadImageModal, setUploadImageModal] = useState(false);
  const navigate=useNavigate();
  let {response,isFetchItems}=useGetService(`/api/category/getDisplayNameFromParentIdStep2?topParentId=${searchParams.get("topParentId")}`);
  let response2=usePostService(payload,'api/category/createCategoryStep2',buttonState);
  const [categoryList, setcategoryList] = useState([]);
  useEffect(() => {
    if(response?.status ==="200-"){
      setcategoryList(response?.data?.allCategoriesList)
      if(response?.data?.existingCategoryList?.length >0 ){
      setformvalues(response?.data?.existingCategoryList)
      }
    }
  }, [response]);
  useEffect(() => {
    if (response2?.status ==="200-" && buttonState){
     toast.success(response2?.data?.message);
     navigate(`/categorystep?step=3&topParentId=${searchParams.get("topParentId")}`)
     setbuttonState(false)
    }
    else if(buttonState) {
      seterrorcategory(response2?.data?.message)
      setbuttonState(false)
    }
  }, [response2]);
  console.log("formva",formValues);
  const handlemore=()=>{
   let temp=[...formValues]
   temp.push({Id: "",categoryId:null})
   setformvalues(temp)
   seterrorcategory("")
  }
  const handleDelete=(i)=>{
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setformvalues(newFormValues);
    seterrorcategory("")
    sethandleChange(true)
  }
  const handleInput=(e,i)=>{
    let newFormValues = [...formValues];
    let idExists = newFormValues.some(obj => obj.Id === e.target.value);
    if(!idExists){
      newFormValues[i].Id = e.target.value
    setformvalues(newFormValues);
    seterrorcategory("")
    sethandleChange(true)
    }
   
  }
  const validate=()=>{
    let isError=false;
    let validation = formValues?.every(item => item.Id);
   if(!validation){
    seterrorcategory("Top level category can't be empty")
    isError=true;
   } 
   return isError;
  }
  const handlenext=()=>{
    let isError = validate();
    if (!isError) {
      if(handleChange){
        setpayload({
          parentIdFromStep1:searchParams.get('topParentId'),
          step2CategoryArray:formValues
        })
        setbuttonState(true)
      sethandleChange(false)
      }
      else {
        navigate(`/categorystep?step=3&topParentId=${searchParams.get("topParentId")}`)
        sethandleChange(false)
      }
     
    }
  }
  const handleAddImage=()=>{
    setUploadImageModal(true)
  }
  return (
    <AdminLayout name="Category">
  
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col" >
            <div className="row">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                        Choose top level category
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>


                  <Form className="justify-content-center">
                     {formValues?.map((item,i)=>(
                       <Row className="justify-content-center">
                       <Col lg="5">
                             <FormGroup className="form-group">
                               <label
                                 className="form-control-label"
                                 htmlFor="input-first-name"
                               >
                                 Top level category
                               </label>
                               <Input
                                 type="select"
                                 name="role_id"
                                 id="selectMulti"
                                 value={item.Id || ""}
                                 onChange={(e) => handleInput(e,i)}
                               >
                                 <option value="">Choose from top level category</option>
                                 {categoryList?.map((item2,i)=>(
                                  <option value={item2.Id} key={i}
                                  className={formValues.some(obj => obj.Id === item2.Id)?"option-select-property":null}
                                  >
                                   {item2?.DisplayName !==" "?item2?.DisplayName:item2?.Id}
                                   </option>
                                 ))}
                                 
                               </Input>
                               
                             </FormGroup>
                           </Col>
                         
                           <Col lg="2">
                            {formValues?.length >1 && <div className="btn btn-lg btn-icon" onClick={()=>handleDelete(i)}> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                           <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                           </svg>
                            </div>
                          //    <button className="btn btn-lg btn-icon" onClick={()=>handleDelete(i)}>
                          //    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                          //      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          //      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                          //    </svg>
                          //  </button>
                            }
                          
                           </Col>
                       </Row>
                     ))}
                     <Row className="justify-content-center">
                     <Col lg="7">
                     {errorcategory!=="" ? (
                                 <ErrorMessage errorMsg={errorcategory} />
                               ) : null}
                     </Col>
                     </Row>
                     
{/* 
                    <Row className="d-flex justify-content-center">
                    <Col lg="5">
                          <FormGroup className="form-group">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Category
                            </label>
                            <Input
                              type="select"
                              name="role_id"
                              id="selectMulti"
                              // value={userCreateUpdate.role_id}
                              // onChange={(e) => handleInput(e)}
                            >
                              <option value="">Choose from category</option>
                            </Input>
                            
                          </FormGroup>
                        </Col>
                      
                        <Col lg="2">
                        <button className="btn btn-lg btn-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                          </svg>
                        </button>
                        </Col>
                    </Row> */}
                    <Row>
                    <Col lg="4" >
                        <div className="add-more-icon" onClick={handlemore}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                          </svg>
                          <span>Add More</span>
                        </div>
                     
                      </Col>
                      <Col lg="4" >
                    
                             <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  onClick={handleAddImage}
                                >
                                  Add Image
                                </Button>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                     
                    <FormGroup className="d-flex justify-content-between">
                      <Button color="info" className="no-transform" onClick={()=>navigate(`/categorystep?step=1&topParentId=${searchParams.get("topParentId")}`)}>
                        &#10229; Previous Step 
                      </Button>
                      <Button color="info" className="no-transform" onClick={handlenext}>
                        Next Step &#10230;
                      </Button>
                    </FormGroup>
                   
                  </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      {UploadImageModal ?
       <CategoryStep2Modal
       isOpen={UploadImageModal}
       toggle={() => setUploadImageModal(false)}
      categoryId={searchParams.get("topParentId")}
     ></CategoryStep2Modal>
    :null
    }
     
    </AdminLayout>
  );
};

export default CategoryStep2;
