import React, { useState, useEffect } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import ListHeader from '../../components/Headers/ListHeader';
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Modal,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    ModalFooter,
    Button,
} from "reactstrap";
import useDebounce from "../../components/custom-hooks/useDebounce";
import useConditiongetService from '../../services/conditiongetService';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { removeTags } from '../../helpers/utils';
import useDeleteService from '../../services/deleteservice';
import { toast } from 'react-toastify';

const ServiceList = () => {
    const navigate = useNavigate()
    const [serviceList, setserviceList] = useState([]);
    const [getListData, setListData] = useState(true);
    const [deleteData, setDeleteData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [contactModal, setContactModal] = useState(false);

    const [sortName, setSortName] = useState("createdAt");
    const [totalPage, settotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [asc, setAsc] = useState("asc");

    const [deletedId, setDeletedId] = useState('');

    let debouncedSearchTerm = useDebounce(searchInput, 500);

    const { response2, isFetchItems } = useConditiongetService(
        `/api/admin/getAllService?page=${page}&size=20&page_name=service`,
        getListData
    );
    const deleteResponse = useDeleteService(
        `/api/admin/deleteService/${deletedId}`,
        deleteData
    );
    console.log("r", response2);
    // search=services 3&size=1&page=2
    // useEffect(() => {
    //     setListData(true)
    //     setPage(1)
    //     setSortName("createdAt")
    //     setAsc("asc")
    // }, [debouncedSearchTerm]);
    useEffect(() => {
        if (deleteResponse.status === "200-" && deleteData) {
            toast.success(deleteResponse?.data?.message);
            setListData(true);
            setDeletedId('');
            setDeleteData(false);
        }
    }, [deleteResponse]);

    useEffect(() => {
        if (response2.status === "200-" && getListData) {
            setserviceList(response2?.data?.data)
            setListData(false);
            settotalPage(response2?.data?.totalPages)
        }
        // if (response2.status === "202-Accepted" && getListData) {
        //     setserviceList(response2?.data?.data?.rows)
        //     setListData(false)
        //     settotalPage(0)
        // }
    }, [response2]);
    console.log("s", serviceList, totalPage);
    const onPageChange = (value) => {
        setPage(value)
        setListData(true)
    }

    const deleteService = (id) => {
        setDeletedId(id);
    }

    const handleClose = () => {
        setDeletedId("");
    }

    const deleteHandler = () => {
        setDeleteData(true);
    }

    return (
        <AdminLayout name="Services">
            {isFetchItems && <Loader />}
            <ListHeader />
            {/* Page content */}

            <Modal
                className="modal-dialog-centered w-50" isOpen={deletedId === '' ? false : true} toggle={handleClose}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Confirmation
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleClose}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <h4 className="d-flex justify-content-center">Are you want to delete it?</h4>
                </div>

                <ModalFooter>
                    <Button
                        color="info"
                        className="no-transform "
                        onClick={() => deleteHandler()}
                    >
                        Yes
                    </Button>
                    <Button
                        color="warning"
                        className="no-transform "
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </ModalFooter>
            </Modal>

            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h3 className="mb-0">Services List</h3>
                                    </div>
                                    <div
                                        style={{ textAlign: "right" }}
                                        className="col-md-8 align-right"
                                    >
                                        <UncontrolledDropdown className="inline">
                                            <DropdownToggle
                                                className="btn-icon-only text-dark"
                                                role="button"
                                                size="sm"
                                                color=""
                                            //onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    // href="javascript:void(0)"
                                                    onClick={() => {
                                                        navigate("/createservice")
                                                    }}
                                                >
                                                    Create New Service
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                                style={{
                                    marginBottom: "25px",
                                }}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">
                                            Name
                                        </th>
                                        <th scope="col">
                                            Description
                                        </th>
                                        <th scope="col" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {serviceList?.length > 0 ? serviceList?.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item?.name}</td>
                                            <td>{removeTags(item?.description)?.substring(0, 40)}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                    // onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" container="body">

                                                        <DropdownItem
                                                            onClick={() => navigate(`/services/${item?.id}`)}
                                                        >
                                                            Edit
                                                        </DropdownItem>

                                                        <DropdownItem
                                                            onClick={() => deleteService(item?.id)}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) :
                                        <tr>
                                            <td>
                                                Sorry no service found
                                            </td>

                                        </tr>
                                    }
                                </tbody>

                            </Table>
                            <CardFooter className="py-4">
                                {totalPage > 1 ?
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                className={page < 3 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout>
    )
}

export default ServiceList;