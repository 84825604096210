import React, { useState, useEffect } from 'react'
import AdminLayout from '../../layouts/AdminLayout';
import ListHeader from '../../components/Headers/ListHeader';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Table,
} from "reactstrap";
import { useNavigate, useParams } from 'react-router-dom';
import useGetService from '../../services/getservice';
import { InputGroup } from 'reactstrap-date-picker/lib/input/InputGroup';
import Loader from "../../components/Loader/Loader";
import usePutService from '../../services/putservice';
import { toast } from 'react-toastify';
import { getFormattedDate } from '../../helpers/utils';
import ConfirmationDialog from '../../components/confirmation.dialog';

const ChangePaymentStatus = () => {

  let { paymentId } = useParams();
  const navigate = useNavigate();

  const [checkboxState, setCheckboxState] = useState(false);
  const [updateButtonState, setUpdateButtonState] = useState(false);
  const [isOpen, setisOpen] = useState(false);

  const { response: getPaymentDetails, isFetchItems } = useGetService(`/api/admin/payment/getPaymentById/${paymentId}`);

  let payload = {
    "status": checkboxState ? '1' : getPaymentDetails?.data?.data?.status
  }

  const updatedResponse = usePutService(
    payload,
    `/api/admin/updatePymentStatus/${paymentId}`,
    updateButtonState
  );

  useEffect(() => {
    if (updatedResponse?.status === "200-" && updateButtonState) {
      setUpdateButtonState(false);
      toast.success(updatedResponse.data.message);
      navigate("/payment-management");
    }
  }, [updatedResponse]);

  return (
    <AdminLayout name="Payment Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <ConfirmationDialog
        isOpen={isOpen}
        toggle={() => setisOpen(false)}
        action={"change the status"}
        onClick={() => setUpdateButtonState(true)}
        dataType={'payment'}
      ></ConfirmationDialog>

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col" >
            <div className="row">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Payment Details
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Payment information
                      </h6>
                      <div className="pl-lg-4">

                        <div className="scroll-table-area">
                          <Table className="table-views mb-4">

                            <tr>
                              <td className="table-views--body">
                                <h4 className="table-views--title">Deponent Name</h4>
                                {getPaymentDetails?.data?.data?.case_deposition?.deponent_name || '--'}
                              </td>
                              <td>
                                <h4 className="table-views--title">Case Name</h4>
                                {getPaymentDetails?.data?.data?.case_deposition?.case?.case_name || '--'}
                              </td>
                            </tr>

                            <tr>
                              <td className="table-views--body">
                                <h4 className="table-views--title">Payee Name</h4>
                                {getPaymentDetails?.data?.data?.case_deposition?.user?.first_name} {getPaymentDetails?.data?.data?.case_deposition?.user?.last_name}
                              </td>
                              <td className="table-views--body">
                                <h4 className="table-views--title">Receiver</h4>
                                {getPaymentDetails?.data?.data?.user?.first_name || ''} {getPaymentDetails?.data?.data?.user?.last_name || ''}
                              </td>

                            </tr>

                            <tr>
                              <td className="table-views--body">
                                <h4 className="table-views--title">Amount ($)</h4>
                                {getPaymentDetails?.data?.data?.amount || ''}
                              </td>

                              <td className="table-views--body">
                                <h4 className="table-views--title">Transaction Date</h4>
                                {getPaymentDetails?.data?.data?.payment_received_date ? getFormattedDate(getPaymentDetails?.data?.data?.payment_received_date) : '--'}
                              </td>
                            </tr>

                            <tr>
                              <td className="table-views--body">
                                <h4 className="table-views--title">Status</h4>
                                {getPaymentDetails?.data?.data?.status == '0' ? 'Pending' : getPaymentDetails?.data?.data?.status == '2' ? 'Cancel' : getPaymentDetails?.data?.data?.status == '3' ? 'Refund' : 'Paid'}
                              </td>
                            </tr>

                          </Table>
                        </div>

                        {getPaymentDetails?.data?.data?.status !== '1' &&
                          <div className='ml-4'>
                            <InputGroup>
                              <p className="ml-4">Confirm the payment</p>
                              <Input
                                type="checkbox"
                                name="status"
                                style={{ marginLeft: '0', width: '15px', height: '16px' }}
                                id="selectMulti"
                                checked={checkboxState}
                                onChange={() => setCheckboxState(!checkboxState)}
                              >
                              </Input>
                            </InputGroup>
                          </div>
                        }

                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <FormGroup className="d-flex justify-content-end">
                          {getPaymentDetails?.data?.data?.status !== '1' &&
                            <Button
                              color="info"
                              className="no-transform"
                              disabled={!checkboxState}
                              onClick={() => setisOpen(true)}
                            >
                              Update
                            </Button>
                          }
                          <Button
                            color="warning"
                            className="no-transform"
                            onClick={() => navigate("/payment-management")}
                          >
                            Cancel
                          </Button>
                        </FormGroup>
                      </div>

                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Row>
      </Container>


    </AdminLayout>
  )
}

export default ChangePaymentStatus;