import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import useConditiongetService from "../../services/conditiongetService";
const CategoryList = () => {
  const [alldata, setAlldata] = useState([]);
  const [getbutton, setgetbutton] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [buttonState, setbuttonState] = useState(true);
  const [condition, setcondition] = useState("saved");
  const [page,setPage]=useState(1)
  const navigate=useNavigate();
  let {response2:{response},isLoadingProducts,isFetchItems}=useConditiongetService(`api/category/getAllTopCategories?mode=${condition}&page=${page}`,buttonState);
  let {response2}=useConditiongetService(`api/category/deleteTopParentId/${deleteid}`,getbutton);
  useEffect(() => {
    if (response2?.status ==="200-" &&buttonState){
      // console.warn("bad")
     setAlldata(response2?.data?.list)
     setbuttonState(false)
    }
  }, [response2]);
  console.log("cond",condition);
  const handleInput = async(e) => {
    setcondition(e.target.value)
    setbuttonState(true)
    // setSearchText("")
    // setCurrentPage(1)
    // await listUsersLogsData(0,10,"",e.target.value)
  };
  console.log("delete",alldata)
  useEffect(() => {
    if(response2 !=="" && getbutton){
     setAlldata(response2.data?.remainingTopParentCategories)
     setgetbutton(false)
    }
  }, [response2]);
  const handleDelete=(i)=>{
   setdeleteid(i)
   setgetbutton(true)
  }
  console.log("respon",response)
  return (
    <AdminLayout name="Category">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-8 d-flex align-items-center">
                    <h3 className="mb-0">Categories List</h3>
                    <div className="col-md-4">
                      <select id="input-experience" 
                      name="experience" 
                      autoComplete="nope" 
                      className="form-control-alternative form-control" 
                      fdprocessedid="2aoekh"
                      value={condition}
                      onChange={(e) => handleInput(e)}
                      >
                        <option value="saved">Saved</option>
                        <option value="draft">Draft</option>
                      </select>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "80%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                        //   onChange={(e) => onSearchChange(e.target.value)}
                        />
                      </div>
                    </div>
                    <UncontrolledDropdown className="inline">
                        <DropdownToggle
                          className="btn-icon-only text-dark"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>

                          <DropdownItem
                            onClick={() => navigate('/categorystep?step=1')}
                          >
                            Add New Category
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive style={{
                marginBottom: '25px'
              }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Display Name</th>
                    <th scope="col">Top level category</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {alldata?.length >0 ?
                  alldata?.map((item,i)=>(
                    <tr key={i}>
                      <td>{item.name}</td>
                       <td>
                        {item?.amazonTopParents?.length >0? 
                        item?.amazonTopParents?.map((item2,i2)=>(
                          <span key={i2}>
                            {item2?.name}{item?.amazonTopParents?.length -1 !==i2? ",":null}
                          </span>
                         
                        ))
                        :"Not found"}
                       </td>
                       <td>{condition ==="saved"?"Active":"Not active"}</td>
                      <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" container="body">
                              {condition ==="saved"?<DropdownItem
                            onClick={()=>navigate(`/category/${item.id}`)}
                                >
                                  View
                                </DropdownItem>:null}
                            
                                <DropdownItem
                            onClick={()=>navigate(`/categorystep?step=1&topParentId=${item?.id}`)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                            onClick={()=>handleDelete(item?.id)}
                                >
                                  Delete
                                </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                    </tr>
                  ))
                  :null}
               
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                     className="disabled"
                    //   className={currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        //   onPageChange(currentPage - 2);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(5)].map((_, i) => {
                      return (
                        <PaginationItem
                          key={i}
                          className="active"
                        //   className={currentPage === i + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                            //   onPageChange(i);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                    className="disabled"
                    //   className={currentPage === pages ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        //   onPageChange(currentPage);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      
    </AdminLayout>
   
  );
};

export default CategoryList;
