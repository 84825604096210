import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import ListHeader from "../../components/Headers/ListHeader.js";
import AdminLayout from "../../layouts/AdminLayout.js";
import usePutService from "../../services/putservice";
import useGetService from "../../services/getservice";
import { toast, ToastContainer } from "react-toastify";
import { getStorage, setSessionStorage } from "../../helpers/apihelper";
import ErrorMessage from "../../components/Validation/ErrorMessage.js";

const Profile = () => {
  const [passwordChange, setpasswordChange] = useState(false);
  const [buttonState, setbuttonState] = useState(false);
  const [buttonState2, setbuttonState2] = useState(false);
  const token = getStorage("token");
  const adminid = JSON.parse(getStorage("admindetails"))?.id;
  console.log("admindeta", adminid);
  // let [user_id, setUserId] = useState("");
  let [userCreateUpdate, setUserCreateUpdate] = useState({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });
  let [userPasswordUpdate, setUserPasswordUpdate] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
    adminPkId: ""
  });

  let [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    phone: '',
    email: "",
    password: "",
    new_password: "",
    confirm_password: ""
  });

  // let response = usePostService(userPasswordUpdate, 'api/admin/changePassword', buttonState2);
  // let response2 = usePostService(userCreateUpdate, `api/admin/changeUsernamePassword/${adminid}`, buttonState);
  // console.log("respon", response2)
  // console.log(userData);

  let payload = {
    "first_name": userCreateUpdate.first_name,
    "last_name": userCreateUpdate.last_name,
    "phone": userCreateUpdate.phone
  };

  let passwordPayload = {
    "password": userPasswordUpdate.password,
    "newPassword": userPasswordUpdate.new_password,
    "confirmPassword": userPasswordUpdate.confirm_password,
  };

  let updatePasswordResponse = usePutService(passwordPayload, `/api/admin/changePassword/${userCreateUpdate.id}`, buttonState2);
  let updateresponse = usePutService(payload, `/api/admin/update_profile/${userCreateUpdate.id}`, buttonState);
  let { response, isFetchItems } = useGetService(`/api/admin/profile/${adminid}`);
  useEffect(() => {
    if (response?.status === "200-") {
      let userDetails = response?.data?.data;
      setUserCreateUpdate({
        id: userDetails?.id,
        first_name: userDetails?.first_name,
        last_name: userDetails?.last_name,
        email: userDetails?.email,
        phone: userDetails?.phone
      })
    }
  }, [response]);

  useEffect(() => {
    if (updateresponse?.status === "200-" && buttonState) {
      toast.success(updateresponse?.data?.message)
      setbuttonState(false);
    } else {
      toast.error(updateresponse?.response?.data?.message);
    }
  }, [updateresponse]);

  useEffect(() => {
    if (updatePasswordResponse?.status === "200-" && buttonState2) {
      toast.success(updatePasswordResponse?.data?.message)
      setbuttonState2(false);
    } else {
      toast.error(updatePasswordResponse?.data?.message);
      setbuttonState2(false);
    }
  }, [updatePasswordResponse]);
  // useEffect(() => {
  //   if (userData !== "") {
  //     let userDetails = userData?.response;
  //     console.log("userDetails===>>>", userDetails);
  //     if (userDetails.status !== "200-") {
  //       toast.error(userDetails?.data?.message)
  //     }
  //     else {
  //       console.log("Goood===>>>", userDetails?.data);
  //       setUserCreateUpdate({
  //         id: userDetails?.id,
  //         first_name: userDetails?.first_name,
  //         last_name: userDetails?.last_name,
  //         email: userDetails?.email,
  //         phone: userDetails?.phone
  //       })
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (response2 !== "") {
  //     setbuttonState(false)
  //     if (response2.status !== "200-") {
  //       toast.error(response2.data.message)
  //     }
  //     else {
  //       toast.success(response2.data.message)
  //       setSessionStorage("admindetails", JSON.stringify(response2.data.updatedAdmin));
  //     }
  //   }
  // }, [response2]);

  // useEffect(() => {
  //   if (response !== "") {
  //     setbuttonState2(false)
  //     if (response.status !== "200-") {
  //       toast.error(response.data.message)
  //     }
  //     else {
  //       toast.success(response.data.message)
  //       setpasswordChange(false);
  //     }
  //   }
  // }, [response]);

  const validate = () => {
    let isError = false;
    let temp = { ...errors };
    if (userPasswordUpdate?.password?.trim() === "" || userPasswordUpdate?.password?.trim()?.length < 6) {
      temp.password = "Current password can't be less than 6 characters";
      isError = true;
    }
    if (userPasswordUpdate?.new_password?.trim() === "" || userPasswordUpdate?.new_password?.trim()?.length < 6) {
      temp.new_password = "New password can't be less than 6 characters";
      isError = true;
    }
    if (userPasswordUpdate?.password?.trim() === userPasswordUpdate?.new_password?.trim()) {
      temp.new_password = "Current password and new password can't be same";
      isError = true;
    }
    if (userPasswordUpdate?.confirm_password?.trim() === "" || userPasswordUpdate?.confirm_password?.trim()?.length < 6) {
      temp.confirm_password = "Confirm password can't be less than 6 characters";
      isError = true;
    }
    if (userPasswordUpdate?.confirm_password?.trim() !== userPasswordUpdate?.new_password?.trim()) {
      temp.confirm_password = "Confirm password and new password can't be different";
      isError = true;
    }
    setErrors(temp);
    return isError;
  }
  const validate2 = () => {
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneReg = /^[0-9]*$/
    let isError = false;
    let temp = { ...errors };
    if (userCreateUpdate?.first_name?.trim() === "") {
      temp.firstname = "First name can't be empty";
      isError = true;
    }

    if (userCreateUpdate?.last_name?.trim() === "") {
      temp.lastname = "Last name can't be empty";
      isError = true;
    }
    console.log("userCreateUpdate?.phone", userCreateUpdate?.phone + ''.length);
    if (userCreateUpdate?.phone + ''.trim() === "") {
      temp.phone = "Phone name can't be empty";
      isError = true;
    } else if (!phoneReg.test(userCreateUpdate?.phone) || (userCreateUpdate?.phone + '').length !== 10) {
      temp.phone = "Phone must be a valid phone no";
      isError = true;
    }

    if (userCreateUpdate?.email?.trim() === "") {
      temp.email = "Email can't be empty";
      isError = true;
    } else if (!emailReg.test(userCreateUpdate?.email)) {
      temp.email = "Email must be a valid email address";
      isError = true;
    }
    setErrors(temp);
    return isError;
  }

  const handlePasswordChange = () => {
    let isError = validate();
    if (!isError) {
      setbuttonState2(true)
      // setUserPasswordUpdate({
      //   ...userPasswordUpdate,
      //   adminPkId: adminid
      // })
    }
  }

  const handleEmailChange = () => {
    let isError = validate2();
    if (!isError) {
      setbuttonState(true)
    }
  }

  const handleInput2 = (e) => {
    setUserPasswordUpdate({
      ...userPasswordUpdate,
      [e.target.name]: e.target.value
    })
    setErrors({
      ...errors,
      [e.target.name]: ""
    })
  }

  const handleInput = (e) => {
    setUserCreateUpdate({
      ...userCreateUpdate,
      [e.target.name]: e.target.value
    })
    setErrors({
      ...errors,
      [e.target.name]: ""
    })
  }

  return (
    <AdminLayout>
      <ListHeader />
      <ToastContainer />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="row">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">My Profile</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                First name
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                name="first_name"
                                autoComplete="nope"
                                value={userCreateUpdate.first_name}
                                onChange={handleInput}
                              />
                              {errors.firstname ? (
                                <ErrorMessage errorMsg={errors.firstname} />
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Last name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                placeholder="Last name"
                                type="text"
                                name="last_name"
                                autoComplete="nope"
                                value={userCreateUpdate.last_name}
                                onChange={handleInput}
                              />
                              {errors.lastname ? (
                                <ErrorMessage errorMsg={errors.lastname} />
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Phone
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-phone"
                                placeholder="Phone no"
                                type="number"
                                name="phone"
                                autoComplete="nope"
                                value={userCreateUpdate.phone}
                                onChange={handleInput}
                              />
                              {errors.phone ? (
                                <ErrorMessage errorMsg={errors.phone} />
                              ) : null}
                            </FormGroup>
                          </Col> */}
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                readOnly
                                placeholder="Email"
                                type="email"
                                name="email"
                                autoComplete="nope"
                                value={userCreateUpdate.email}
                                onChange={handleInput}
                              />
                              {errors.email ? (
                                <ErrorMessage errorMsg={errors.email} />
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-phone"
                              >
                                Phone
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-phone"
                                placeholder="Phone"
                                type="number"
                                name="phone"
                                autoComplete="nope"
                                value={userCreateUpdate.phone}
                                onChange={handleInput}
                              />
                              {errors.phone ? (
                                <ErrorMessage errorMsg={errors.phone} />
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        {/* 
                        <Row>

                        </Row> */}
                      </div>
                      <hr className="my-4" />
                      <div className="d-flex">
                        <div className="pl-lg-4">
                          <FormGroup>
                            <Button
                              color="info"
                              onClick={handleEmailChange}
                            >
                              Save Changes
                            </Button>
                          </FormGroup>
                        </div>
                        {!passwordChange &&
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Button
                                color="info"
                                onClick={() => setpasswordChange(true)}
                              >
                                Want to Change Password
                              </Button>
                            </FormGroup>
                          </div>
                        }

                      </div>
                      {passwordChange &&
                        <>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-currentpassword"
                                >
                                  Current Password
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-currentpassword"
                                  placeholder="Current password"
                                  name="password"
                                  type="password"
                                  autoComplete="nope"
                                  value={userPasswordUpdate.password}
                                  onChange={handleInput2}
                                />
                                {errors.password ? (
                                  <ErrorMessage errorMsg={errors.password} />
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-newpassword"
                                >
                                  New Password
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-newpassword"
                                  placeholder="New password"
                                  type="password"
                                  name="new_password"
                                  autoComplete="nope"
                                  value={userPasswordUpdate.new_password}
                                  onChange={handleInput2}
                                />
                                {errors.new_password ? (
                                  <ErrorMessage errorMsg={errors.new_password} />
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="inputconfirmPassword"
                                >
                                  Confirm Password
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="inputconfirmPassword"
                                  placeholder="Confirm password"
                                  type="password"
                                  name="confirm_password"
                                  autoComplete="nope"
                                  value={userPasswordUpdate.confirm_password}
                                  onChange={handleInput2}
                                />
                                {errors.confirm_password ? (
                                  <ErrorMessage errorMsg={errors.confirm_password} />
                                ) : null}
                              </FormGroup>

                            </Col>
                          </Row>
                          <div className="pl-lg-4">
                            <FormGroup>
                              <Button
                                color="info"
                                onClick={handlePasswordChange}
                              >
                                Change Password
                              </Button>
                            </FormGroup>
                          </div>
                        </>
                      }

                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default Profile;
