import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Label,
    FormText,
} from 'reactstrap';
import AdminLayout from '../../layouts/AdminLayout';
import ErrorMessage from "../../components/Validation/ErrorMessage";
import ListHeader from '../../components/Headers/ListHeader';
import useGetService from '../../services/getservice';
import usePostService from "../../services/postservice";

const AddContactDetails = () => {
    let [fromData, setFromData] = useState({
        id: null,
        email: "",
        address: "",
        phone: "",
        fb_link: "",
        linkedin_link: "",
        created_by: ""
    });

    let [error, setError] = useState({
        emailError: "",
        addressError: "",
        phoneError: "",
        fbLinkError: "",
        linkedinLinkError: "",
        createdByError: ""
    });
    const [mode, setMode] = useState('create');
    const [buttonState, setButtonState] = useState(false);

    const { response, isFetchItems } = useGetService(
        `/api/contactDetail/getContactDetails`
    );

    const createdResponse = usePostService(
        fromData,
        `/api/contactDetail/createContactDetails`,
        buttonState
    );

    useEffect(() => {
        if (response?.status === "200-") {
            let contactDetails = response?.data?.data;
            if (contactDetails.length > 0) {
                setMode('update');
                setFromData({
                    id: 1,
                    email: contactDetails[0]?.email,
                    phone: contactDetails[0]?.phone ? contactDetails[0]?.phone + '' : '',
                    address: contactDetails[0]?.address,
                    fb_link: contactDetails[0]?.fb_link,
                    linkedin_link: contactDetails[0]?.linkedin_link,
                    created_by: contactDetails[0]?.created_by,
                })
            }
        }
    }, [response]);

    useEffect(() => {
        if (createdResponse?.status === "200-" && buttonState) {
            toast.success(createdResponse?.data?.message);
            setButtonState(false);
        }
    }, [createdResponse]);

    const handleInput = (e) => {
        setFromData({ ...fromData, [e.target.name]: e.target.value });
    }

    function validateEmail(email) {
        if (email !== '') {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        } else {
            return false;
        }
    }

    const validate = () => {
        let isError = false;
        let errorMessages = {
            emailError: "",
            addressError: "",
            phoneError: "",
            fbLinkError: "",
            linkedinLinkError: "",
            createdByError: ""
        };
        let { email, phone, address, created_by, fb_link, linkedin_link } = fromData;
        if (!validateEmail(email)) {
            errorMessages = { ...errorMessages, emailError: 'Enter valid email' }
            isError = true;
        }
        if (phone === '') {
            errorMessages = { ...errorMessages, emailError: 'Enter valid phone no' }
            isError = true;
        }
        if (address === '') {
            errorMessages = { ...errorMessages, emailError: 'Enter valid address' }
            isError = true;
        }
        if (created_by === '') {
            errorMessages = { ...errorMessages, emailError: 'Enter valid created by' }
            isError = true;
        }
        if (fb_link === '') {
            errorMessages = { ...errorMessages, emailError: 'Enter valid link' }
            isError = true;
        }
        if (linkedin_link === '') {
            errorMessages = { ...errorMessages, emailError: 'Enter valid link' }
            isError = true;
        }
        setError((preData) => ({ ...preData, ...errorMessages }))
        return isError;
    }

    const handleSubmit = () => {
        let isError = validate();
        if (!isError) {
            setButtonState(true);
        }
    }

    return (
        <AdminLayout name="Contact Details">
            <ListHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}

                <Row>
                    <div className="col">
                        <div className="row">
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">
                                                    Contact Details
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                Contact information
                                            </h6>
                                            <div className="pl-lg-4">



                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Email
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-email"
                                                                placeholder="Email"
                                                                type="email"
                                                                name="email"
                                                                autoComplete="nope"
                                                                value={fromData.email}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.emailError ? (
                                                                <ErrorMessage errorMsg={error.emailError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-phone"
                                                            >
                                                                Phone No
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-phone"
                                                                placeholder="Phone No"
                                                                type="number"
                                                                name="phone"
                                                                autoComplete="nope"
                                                                value={fromData.phone}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.phoneError ? (
                                                                <ErrorMessage errorMsg={error.phoneError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-address"
                                                            >
                                                                Address
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-address"
                                                                placeholder="Address"
                                                                type="textarea"
                                                                name="address"
                                                                autoComplete="nope"
                                                                value={fromData.address}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.addressError ? (
                                                                <ErrorMessage errorMsg={error.addressError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-createdby"
                                                            >
                                                                Created By
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-createdby"
                                                                placeholder="Created By"
                                                                type="text"
                                                                name="created_by"
                                                                autoComplete="nope"
                                                                value={fromData.created_by}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.createdByError ? (
                                                                <ErrorMessage errorMsg={error.createdByError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-facebook"
                                                            >
                                                                Facebook Link
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-facebook"
                                                                placeholder="Facebook Link"
                                                                type="url"
                                                                name="fb_link"
                                                                autoComplete="nope"
                                                                value={fromData.fb_link}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.fbLinkError ? (
                                                                <ErrorMessage errorMsg={error.fbLinkError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-Linkedin"
                                                            >
                                                                Linkedin Link
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=""
                                                                id="input-Linkedin"
                                                                placeholder="Linkedin Link"
                                                                type="url"
                                                                name="linkedin_link"
                                                                autoComplete="nope"
                                                                value={fromData.linkedin_link}
                                                                onChange={(e) => handleInput(e)}
                                                            />
                                                            {error?.linkedinLinkError ? (
                                                                <ErrorMessage errorMsg={error.linkedinLinkError} />
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>


                                            </div>
                                            <hr className="my-4" />
                                            <div className="pl-lg-4">
                                                <FormGroup className="d-flex justify-content-end">
                                                    <Button
                                                        color="info"
                                                        className="no-transform"
                                                        onClick={() => handleSubmit()}
                                                    >
                                                        {mode === "update" ? 'Update' : 'Submit'}
                                                    </Button>
                                                </FormGroup>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </div>
                </Row>
            </Container>
        </AdminLayout>
    )
}

export default AddContactDetails;