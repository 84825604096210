import React, { useState,useEffect} from "react";
import { Button, FormGroup, Modal, ModalFooter} from "reactstrap";
import Placeholder from "../../assets/images/team-4.jpg";
import useModalGetService from "../../services/modalgetService";
import { imagePath } from "../../config/filepath";
import useModalPostService from "../../services/modaPostlService";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
const CategoryStep5Modal = ({ isOpen, toggle,categoryId }) => {
  const [buttonImageState, setbuttonImageState] = useState(false);
  const [Image, setImage] = useState("");
  const [OverviewImage, setOverviewImage] = useState("");
  const [ImageResponse, setImageResponse] = useState("");
  const [OverViewImageResponse, setOverViewImageResponse] = useState("");
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [AdviceTitle, setAdviceTitle] = useState("");
  const [AdviceDescription, setAdviceDescription] = useState("");
  const [OverviewDescription, setOverviewDescription] = useState("");
  const [OverviewImageTitle, setOverviewImageTitle] = useState("");
  const [CostsDescription, setCostsDescription] = useState("");
  const [BrandDescription, setBrandDescription] = useState("");
  const [ReportsDescription, setReportDescription] = useState("");
  const [detailspayload,setdetailspayload]=useState("")
  let {response5,isFetchItems}=useModalGetService(`api/category/getStep5ComparisonInfo/${categoryId}`);
  let response3=useModalPostService(detailspayload,`api/category/createStep5infoSubcategory/${categoryId}`,buttonImageState);
  const [apicall, setapicall] = useState(false);
  console.log("res",response5);
   useEffect(() => {
    if(response5?.status ==="200-"){  
        setImageResponse(response5?.data?.subCategoryCompareInfo?.CategoryImage)
        setOverViewImageResponse(response5?.data?.subCategoryCompareInfo?.OverviewImage)
        setTitle(response5?.data?.subCategoryCompareInfo?.Title)
        setDescription(response5?.data?.subCategoryCompareInfo?.Description)
        setAdviceTitle(response5?.data?.subCategoryCompareInfo?.AdviceTitle)
        setAdviceDescription(response5?.data?.subCategoryCompareInfo?.AdviceDescription)
        setOverviewDescription(response5?.data?.subCategoryCompareInfo?.OverviewDescription)
        setOverviewImageTitle(response5?.data?.subCategoryCompareInfo?.OverviewImageTitle)
        setCostsDescription(response5?.data?.subCategoryCompareInfo?.CostsDescription)
        setBrandDescription(response5?.data?.subCategoryCompareInfo?.BrandDescription)
        setReportDescription(response5?.data?.subCategoryCompareInfo?.ReportsDescription)
    }
  }, [response5]);
// console.log("Image",Image);
// console.log("OverviewImage",OverviewImage);
// console.log("ImageResponse",ImageResponse);
// console.log("OverviewImageResponse",OverViewImageResponse);
// console.log("Title",Title);
// console.log("Description",Description);
// console.log("AdviceTitle",AdviceTitle);
// console.log("AdviceDescription",AdviceDescription);
// console.log("OverviewDescription",OverviewDescription);
// console.log("OverviewImageTitle",OverviewImageTitle);
// console.log("CostsDescription",CostsDescription);
// console.log("BrandDescription",BrandDescription);
// console.log("ReportDescription",ReportsDescription);
console.log("detailsPayload2",detailspayload);
  const handleClose=()=>{
    setImageResponse("")
    setImage("")
    toggle()
    setapicall(false)
 }
  useEffect(() => {
    if (response3?.status ==="200-")
    {
      toast.success(response3?.data?.message)
      handleClose()
      setbuttonImageState(false)
    }
  }, [response3]);
  const handleFieldImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageResponse("")
  };
  const handleFieldOverviewImageChange = (e) => {
    setOverviewImage(e.target.files[0]);
    setOverViewImageResponse("")
  };
  const handleSave=()=>{
    setapicall(true)
    var formData = new FormData();
    formData.append("CategoryImage",Image);
    formData.append("Title",Title);
    formData.append("Description",Description);
    formData.append("AdviceTitle",AdviceTitle);
    formData.append("AdviceDescription",AdviceDescription);
    formData.append("OverviewDescription",OverviewDescription);
    formData.append("OverviewImage",OverviewImage);
    formData.append("OverviewImageTitle",OverviewImageTitle);
    formData.append("CostsDescription",CostsDescription);
    formData.append("BrandDescription",BrandDescription);
    formData.append("ReportsDescription",ReportsDescription);
    setdetailspayload(formData)
    setbuttonImageState(true);
  }
  const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"]
  ];
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={handleClose}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add Details about Sub Category
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center">Upload Sub Category Image</div>
        <div className="d-flex justify-content-center align-items-center">
        <div
          className="card-img-top"
          style={{
            borderRadius: "50%",
            width: "151px",
            height: "151px",
            overflow: "hidden",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
             src={ Image !== "" ? URL?.createObjectURL(Image) :
             ImageResponse !== ""
               ? imagePath+ImageResponse
             :Placeholder }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
          />{" "}
          <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
        </div>
        <div className="d-flex justify-content-center">
          <label className="fileUpload">
            <input
              type="file"
              id="image"
              name="logo"
              hidden
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFieldImageChange}
              multiple={false}
              style={{
                display: "none",
              }}
            />
            <span
              className="d-flex justify-content-center"
              style={{ display: "inline-block", color: "red" }}
            >
                {(Image !==""||ImageResponse !=="" ) ? "Change Image":"Upload Image"}
            
            </span>
          </label>
        </div>
        </div>
        <div>
            <div className="d-flex justify-content-center mb-2">Comparison title</div>
            <div>
            <FormGroup className="form-group">
                              <input type="text" 
                              className="form-control" 
                              placeholder="Comparison title"
                              value={Title}
                              onChange={(e)=>setTitle(e.target.value)}
                            //   onFocus={()=>seterrordisplay("")}
                              />
                                 <div style={{float:"left"}}>
                            {/* {errordisplay !=="" ? <ErrorMessage errorMsg={errordisplay} /> : null} */}
                            </div>
            </FormGroup>
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center mb-2">Comparison description</div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={Description}
            onChange={setDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }

              }}
             />
            </div>
        </div>
        <div>
        <div className="d-flex justify-content-center mb-2">Comparison advice title</div>
            <div>
            <FormGroup className="form-group">
                              <input type="text" 
                              className="form-control" 
                              placeholder="Comparison advice title"
                              value={AdviceTitle}
                              onChange={(e)=>setAdviceTitle(e.target.value)}
                            //   onFocus={()=>seterrordisplay("")}
                              />
                                 <div style={{float:"left"}}>
                            {/* {errordisplay !=="" ? <ErrorMessage errorMsg={errordisplay} /> : null} */}
                            </div>
                            </FormGroup>
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center mb-2">Comparison advice description</div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={AdviceDescription}
            onChange={setAdviceDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }

              }}
             />
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center mb-2">Comparison advice overview description</div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={OverviewDescription}
            onChange={setOverviewDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }

              }}
             />
            </div>
        </div>
        <div className="d-flex justify-content-center">Comparison Advice Overview Image</div>
        <div className="d-flex justify-content-center align-items-center">
        <div
          className="card-img-top"
          style={{
            borderRadius: "50%",
            width: "151px",
            height: "151px",
            overflow: "hidden",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
             src={ OverviewImage !== "" ? URL?.createObjectURL(OverviewImage) :
             OverViewImageResponse !== ""
               ? imagePath+OverViewImageResponse
             :Placeholder }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
          />{" "}
          <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
        </div>
        <div className="d-flex justify-content-center">
          <label className="fileUpload">
            <input
              type="file"
              id="image"
              name="logo"
              hidden
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFieldOverviewImageChange}
              multiple={false}
              style={{
                display: "none",
              }}
            />
            <span
              className="d-flex justify-content-center"
              style={{ display: "inline-block", color: "red" }}
            >
                {(OverviewImage !==""||OverViewImageResponse !=="" ) ? "Change Image":"Upload Image"}
            
            </span>
          </label>
        </div>
        </div>
        <div>
            <div className="d-flex justify-content-center mb-2">Comparison advice overview image title</div>
            <div>
            <FormGroup className="form-group">
                              <input type="text" 
                              className="form-control" 
                              placeholder="Comparison advice overview image title"
                              value={OverviewImageTitle}
                              onChange={(e)=>setOverviewImageTitle(e.target.value)}
                            //   onFocus={()=>seterrordisplay("")}
                              />
                                 <div style={{float:"left"}}>
                            {/* {errordisplay !=="" ? <ErrorMessage errorMsg={errordisplay} /> : null} */}
                            </div>
            </FormGroup>
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center">
            Comparison advice costs description
            </div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={CostsDescription}
             onChange={setCostsDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }
                // "emoji-toolbar": true,
                // "emoji-textarea": false,
                // "emoji-shortname": true
              }}
             />
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center">
            Comparison advice brand description
            </div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={BrandDescription}
             onChange={setBrandDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }
              }}
             />
            </div>
        </div>
        <div>
            <div className="d-flex justify-content-center">
            Comparison advice report description
            </div>
            <div>
            <ReactQuill 
            theme="snow" 
            value={ReportsDescription}
             onChange={setReportDescription}
             placeholder="Start writing..."
             modules={{
                toolbar: {
                  container: TOOLBAR_OPTIONS
                }
              }}
             />
            </div>
        </div>
      </div>
      <hr/>
      <ModalFooter>
        <Button
          color="info"
          className="no-transform "
            disabled={apicall}
            onClick={handleSave}
        >
          Save
        </Button>
        <Button
          color="warning"
          className="no-transform "
          //   disabled={active !== item.categoryId}
            onClick={handleClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategoryStep5Modal;
