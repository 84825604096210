import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router";
// core components
import Select, { components } from 'react-select';
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import MultiSelect from "../../components/Select/MultiSelect";
import SingleSelect from "../../components/Select/SingleSelect";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useGetService from "../../services/getservice";
import useConditiongetService from "../../services/conditiongetService";
import AddExternalLawyer from "./AddExternalLawyer";
import useParallelService from "../../services/useParallelService";
import { MenuListFooter, MenuList } from '../../helpers/selectpaginationhelper';
import useDebounce from "../../components/custom-hooks/useDebounce";
import useParallelServiceConditionally from "../../services/useParallelServiceConditionally";
import { validate_email } from "../../helpers/utils";

const fileTypes = ["PDF"];

const CreateUpdateCase = (props) => {
  let { caseId } = useParams();
  let navigate = useNavigate();
  let [fromData, setFromData] = useState({
    id: caseId || "",
    requestedBy: [],
    virtualDepo: true,
    personDepo: false,
    defendantLawyer: [],
    plaintiffLawyer: [],
    defendant_client: [{ name: "", email: "" }],
    plaintiff_client: [{ name: "", email: "" }],
    // witness: [{ name: '', email: "" }],
    casename: "",
    court: "",
    claim: "",
    file: "",
    details: "",
    // country: "",
    state: "",
    city: "",
    county: "",
    uploadFiles: [],
    fromDataObj: null,
    files: [],
    // inviteUsers: [{ name: "", label: "", role: "", value: "", isExist: false }]
  });

  let [mode, setMode] = useState("create");
  let [staticData, setStaticData] = useState({
    roleData: [],
    attorneysList: [],
    remainingAttorneysList: [],
    allUserList: [],
  });
  const [buttonState, setbuttonState] = useState(false);
  const [updateButtonState, setUpdateButtonState] = useState(false);

  const [UserDetails, setUserDetails] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const [stateDetails, setStateDetails] = useState(false);
  const [statedata, setstatedata] = useState([]);
  const [statetotal, setstatetotal] = useState(0);
  const [searchstate, setsearchstate] = useState('');
  const [statepage, setstatepage] = useState(1);
  const [selectstate, setselectstate] = useState('');
  const debouncedSearchstate = useDebounce(searchstate, 500);

  const [cityDetails, setCityDetails] = useState(false);
  const [citydata, setcitydata] = useState([]);
  const [citytotal, setcitytotal] = useState(0);
  const [searchcity, setsearchcity] = useState('');
  const [citypage, setcitypage] = useState(1);
  const debouncedSearchcity = useDebounce(searchcity, 500);

  const [countyDetails, setCountyDetails] = useState(false);
  const [countydata, setcountydata] = useState([]);
  const [countytotal, setcountytotal] = useState(0);
  const [searchcounty, setsearchcounty] = useState('');
  const [countypage, setcountypage] = useState(1);
  const debouncedSearchcounty = useDebounce(searchcounty, 500);

  const [isLoad, setIsLoad] = useState(false);
  const [isLoadCitySate, setIsLoadCitySate] = useState(false);

  const [modalStatus, setModalStatus] = useState({
    isShow: false,
    type: "",
    contaxt: '',
    event: ''
  })

  let [error, setError] = useState({
    requestedByError: "",
    defendantLawyerError: "",
    plaintiffLawyerError: "",
    defendantError: { ind: -1, name: "", email: "" },
    plaintiffError: { ind: -1, name: "", email: "" },
    // witnessError: { ind: -1, name: '', email: "" },
    // inviteUsersError: { ind: -1, name: "", email: "", role: 0 },
    casenameError: "",
    courtError: "",
    claimError: "",
    fileError: "",
    detailsError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    zipError: "",
    uploadFilesError: [],
  });

  useEffect(() => {
    setIsLoad(true);
    if (caseId) {
      setMode("update");
    }
  }, []);

  const { response2: getUserDetails } = useConditiongetService(
    `/api/case/getCaseDetails/${caseId}`,
    UserDetails
  );

  const { response2: getStatedata } = useConditiongetService(
    `/api/frontend/getStateList?search=${debouncedSearchstate}&size=10&page=${statepage}`,
    stateDetails
  );

  const { response2: getcitydata } = useConditiongetService(
    `/api/frontend/getCityList/${selectstate}?search=${debouncedSearchcity}&size=10&page=${citypage}`,
    cityDetails
  );

  const { response2: getcountydata } = useConditiongetService(
    `/api/frontend/getCountyList/${selectstate}?search=${debouncedSearchcounty}&size=10&page=${countypage}`,
    countyDetails
  );

  const parallelResponse = useParallelService([`/api/case/getAllCaseViewList`, `/api/frontend/getStateList?search=${debouncedSearchstate}&size=10&page=${statepage}`]);

  const parallelCitySateResponse = useParallelServiceConditionally([`/api/frontend/getCityList/${selectstate}?search=${debouncedSearchcity}&size=10&page=${citypage}`, `/api/frontend/getCountyList/${selectstate}?search=${debouncedSearchcounty}&size=10&page=${countypage}`], isLoadCitySate);

  // Parallel City State
  useEffect(() => {
    if (parallelCitySateResponse?.length > 0 && isLoadCitySate === true) {

      setIsLoadCitySate(false);
      if (parallelCitySateResponse[0]?.status === 200) {
        if (citydata?.length === 0 && parallelCitySateResponse[0]?.data?.count !== 0) {
          let finaldata = parallelCitySateResponse[0]?.data.map((item) => {
            return {
              id: item?.id,
              value: item.city,
              label: item.city,
            };
          });
          setcitydata(finaldata);
          setcitytotal(parallelCitySateResponse[0]?.totalCount);
        }
      }

      if (parallelCitySateResponse[1]?.status === 200) {
        if (countydata?.length === 0 && parallelCitySateResponse[1]?.data?.count !== 0) {
          let finaldata = parallelCitySateResponse[1]?.data?.map((item) => {
            return {
              value: item?.label ? item?.label : item?.city,
              label: item?.label ? item?.label : item?.city,
            };
          });
          setcountydata(finaldata);
          setcountytotal(parallelCitySateResponse[1]?.totalCount);
        }
      }

    }
  }, [parallelCitySateResponse]);

  // For State
  useEffect(() => {
    if (getStatedata?.status === "200-" && stateDetails) {
      if (statedata?.length === 0 && getStatedata?.data?.data?.count !== 0) {
        let finaldata = getStatedata.data.data?.map((item) => {
          return {
            value: item.state_id,
            label: item.state_name,
          };
        });
        setstatedata(finaldata);
        setstatetotal(getStatedata?.data?.totalCount);
        setStateDetails(false);
      } else if (statedata?.length !== 0) {
        let myObj = 'rows';
        if (getStatedata?.data?.data.hasOwnProperty(myObj)) {
          setstatedata([...statedata]);
          setStateDetails(false);
        } else {
          let finaldata = getStatedata.data.data?.map((item) => {
            return {
              value: item.state_id,
              label: item.state_name,
            };
          });
          setstatedata([...statedata, ...finaldata]);
          setStateDetails(false);
        }
      }
    }
  }, [getStatedata]);

  useEffect(() => {
    if (debouncedSearchstate !== "") {
      setStateDetails(true);
      setsearchstate(debouncedSearchstate);
    }
  }, [debouncedSearchstate]);

  // For city
  useEffect(() => {
    if (getcitydata?.status === "200-" && cityDetails) {
      if (citydata?.length === 0 && getcitydata?.data?.data?.count !== 0) {
        let finaldata = getcitydata.data.data.map((item) => {
          return {
            id: item?.id,
            value: item.city,
            label: item.city,
          };
        });
        setcitydata(finaldata);
        setcitytotal(getcitydata?.data?.totalCount);
        setCityDetails(false);
      } else if (citydata?.length !== 9) {
        let myObj = 'rows';
        if (getStatedata?.data?.data.hasOwnProperty(myObj)) {
          setcitydata([...citydata]);
          setCityDetails(false);
        } else {
          let finaldata = getcitydata.data.data.map((item) => {
            return {
              value: item.city,
              label: item.city,
            };
          });
          setcitydata([...citydata, ...finaldata]);
          setCityDetails(false);
        }
      }
    }
  }, [getcitydata]);

  useEffect(() => {
    if (debouncedSearchcity !== "") {
      setCityDetails(true);
      setsearchcity(debouncedSearchcity);
    }
  }, [debouncedSearchcity]);

  // For County
  useEffect(() => {
    if (getcountydata?.status === "200-" && countyDetails) {
      if (countydata?.length === 0 && getcountydata?.data?.data?.count !== 0) {
        let finaldata = getcountydata.data.data.map((item) => {
          return {
            value: item?.label ? item?.label : item?.city,
            label: item?.label ? item?.label : item?.city,
          };
        });
        setcountydata(finaldata);
        setcountytotal(getcountydata?.data?.totalCount);
      } else if (countydata?.length > 9) {
        let finaldata = getcountydata.data.data.map((item) => {
          return {
            value: item.label,
            label: item.label,
          };
        });
        setcountydata([...countydata, ...finaldata]);
      }
      setCountyDetails(false);
    }
  }, [getcountydata]);

  useEffect(() => {
    if (debouncedSearchcounty !== "") {
      setCountyDetails(true);
      setsearchcounty(debouncedSearchcounty);
    }
  }, [debouncedSearchcounty]);

  let payload = {
    // "meet_option": (fromData.virtualDepo) ? '1' : '2',
    requested_by: fromData.requestedBy[0]?.value,
    requested_by_role: fromData.requestedBy[0]?.role + "",
    defendant_lawyer: fromData?.defendantLawyer?.map((item) => ({
      user_id: item?.role ? parseInt(item?.value) : null,
      role_id: item?.role ? item?.role : null,
      name: item?.label,
      email: item?.email,
    })),
    plaintiff_lawyer: fromData?.plaintiffLawyer?.map((item) => ({
      user_id: item?.role ? parseInt(item?.value) : null,
      role_id: item?.role ? item?.role : null,
      name: item?.label,
      email: item?.email,
    })),
    defendant: fromData?.defendant_client,
    plaintiff: fromData?.plaintiff_client,
    // "witness": fromData.witness,
    // "invite_users": fromData?.inviteUsers?.map(item => ((item?.isExist) ? {
    //     "name": item?.name,
    //     "email": item?.label,
    //     "role_id": parseInt(item?.role),
    //     "user_id": parseInt(item?.value),
    //     "isExistFlag": 1
    // } : {
    //     "name": item?.name,
    //     "email": item?.label,
    //     "role_id": parseInt(item?.role),
    //     "user_id": 0,
    //     "isExistFlag": 0
    // })),
    case_number: fromData?.casename,
    court_number: fromData?.court,
    claim_number: fromData?.claim,
    file_number: String(fromData?.file),
    // country: fromData.country,
    state: fromData?.state?.label,
    city: fromData?.city?.label,
    county: fromData?.county?.label,
    details: fromData?.details,
    files: fromData?.files?.length > 0 ? fromData?.files.map((item) => ({ file_name: item?.file_name })) : [],
  };

  const createdResponse = usePostService(
    payload,
    `/api/case/addCase`,
    buttonState
  );
  const updatedResponse = usePutService(
    payload,
    `/api/case/updateCaseDetails/${caseId}`,
    updateButtonState
  );
  const uploadResponse = usePostService(
    fromData.fromDataObj,
    `/api/fileUpload/case_upload`,
    isUpload
  );

  useEffect(() => {
    if (uploadResponse?.status === "200-" && isUpload) {
      toast.success("File upload successful");
      if (uploadResponse?.data?.fileData.length > 0) {
        setFromData((preData) => ({
          ...preData,
          files: [
            ...preData.files,
            ...uploadResponse?.data?.fileData?.map((item) => ({
              id: Math.floor(1000 + Math.random() * 9000),
              file_name: item?.file_name,
            })),
          ],
        }));
      }
      setIsUpload(false);
    }
  }, [uploadResponse]);

  useEffect(() => {
    if (createdResponse?.status === "200-" && buttonState) {
      toast.success(createdResponse.data.message);
      navigate("/case-management");
      setFromData({
        id: caseId || "",
        requestedBy: [],
        virtualDepo: true,
        personDepo: false,
        defendantLawyer: [],
        plaintiffLawyer: [],
        defendant_client: [{ name: "", email: "" }],
        plaintiff_client: [{ name: "", email: "" }],
        // witness: [{ name: '', email: "" }],
        casename: "",
        court: "",
        claim: "",
        file: "",
        details: "",
        // country: "",
        state: "",
        city: "",
        county: "",
        uploadFiles: [],
        // inviteUsers: [{ name: "", label: "", role: "", value: "", isExist: false }]
      });
    }
    if (createdResponse?.status == 406) {
      toast.error(createdResponse?.data?.message);
    }
    setbuttonState(false);
  }, [createdResponse]);

  useEffect(() => {
    if (updatedResponse?.status === "200-" && updateButtonState) {
      toast.success(updatedResponse.data.message);
      navigate("/case-management");
      setFromData({
        id: caseId || "",
        requestedBy: [],
        virtualDepo: true,
        personDepo: false,
        defendantLawyer: [],
        plaintiffLawyer: [],
        defendant_client: [{ name: "", email: "" }],
        plaintiff_client: [{ name: "", email: "" }],
        // witness: [{ name: '', email: "" }],
        casename: "",
        court: "",
        claim: "",
        file: "",
        details: "",
        // country: "",
        state: "",
        city: "",
        county: "",
        uploadFiles: [],
        // inviteUsers: [{ name: "", label: "", role: "", value: "", isExist: false }]
      });
    }
    if (updatedResponse?.status == 406) {
      toast.error(updatedResponse?.data?.message);
    }
    setUpdateButtonState(false);
  }, [updatedResponse]);

  // Retrive Master Details
  useEffect(() => {
    if (parallelResponse.length > 0 && isLoad === true) {
      setIsLoad(false);
      if (parallelResponse[0]?.status === 200) {
        let attorneys = parallelResponse[0]?.data;
        let changeData = attorneys?.allAttorneyList?.map((item) => ({
          value: item.id,
          label: item?.first_name + " " + item?.last_name,
          role: item.role_id,
          email: item?.email,
        }));
        let allUserList = attorneys?.allUsersList?.map((item) => ({
          value: item.id,
          label: item?.email,
          name: item?.first_name + " " + item?.last_name,
          role: item.role_id,
        }));
        let allRoleList = attorneys?.allRoleList?.map((item) => ({
          id: item?.id,
          name: item?.role,
        }));
        setStaticData((preData) => ({
          ...preData,
          attorneysList: changeData,
          remainingAttorneysList: changeData,
          allUserList,
          roleData: allRoleList,
        }));
      }

      if (parallelResponse[1]?.status === 200) {
        if (statedata?.length === 0 && parallelResponse[1]?.data?.count !== 0) {
          let finaldata = parallelResponse[1]?.data?.map((item) => {
            return {
              value: item.state_id,
              label: item.state_name,
            };
          });
          setstatedata(finaldata);
          setstatetotal(parallelResponse[1]?.totalCount);
        }
      }
      if (caseId && fromData.defendantLawyer.length == 0) setUserDetails(true);
    }
  }, [parallelResponse]);

  useEffect(() => {
    if (getUserDetails?.status === "200-" && UserDetails) {
      let userDetails = getUserDetails?.data?.data;

      let defendantLawyer = userDetails?.defendant_lawyer?.map((item) => {
        let label = item?.label.split('(');
        return {
          value: item.value,
          label: label[0],
          role: item?.role_id ? item?.role_id : 3,
          email: label[1]?.split(')')[0],
        }
      });

      let plaintiffLawyer = userDetails?.plaintiff_lawyer?.map((item) => {
        let label = item?.label.split('(');
        return {
          value: item.value,
          label: label[0],
          role: item?.role_id ? item?.role_id : 3,
          email: label[1]?.split(')')[0],
        }
      });

      let remainingAttorney = [];

      remainingAttorney = staticData.attorneysList?.filter(
        (attorney) =>
          ![...defendantLawyer, ...plaintiffLawyer].some(
            (item) => item?.value == attorney?.value
          )
      );

      let updateDetails = {
        id: caseId || "",
        requestedBy: staticData.attorneysList.filter(
          (attorney) => attorney?.value === userDetails?.requested_by
        ),
        // virtualDepo: (parseInt(userDetails?.meet_option) === 1) ? true : false,
        // personDepo: (parseInt(userDetails?.meet_option) === 2) ? true : false,
        defendantLawyer,
        plaintiffLawyer,
        defendant_client: userDetails?.defendant?.map((item) => ({
          name: item?.name,
          email: item?.email,
        })),
        plaintiff_client: userDetails?.plaintiff?.map((item) => ({
          name: item?.name,
          email: item?.email,
        })),
        // witness: userDetails?.case_witnesses?.map(item => ({ name: item?.name, email: item?.email })),
        casename: userDetails?.case_number,
        court: userDetails?.court_number,
        claim: userDetails?.claim_number,
        file: userDetails?.file_number,
        details: userDetails?.details,
        // country: userDetails?.country,
        state: userDetails?.state,
        city: userDetails?.city,
        county: userDetails?.county,
        uploadFiles: [],
        files: userDetails?.files,
        // inviteUsers: userDetails?.case_invitees.map(item => ({ value: item.user_id, label: item?.email, name: item?.name, role: item.role_id, isExist: true }))
      };
      setFromData((preData) => ({ ...preData, ...updateDetails }));
      setStaticData((preData) => ({
        ...preData,
        remainingAttorneysList: remainingAttorney,
      }));
      setUserDetails(false);
    }
  }, [getUserDetails]);

  //Request By
  const onChangeRequestBy = (value, context) => {
    setFromData((preData) => ({ ...preData, requestedBy: [value] }));
  };

  const onChangeMeet = (e) => {
    if (e.target.name === "virtual")
      setFromData((preData) => ({
        ...preData,
        virtualDepo: true,
        personDepo: false,
      }));
    else
      setFromData((preData) => ({
        ...preData,
        virtualDepo: false,
        personDepo: true,
      }));
  };

  // Defendant Lawyer
  const onChangeDefendantLawyer = (defendantData, context, type) => {
    let changeList = [];
    if (context.action === "select-option") {
      changeList = staticData.remainingAttorneysList.filter(
        (item) => item.value !== context.option.value
      );
      setFromData((preData) => ({ ...preData, defendantLawyer: defendantData }));
      setStaticData((preData) => ({
        ...preData,
        remainingAttorneysList: changeList,
      }));
    }
    if (context.action === "remove-value") {
      if (context.removedValue.role === null) {

        changeList = [...staticData.remainingAttorneysList];
        setFromData((preData) => ({ ...preData, defendantLawyer: defendantData }));

      } else {

        let removeItem = staticData.attorneysList.find(
          (item) => item.value === context.removedValue.value
        );
        changeList = [...staticData.remainingAttorneysList, removeItem];
        setFromData((preData) => ({ ...preData, defendantLawyer: defendantData }));

      }
      setStaticData((preData) => ({
        ...preData,
        remainingAttorneysList: changeList,
      }));
    }
    if (context.action === "create-option") {
      if (validate_email(defendantData[defendantData.length - 1].label)) {
        setModalStatus({
          isShow: true,
          type: type,
          contaxt: context,
          event: defendantData
        })
      }
    }
  };

  // Plaintiff Lawyer
  const onChangePlaintiffLawyer = (plaintiffData, context, type) => {
    let changeList = [];
    if (context.action === "select-option") {
      changeList = staticData.remainingAttorneysList.filter(
        (item) => item.value !== context.option.value
      );
      setFromData((preData) => ({ ...preData, plaintiffLawyer: plaintiffData }));
      setStaticData((preData) => ({
        ...preData,
        remainingAttorneysList: changeList,
      }));
    }
    if (context.action === "remove-value") {

      if (context.removedValue.role === null) {

        changeList = [...staticData.remainingAttorneysList];
        setFromData((preData) => ({ ...preData, plaintiffLawyer: plaintiffData }));

      } else {

        let removeItem = staticData.attorneysList.find(
          (item) => item.value === context.removedValue.value
        );
        changeList = [...staticData.remainingAttorneysList, removeItem];
        setFromData((preData) => ({ ...preData, plaintiffLawyer: plaintiffData }));

      }
      setStaticData((preData) => ({
        ...preData,
        remainingAttorneysList: changeList,
      }));
    }
    if (context.action === "create-option") {
      if (validate_email(plaintiffData[plaintiffData.length - 1].label)) {
        setModalStatus({
          isShow: true,
          type: type,
          contaxt: context,
          event: plaintiffData
        })
      }
    }
  };

  // Defendant
  const addMoreDefendant = () => {
    setFromData((preData) => ({
      ...preData,
      defendant_client: [...preData.defendant_client, { name: "", email: "" }],
    }));
  };
  const removeDefendant = (idx) => {
    setFromData((preData) => ({
      ...preData,
      defendant_client: preData.defendant_client.filter(
        (_, index) => index !== idx
      ),
    }));
  };
  const handleDefendantInput = (e, idx) => {
    let changeInput = fromData.defendant_client.map((item, index) => {
      if (index === idx) {
        return { ...item, [e.target.name]: e.target.value };
      } else return item;
    });
    setFromData((preData) => ({ ...preData, defendant_client: changeInput }));
  };

  // Plaintiff
  const addMorePlaintiff = () => {
    setFromData((preData) => ({
      ...preData,
      plaintiff_client: [...preData.plaintiff_client, { name: "", email: "" }],
    }));
  };
  const removePlaintiff = (idx) => {
    setFromData((preData) => ({
      ...preData,
      plaintiff_client: preData.plaintiff_client.filter(
        (_, index) => index !== idx
      ),
    }));
  };
  const handlePlaintiffInput = (e, idx) => {
    let changeInput = fromData.plaintiff_client.map((item, index) => {
      if (index === idx) {
        return { ...item, [e.target.name]: e.target.value };
      } else return item;
    });
    setFromData((preData) => ({ ...preData, plaintiff_client: changeInput }));
  };

  // Witness
  // const addMoreWitness = () => {
  //     setFromData((preData) => ({ ...preData, witness: [...preData.witness, { name: "", email: "" }] }))
  // }
  // const removeWitness = (idx) => {
  //     setFromData((preData) => ({ ...preData, witness: preData.witness.filter((_, index) => index !== idx) }));
  // }
  // const handleWitnessInput = (e, idx) => {
  //     let changeInput = fromData.witness.map((item, index) => {
  //         if (index === idx) {
  //             return { ...item, [e.target.name]: e.target.value }
  //         } else
  //             return item
  //     })
  //     setFromData((preData) => ({ ...preData, witness: changeInput }));
  // }

  // Other Inputs
  const handleInput = (e) => {
    setFromData((preData) => ({ ...preData, [e.target.name]: e.target.value }));
  };

  // Invite User
  // const addMoreInviteUser = () => {
  //     setFromData((preData) => ({ ...preData, inviteUsers: [...preData.inviteUsers, { name: "", email: "", role: "" }] }))
  // }
  // const removeInviteUser = (idx) => {
  //     setFromData((preData) => ({ ...preData, inviteUsers: preData.inviteUsers.filter((_, index) => index !== idx) }));
  // }
  // const handleInviteUserInput = (e, idx) => {
  //     let changeInput = fromData.inviteUsers.map((item, index) => {
  //         if (index === idx) {
  //             return { ...item, [e.target.name]: e.target.value }
  //         } else
  //             return item
  //     })
  //     setFromData((preData) => ({ ...preData, inviteUsers: changeInput }));
  // }

  // const handleInviteUserEmail = (value, context, idx) => {
  //     let changeInput = fromData.inviteUsers.map((item, index) => {
  //         if (index === idx) {
  //             if (context.action === 'select-option') {
  //                 return { ...item, ...value, isExist: true };
  //             } else if (context.action === 'create-option') {
  //                 return { name: "", label: value.label, role: "", value: value.label, isExist: false };
  //             } else if (context.action === 'clear') {
  //                 return { name: "", label: "", role: "", value: "", isExist: false };
  //             }
  //         } else
  //             return item
  //     })
  //     setFromData((preData) => ({ ...preData, inviteUsers: changeInput }));
  // }

  // Upload Files
  const handleFileChange = (newFile) => {
    let fileData = new FormData();
    Object.entries(newFile).map(([key, value]) =>
      fileData.append("upload_files", value)
    );
    setFromData((preData) => ({
      ...preData,
      uploadFiles: [
        ...preData.uploadFiles,
        ...Object.entries(newFile).map(([key, value]) => value),
      ],
      fromDataObj: fileData,
    }));
    setIsUpload(true);
  };

  const onDeleteFile = (deleteId) => {
    setFromData((preData) => {
      return {
        ...preData,
        files: preData.files.filter(
          (imageItem) => parseInt(imageItem.id) !== parseInt(deleteId)
        ),
      };
    });
  };

  const onSelect = (args) => {
    let totalSize = 0;
    // for (const file of args) {
    //   totalSize = totalSize + file?.size;
    // }
    // settotal(totalSize);
    // setuploadFile(true)
  };

  const onDrop = (args) => {
    let totalSize = 0;
    // for (const file of args) {
    //   totalSize = totalSize + file?.size;
    // }
    // settotal(totalSize);
  };

  const validateCreateUpdateForm = () => {
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let isError = false;
    let requestedByError = "";
    let defendantLawyerError = "";
    let plaintiffLawyerError = "";
    let defendantError = {};
    let plaintiffError = {};
    // let witnessError = {};
    // let inviteUsersError = {};
    let casenameError = "";
    let courtError = "";
    let claimError = "";
    let fileError = "";
    let detailsError = "";
    let countryError = "";
    let cityError = "";
    let stateError = "";
    let zipError = "";
    let uploadFilesError = "";

    let {
      defendantLawyer,
      plaintiffLawyer,
      defendant_client,
      plaintiff_client,
      requestedBy,
      //  witness, inviteUsers
      casename,
      court,
      claim,
      file,
      details,
      // country,
      city,
      state,
      county,
      uploadFiles,
    } = fromData;

    if (requestedBy.length === 0) {
      requestedByError = lblErr.requestedByMessage;
      isError = true;
    }

    if (!isError)
      if (defendantLawyer.length === 0) {
        defendantLawyerError = lblErr.enterDefendantLawyer;
        isError = true;
      }

    if (!isError)
      if (plaintiffLawyer.length === 0) {
        plaintiffLawyerError = lblErr.enterPlaintiffLawyer;
        isError = true;
      }

    if (!isError)
      for (let i = 0; i < defendant_client.length; i++) {
        if (!defendant_client[i].name) {
          defendantError = { ind: i, name: lblErr.enterDefendant, email: "" };
          isError = true;
          break;
        }
        if (
          !defendant_client[i].email ||
          !emailReg.test(defendant_client[i].email)
        ) {
          defendantError = { ind: i, name: "", email: lblErr.invalidEmail };
          isError = true;
          break;
        }
      }

    if (!isError)
      for (let i = 0; i < plaintiff_client.length; i++) {
        if (!plaintiff_client[i].name) {
          plaintiffError = { ind: i, name: lblErr.enterPlaintiff, email: "" };
          isError = true;
          break;
        }
        if (
          !plaintiff_client[i].email ||
          !emailReg.test(plaintiff_client[i].email)
        ) {
          plaintiffError = { ind: i, name: "", email: lblErr.invalidEmail };
          isError = true;
          break;
        }
      }

    // if (!isError)
    //     for (let i = 0; i < witness.length; i++) {
    //         if (!witness[i].name) {
    //             witnessError = { ind: i, name: lblErr.enterwitness, email: "" };
    //             isError = true;
    //             break;
    //         }
    //         if (!witness[i].email || !emailReg.test(witness[i].email)) {
    //             witnessError = { ind: i, name: "", email: lblErr.invalidEmail };
    //             isError = true;
    //             break;
    //         }
    //     }

    if (!isError && !casename) {
      casenameError = lblErr.enterCasename;
      isError = true;
    }
    if (!isError && !court) {
      courtError = lblErr.enterCourt;
      isError = true;
    }
    if (!isError && !claim) {
      claimError = lblErr.enterClaim;
      isError = true;
    }
    if (!isError && !file) {
      fileError = lblErr.enterFile;
      isError = true;
    }
    if (!isError && !details) {
      detailsError = lblErr.enterDetails;
      isError = true;
    }
    // if (!isError && !country) {
    //   countryError = lblErr.enterCountry;
    //   isError = true;
    // }
    if (!isError && !state) {
      stateError = lblErr.enterState;
      isError = true;
    }

    if (!isError && !city) {
      cityError = lblErr.enterCity;
      isError = true;
    }

    if (!isError && !county) {
      zipError = lblErr.enterCounty;
      isError = true;
    }

    // if (!isError)
    //     for (let i = 0; i < inviteUsers.length; i++) {
    //         if (!inviteUsers[i].isExist) {
    //             if (!inviteUsers[i].label) {
    //                 inviteUsersError = { ...inviteUsersError, ind: i, email: lblErr.enterAnEmail };
    //                 isError = true;
    //                 break;
    //             } else if (!emailReg.test(inviteUsers[i].label)) {
    //                 inviteUsersError = { ...inviteUsersError, ind: i, email: lblErr.invalidEmail };
    //                 isError = true;
    //                 break;
    //             } else if (!inviteUsers[i].name) {
    //                 inviteUsersError = { ...inviteUsersError, ind: i, name: lblErr.enterName };
    //                 isError = true;
    //                 break;
    //             } else if (!(inviteUsers[i].role > 0)) {
    //                 inviteUsersError = { ...inviteUsersError, ind: i, role: lblErr.enterARole };
    //                 isError = true;
    //                 break;
    //             }
    //         }
    //     }

    setError({
      ...error,
      requestedByError: requestedByError,
      defendantLawyerError: defendantLawyerError,
      plaintiffLawyerError: plaintiffLawyerError,
      defendantError: defendantError,
      plaintiffError: plaintiffError,
      casenameError: casenameError,
      // witnessError: witnessError,
      // inviteUsersError: inviteUsersError,
      courtError: courtError,
      claimError: claimError,
      fileError: fileError,
      detailsError: detailsError,
      countryError: countryError,
      cityError: cityError,
      stateError: stateError,
      zipError: zipError,
    });
    return !isError;
  };

  // Submit Form
  const handleSubmit = () => {
    if (validateCreateUpdateForm()) {
      if (mode === "create") setbuttonState(true);
      else setUpdateButtonState(true);
    }
  };

  let {
    defendantLawyer,
    plaintiffLawyer,
    defendant_client,
    plaintiff_client,
    personDepo,
    virtualDepo,
    requestedBy,
    //  witness, inviteUsers
    casename,
    court,
    claim,
    file,
    details,
    // country,
    city,
    state,
    county,
    uploadFiles,
  } = fromData;

  // Close Modal
  const closeModalHandler = () => {
    setModalStatus({
      isShow: false,
      contaxt: '',
      event: '',
      type: ""
    })
  }

  // Submit Modal
  const addExternalLawyerHandler = (e, type) => {
    if (type === 1) {
      setFromData((preData) => ({ ...preData, defendantLawyer: e }));
    } else {
      setFromData((preData) => ({ ...preData, plaintiffLawyer: e }));
    }
    setModalStatus({
      isShow: false,
      contaxt: '',
      event: '',
      type: ""
    })
  }

  const addstateOption = async () => {
    await setstatepage((prev) => prev + 1);
    setStateDetails(true);
  };

  const handlestatechange = (e) => {
    setselectstate(e.label);
    setcitydata([]);
    setcountydata([]);
    // let temppage = 1;
    setcitypage(1);
    setcountypage(1);
    setFromData({
      ...fromData,
      city: "",
      county: ""
    })
    setIsLoadCitySate(true);
  };

  const addcityOption = async () => {
    await setcitypage((prev) => prev + 1);
    setCityDetails(true);
  }

  const addcountyOption = async () => {
    await setcountypage((prev) => prev + 1);
    setCountyDetails(true);
  }

  return (
    <>
      <AdminLayout>
        {/* {loading && <Loader></Loader>} */}
        <ListHeader />
        {/* <ToastContainer /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            {mode === "create"
                              ? "Create New Case"
                              : "Update Case"}
                            {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                            {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          Case information
                        </h6>
                        <div className="pl-lg-4">
                          {/* <Row>
                                                        <Col lg="6">
                                                            <FormGroup size="lg" className="pl-3">
                                                                <Input className="mr-3"
                                                                    id="input-Virtual"
                                                                    name="virtual"
                                                                    type="radio"
                                                                    checked={virtualDepo || false}
                                                                    onChange={onChangeMeet}
                                                                />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-Virtual"
                                                                >
                                                                    Virtual Depo Meet
                                                                </label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="pl-3">
                                                                <Input
                                                                    id="input-Person"
                                                                    name="person"
                                                                    type="radio"
                                                                    // value={}
                                                                    checked={personDepo || false}
                                                                    onChange={onChangeMeet}
                                                                />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-Person"
                                                                >
                                                                    In Person Depo Meet
                                                                </label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}

                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-defendant-lawyer"
                                >
                                  Requested By
                                </label>
                                <SingleSelect
                                  selectedValue={requestedBy}
                                  value={staticData.attorneysList}
                                  onChangeHandler={onChangeRequestBy}
                                />

                                {error.requestedByError ? (
                                  <ErrorMessage
                                    errorMsg={error.requestedByError}
                                  />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-defendant-lawyer"
                                >
                                  Defendant Lawyer/ Law Firm’s Name
                                </label>
                                <MultiSelect
                                  selectedValue={defendantLawyer}
                                  value={staticData.remainingAttorneysList}
                                  onChangeHandler={onChangeDefendantLawyer}
                                  type={1}
                                />

                                {error.defendantLawyerError ? (
                                  <ErrorMessage
                                    errorMsg={error.defendantLawyerError}
                                  />
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-plaintiff-lawyer"
                                >
                                  Plaintiff Lawyer/ Law Firm’s Name
                                </label>
                                <MultiSelect
                                  selectedValue={plaintiffLawyer}
                                  value={staticData.remainingAttorneysList}
                                  onChangeHandler={onChangePlaintiffLawyer}
                                  type={2}
                                />

                                {error.plaintiffLawyerError ? (
                                  <ErrorMessage
                                    errorMsg={error.plaintiffLawyerError}
                                  />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <h3>Defendant</h3> */}
                          <label
                            className="form-control-label"
                          //   htmlFor="input-case"
                          >
                            Defendant
                          </label>
                          {defendant_client?.map((clientItem, index) => {
                            return (
                              <Row key={index}>
                                <Col lg="6">
                                  <FormGroup>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue=""
                                      id={"input-name" + index}
                                      placeholder="Name"
                                      type="text"
                                      name="name"
                                      autoComplete="nope"
                                      value={clientItem.name}
                                      onChange={(e) =>
                                        handleDefendantInput(e, index)
                                      }
                                    />
                                    {error.defendantError?.ind === index &&
                                      error.defendantError?.name ? (
                                      <ErrorMessage
                                        errorMsg={error.defendantError?.name}
                                      />
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="d-flex justify-content-between">
                                    <div className="w-100">
                                      <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id={"input-email" + index}
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        autoComplete="nope"
                                        value={clientItem.email}
                                        onChange={(e) =>
                                          handleDefendantInput(e, index)
                                        }
                                      />
                                      {error.defendantError?.ind === index &&
                                        error.defendantError?.email ? (
                                        <ErrorMessage
                                          errorMsg={error.defendantError?.email}
                                        />
                                      ) : null}
                                    </div>
                                    {index === 0 ? (
                                      <div
                                        onClick={addMoreDefendant}
                                        className="btn-box btn-plus justify-content-end"
                                      >
                                        +
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => removeDefendant(index)}
                                        className="btn-box btn-minus justify-content-end"
                                      >
                                        -
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            );
                          })}

                          {/* <h3>Plaintiff</h3>  */}
                          <label
                            className="form-control-label"
                          //   htmlFor="input-case"
                          >
                            Plaintiff
                          </label>
                          {plaintiff_client?.map((clientItem, index) => {
                            return (
                              <Row key={index}>
                                <Col lg="6">
                                  <FormGroup>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue=""
                                      id={"input-name" + index}
                                      placeholder="Name"
                                      type="text"
                                      name="name"
                                      autoComplete="nope"
                                      value={clientItem.name}
                                      onChange={(e) =>
                                        handlePlaintiffInput(e, index)
                                      }
                                    />
                                    {error.plaintiffError?.ind === index &&
                                      error.plaintiffError?.name ? (
                                      <ErrorMessage
                                        errorMsg={error.plaintiffError?.name}
                                      />
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                                  <FormGroup className="d-flex justify-content-between">
                                    <div className="w-100">
                                      <Input
                                        className="form-control-alternative"
                                        defaultValue=""
                                        id={"input-email" + index}
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        autoComplete="nope"
                                        value={clientItem.email}
                                        onChange={(e) =>
                                          handlePlaintiffInput(e, index)
                                        }
                                      />
                                      {error.plaintiffError?.ind === index &&
                                        error.plaintiffError?.email ? (
                                        <ErrorMessage
                                          errorMsg={error.plaintiffError?.email}
                                        />
                                      ) : null}
                                    </div>
                                    {index === 0 ? (
                                      <div
                                        onClick={addMorePlaintiff}
                                        className="btn-box btn-plus justify-content-end"
                                      >
                                        +
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => removePlaintiff(index)}
                                        className="btn-box btn-minus justify-content-end"
                                      >
                                        -
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            );
                          })}

                          {/* <h3>Witness</h3> */}

                          {/* <Row>
                                                        <Col lg="12">
                                                            {
                                                                witness.map((witnessItem, index) => {

                                                                    return (<Row key={index}>
                                                                        <Col lg="6">

                                                                            <FormGroup>
                                                                                <Input
                                                                                    className="form-control-alternative"
                                                                                    defaultValue=""
                                                                                    id={"input-name" + index}
                                                                                    placeholder="Name"
                                                                                    type="text"
                                                                                    name="name"
                                                                                    autoComplete="nope"
                                                                                    value={witnessItem.name}
                                                                                    onChange={(e) => handleWitnessInput(e, index)}
                                                                                />

                                                                                {error.witnessError?.ind === index && error.witnessError?.name ? (
                                                                                    <ErrorMessage errorMsg={error.witnessError?.name} />
                                                                                ) : null}

                                                                            </FormGroup>

                                                                        </Col>

                                                                        <Col lg="6">
                                                                            <FormGroup className="d-flex justify-content-between">
                                                                                <div className="w-100">
                                                                                    <Input
                                                                                        className="form-control-alternative"
                                                                                        defaultValue=""
                                                                                        id={"input-email" + index}
                                                                                        placeholder="Email"
                                                                                        type="email"
                                                                                        name="email"
                                                                                        autoComplete="nope"
                                                                                        value={witnessItem.email}
                                                                                        onChange={(e) => handleWitnessInput(e, index)}
                                                                                    />

                                                                                    {error.witnessError?.ind === index && error.witnessError?.email ? (
                                                                                        <ErrorMessage errorMsg={error.witnessError?.email} />
                                                                                    ) : null}
                                                                                </div>
                                                                                {
                                                                                    (index === 0) ?
                                                                                        <a href="#" onClick={addMoreWitness} className="btn-box btn-plus justify-content-end">+</a> : <a href="#" onClick={() => removeWitness(index)} className="btn-box btn-minus justify-content-end">-</a>
                                                                                }

                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>)
                                                                })
                                                            }
                                                        </Col>
                                                    </Row> */}

                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-case"
                                >
                                  Case
                                </label>
                                <div className="d-flex justify-content-between">
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-case"
                                    placeholder="Case"
                                    type="text"
                                    name="casename"
                                    autoComplete="nope"
                                    value={casename}
                                    onChange={(e) => handleInput(e)}
                                  />
                                </div>
                                {error.casenameError ? (
                                  <ErrorMessage
                                    errorMsg={error.casenameError}
                                  />
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-court"
                                >
                                  Court
                                </label>
                                <div className="d-flex justify-content-between">
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-court"
                                    placeholder="Court"
                                    type="text"
                                    name="court"
                                    autoComplete="nope"
                                    value={court}
                                    onChange={(e) => handleInput(e)}
                                  />
                                </div>
                                {error.courtError ? (
                                  <ErrorMessage errorMsg={error.courtError} />
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-claim"
                                >
                                  Claim
                                </label>
                                <div className="d-flex justify-content-between">
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-claim"
                                    placeholder="Claim"
                                    type="text"
                                    name="claim"
                                    autoComplete="nope"
                                    value={claim}
                                    onChange={(e) => handleInput(e)}
                                  />
                                </div>
                                {error.claimError ? (
                                  <ErrorMessage errorMsg={error.claimError} />
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-file"
                                >
                                  File
                                </label>
                                <div className="d-flex justify-content-between">
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-file"
                                    placeholder="File"
                                    type="text"
                                    name="file"
                                    autoComplete="nope"
                                    value={file}
                                    onChange={(e) => handleInput(e)}
                                  />
                                </div>
                                {error.fileError ? (
                                  <ErrorMessage errorMsg={error.fileError} />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-details"
                                >
                                  Details
                                </label>
                                <div className="d-flex justify-content-between">
                                  <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-details"
                                    placeholder="Details"
                                    type="textarea"
                                    name="details"
                                    autoComplete="nope"
                                    value={details}
                                    onChange={(e) => handleInput(e)}
                                  />
                                </div>
                                {error.detailsError ? (
                                  <ErrorMessage errorMsg={error.detailsError} />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Upload Files
                                </label>
                                <div className="d-flex justify-content-between">
                                  <FileUploader
                                    handleChange={handleFileChange}
                                    // onSizeError={onSizeError}
                                    onSelect={onSelect}
                                    name="file"
                                    types={fileTypes}
                                    multiple={true}
                                    children={
                                      <label>
                                        Drag or{" "}
                                        <span className="text-orange">
                                          {" "}
                                          upload{" "}
                                        </span>{" "}
                                        file
                                      </label>
                                    }
                                    onDrop={onDrop}
                                  />
                                </div>
                                {!error.uploadFilesError ? (
                                  <ErrorMessage
                                    errorMsg={error.uploadFilesError}
                                  />
                                ) : null}
                                <div className="d-flex flex-column justify-content-around">
                                  {fromData.files?.map((fileNames) => {
                                    return (
                                      <div
                                        key={fileNames?.id}
                                        className="uploadfiles--box d-inline-flex align-items-center mt-1 w-50"
                                      >
                                        {/* <div className="uploadfiles--icon">Files</div> */}
                                        <a
                                          href={`http://dev81.developer24x7.com:4020/public/caseUploadFile/${fileNames?.file_name}`}
                                          download
                                          target="_blank"
                                          className="text-decoration-none"
                                          rel="noreferrer"
                                        >
                                          <span>{fileNames?.file_name}</span>
                                        </a>
                                        {/* <div>
                                                                            <a href="#">
                                                                                View Attachment
                                                                            </a>
                                                                            <a href="#">
                                                                                View Description
                                                                            </a>
                                                                        </div> */}
                                        <a
                                          href="javascript:void(0);"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            onDeleteFile(fileNames?.id)
                                          }
                                          className="btn-icon-close"
                                        >
                                          X
                                        </a>
                                      </div>
                                    );
                                  })}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>

                          <h3>Address</h3>

                          <Row>

                            <Col lg="6">
                              <FormGroup className="d-flex flex-column">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-state"
                                >
                                  State
                                </label>

                                <Select
                                  className="s-select"
                                  components={
                                    {
                                      MenuList,
                                      MenuListFooter: (
                                        <MenuListFooter
                                          showing={statedata?.length}
                                          total={statetotal}
                                          onClick={addstateOption}
                                        />
                                      ),
                                    }}
                                  onChange={(e) => {
                                    handlestatechange(e);
                                    setFromData((preData) => ({ ...preData, state: e }));
                                  }
                                  }
                                  onInputChange={(e) => setsearchstate(e)}
                                  value={state}
                                  placeholder="Type something and press enter..."
                                  options={statedata}
                                />

                                {error.stateError ? (
                                  <ErrorMessage errorMsg={error.stateError} />
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup className="d-flex flex-column">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  City
                                </label>

                                <Select
                                  className="s-select"
                                  components={{
                                    MenuList,
                                    MenuListFooter: (
                                      <MenuListFooter
                                        showing={citydata?.length}
                                        total={citytotal}
                                        onClick={addcityOption}
                                      />
                                    ),
                                  }}
                                  onChange={(e) => setFromData((preData) => ({ ...preData, city: e }))}
                                  onInputChange={(e) => setsearchcity(e)}
                                  value={city}
                                  isDisabled={selectstate === ''}
                                  placeholder="Type something and press enter..."
                                  options={citydata}
                                />

                                {error.cityError ? (
                                  <ErrorMessage errorMsg={error.cityError} />
                                ) : null}
                              </FormGroup>
                            </Col>

                          </Row>
                          <Row>

                            <Col lg="6">
                              <FormGroup className="d-flex flex-column">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-county"
                                >
                                  County
                                </label>

                                <Select
                                  className="s-select"
                                  components={{
                                    MenuList,
                                    MenuListFooter: (
                                      <MenuListFooter
                                        showing={countydata?.length}
                                        total={countytotal}
                                        onClick={addcountyOption}
                                      />
                                    ),
                                  }}
                                  onChange={(e) => setFromData((preData) => ({ ...preData, county: e }))}
                                  onInputChange={(e) => setsearchcounty(e)}
                                  value={county}
                                  isDisabled={selectstate === ''}
                                  placeholder="Type something and press enter..."
                                  options={countydata}
                                />

                                {error?.zipError ? (
                                  <ErrorMessage errorMsg={error.zipError} />
                                ) : null}
                              </FormGroup>
                            </Col>

                          </Row>

                          {/* <h3>Invite Users</h3>

                                                    {
                                                        inviteUsers.map((item, index) => {
                                                            return (
                                                                <Row key={index}>
                                                                    <Col lg="4">
                                                                        <FormGroup>
                                                                            <SingleSelect id={"input-email" + index} name="email" value={staticData.allUserList} selectedValue={item} index={index} onChangeHandler={handleInviteUserEmail} />

                                                                            {error.inviteUsersError?.ind === index && error.inviteUsersError?.email ? (
                                                                                <ErrorMessage errorMsg={error.inviteUsersError?.email} />
                                                                            ) : null}

                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col lg="4">
                                                                        <FormGroup>
                                                                            <Input
                                                                                className="form-control-alternative"
                                                                                defaultValue=""
                                                                                id={"input-name" + index}
                                                                                placeholder="Name"
                                                                                type="text"
                                                                                name="name"
                                                                                disabled={item.isExist}
                                                                                autoComplete="nope"
                                                                                value={item.name}
                                                                                onChange={(e) => handleInviteUserInput(e, index)}
                                                                            />
                                                                            {error.inviteUsersError?.ind === index && error.inviteUsersError?.name ? (
                                                                                <ErrorMessage errorMsg={error.inviteUsersError?.name} />
                                                                            ) : null}

                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col lg="4">
                                                                        <FormGroup className="d-flex justify-content-between">
                                                                            <div className="w-100">
                                                                                <Input
                                                                                    className="form-control-alternative"
                                                                                    type="select"
                                                                                    name="role"
                                                                                    disabled={item.isExist}
                                                                                    id={"input-role" + index}
                                                                                    value={item.role}
                                                                                    onChange={(e) => handleInviteUserInput(e, index)}
                                                                                >
                                                                                    <option value={0}>Select Role</option>
                                                                                    {
                                                                                        staticData.roleData.map(roleItem => {
                                                                                            return (
                                                                                                <option key={roleItem.id} value={roleItem.id}>{roleItem.name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Input>
                                                                                {error.inviteUsersError?.ind === index && error.inviteUsersError?.role ? (
                                                                                    <ErrorMessage errorMsg={error.inviteUsersError?.role} />
                                                                                ) : null}
                                                                            </div>
                                                                            {(index === 0) ? <a href="#" onClick={addMoreInviteUser} className="btn-box btn-plus justify-content-end">+</a> : <a href="#" onClick={() => removeInviteUser(index)} className="btn-box btn-minus justify-content-end">-</a>
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })
                                                    } */}
                        </div>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            {mode === "update" ? (
                              <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Update
                              </Button>
                            ) : (
                              <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </Button>
                            )}
                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/case-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </AdminLayout>

      {
        modalStatus.isShow && <AddExternalLawyer
          isOpen={modalStatus.isShow}
          handleClose={closeModalHandler}
          submitModal={addExternalLawyerHandler}
          context={modalStatus.contaxt}
          event={modalStatus?.event}
          type={modalStatus?.type}
        />
      }

    </>
  );
};

export default CreateUpdateCase;
