import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputGroup, Modal } from 'reactstrap';
import ErrorMessage from '../../components/Validation/ErrorMessage';
import { validate_email } from '../../helpers/utils';
import usePostService from '../../services/postservice';
import { toast } from 'react-toastify';

const AddModal = ({ isOpen, addModalHandler }) => {
    const [addedAttorney, setAddedAttorney] = useState(false);
    const [addAttorney, setAddAttorney] = useState({
        first_name: "",
        last_name: "",
        email: ""
    });

    const [error, setError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        created_by: 1
    });

    let requestAttorney = {
        first_name: addAttorney.first_name,
        last_name: addAttorney.last_name,
        email: addAttorney.email,
        created_by: 1,
    }

    const addNewAttorney = usePostService(
        requestAttorney,
        "api/attendee/add_court_reporter",
        addedAttorney
    );

    useEffect(() => {
        if (addNewAttorney.status !== "200-" && addNewAttorney !== "") {
            toast.error(addNewAttorney.data.message);
        } else if (addNewAttorney !== "") {
            toast.success(addNewAttorney.data.message);
            addModalHandler(1)
        }
    }, [addNewAttorney]);

    const changeHandler = (e) => {
        const temp = { ...addAttorney };
        temp[e.target.name] = e.target.value;
        setAddAttorney(temp);
    };

    const validateCreateUpdateForm = () => {
        const { first_name, last_name, email } = addAttorney;
        let isError = false,
            errFirstName = "",
            errLastName = "",
            errEmail = "",
            errPhone = "";

        if (first_name?.trim().length < 3 || first_name?.trim()?.length > 10) {
            errFirstName = "Firstname can't be less than 3 characters and more than 10 characters";
            isError = true;
        }

        if (last_name?.trim().length < 3 || last_name?.trim()?.length > 10) {
            errLastName = "Lastname can't be less than 3 characters and more than 10 characters";
            isError = true;
        }

        if (!email || !validate_email(email)) {
            errEmail = "Enter an email";
            isError = true;
        } else {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                errEmail = "Enter a valid email";
                isError = true;
            }
        }

        setError({
            ...error,
            first_name: errFirstName,
            last_name: errLastName,
            email: errEmail,
            phone: errPhone,
        });
        return !isError;
    };

    const onSubmitHandler = (evt) => {
        evt.preventDefault();
        if (validateCreateUpdateForm()) {
            setAddedAttorney(true);
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => addModalHandler(0)}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Add Court Reporter
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => addModalHandler(0)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Form role="form" onSubmit={(e) => onSubmitHandler(e)}>
                    <InputGroup className="mb-3">
                        <Input
                            defaultValue=""
                            id="input-first-name"
                            placeholder="First name"
                            required
                            maxLength={10}
                            minLength={3}
                            pattern="[A-Za-z]+"
                            type="text"
                            name="first_name"
                            autoComplete="nope"
                            value={addAttorney.first_name}
                            onChange={(e) => {
                                if (!e.target.value.includes(' '))
                                    changeHandler(e)
                            }}
                        />
                        {error.first_name ? (
                            <ErrorMessage errorMsg={error.first_name} />
                        ) : null}
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Input
                            defaultValue=""
                            id="input-last-name"
                            placeholder="Last name"
                            required
                            maxLength={10}
                            minLength={3}
                            pattern="[A-Za-z]+"
                            type="text"
                            name="last_name"
                            autoComplete="nope"
                            value={addAttorney.last_name}
                            onChange={(e) => {
                                if (!e.target.value.includes(' '))
                                    changeHandler(e)
                            }}
                        />
                        {error.last_name ? (
                            <ErrorMessage errorMsg={error.last_name} />
                        ) : null}
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <Input
                            id="input-email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            autoComplete="nope"
                            value={addAttorney?.email}
                            onChange={changeHandler}
                        />
                        {error.email ? <ErrorMessage errorMsg={error.email} /> : null}
                    </InputGroup>

                    <div className="text-center">
                        <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                        >
                            Create
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal >
    )
}

export default AddModal;