import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import AuthLayout from "../../layouts/AuthLayout";
// import { useHistory, Link } from "react-router-dom";
// import ErrorMessage from "../../components/Validation/ErrorMessage";
// import SuccessMessage from "../../components/Validation/successMessage";
// import lblErr from "../../languages/SignInErr";
// import commonService from "../../services/Common";
import logo from "../../assets/images/header-logo.png";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import { toast } from "react-toastify";
const ForgotPassword = () => {
  const [forgetPassword, setforgetPassword] = useState({
    email: ""
  });
  let [emailerror, setemailerror] = useState("");
  const [buttonState, setbuttonState] = useState(false);
  const navigate = useNavigate();
  const changeHandler = (e) => {
    const temp = { ...forgetPassword };
    temp[e.target.name] = e.target.value;
    setforgetPassword(temp);
  }
  const validate = () => {
    let isError = false;
    if (forgetPassword?.email?.trim() === "") {
      setemailerror("Email can't be empty");
      isError = true;
    }
    return isError;
  }
  let response = usePostService(forgetPassword, '/api/auth/forgot-password-mail', buttonState);
  useEffect(() => {
    if (response !== "") {
      if (response.status !== "200-" && buttonState) {
        setbuttonState(false)
        toast.error(response.data.message);
      }
      else {
        toast.success(response.data.message);
        setbuttonState(false)
      }
    }
  }, [response]);
  const handleSubmit = () => {
    let isError = validate();
    if (!isError) {
      setbuttonState(true)
    }
  }

  return (
    <AuthLayout data="Forgot Password">
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="text-center" style={{ marginBottom: "25px" }}>
                  <Col className="collapse-brand" xs="12">
                    <img
                      alt="..."
                      src={logo}
                    />
                    {/* <Link to="/">
                  
                </Link> */}
                  </Col>
                </Row>

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email address"
                        type="email"
                        name="email"
                        autoComplete="nope"
                        value={forgetPassword?.email}
                        onChange={changeHandler}
                        onFocus={() => setemailerror("")}
                      />
                    </InputGroup>
                    {emailerror !== "" ? <ErrorMessage errorMsg={emailerror} /> : null}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
                <Row className="justify-content-center">
                  <small role="button" onClick={() => navigate('/signin')}>Just remembered? Sign in</small>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </AuthLayout>
  );
};

export default ForgotPassword;
