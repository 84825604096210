import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from "reactstrap";
import AuthLayout from "../../layouts/AuthLayout";
import logo from "../../assets/images/header-logo.png";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setSessionStorage } from "../../helpers/apihelper";
import usePostService from "../../services/postservice";
const Signin = () => {
  const navigate = useNavigate();
  const [buttonState, setbuttonState] = useState(false);
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
  });
  // console.log("login", loginInput);
  const [inputErrorData, setInputErrorData] = useState({
    email: "",
    password: "",
  });
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };
  const changeHandler = (e) => {
    const temp = { ...loginInput };
    temp[e.target.name] = e.target.value;
    setLoginInput(temp);
  };
  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (loginInput?.email?.trim() === "") {
      temp.username = "Email can't be empty";
      isError = true;
    }
    if (loginInput?.password?.trim() === "") {
      temp.password = "Password can't be empty";
      isError = true;
    }
    setInputErrorData(temp);
    return isError;
  };
  let response = usePostService(loginInput, "api/auth/login", buttonState);
  useEffect(() => {
    if (response !== "") {
      setbuttonState(false);
      console.log(response.data.status);
      if(response.data.status === 200){
        toast.success(response.data.message);
        setSessionStorage("token", response.data.data.token);
        setSessionStorage("admindetails", JSON.stringify(response.data.data.user));
        navigate("/dashboard");
      }else{
        toast.error(response.data.message);
      }
      // if (response.status !== "200-") {
      //   toast.error(response.data.message);
      // } else {
      //   toast.success(response.data.message);
      //   setSessionStorage("token", response.data.data.token);
      //   setSessionStorage("admindetails", JSON.stringify(response.data.data.user));
      //   navigate("/dashboard");
      // }
    }
  }, [response]);
  const onSignInClick = () => {
    let isError = validate();
    if (!isError) {
      setbuttonState(true);
    }
    // toast.success("Wow so easy!")
  };

  return (
    <AuthLayout data="Sign In">
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="text-center" style={{ marginBottom: "25px" }}>
                  <Col className="collapse-brand" xs="12">
                    {/* <img
               alt="..."
               src={logo}
             /> */}
                    <h2 className="mb-0">Ez Dep</h2>

                    {/* <Link to="/">
             
           </Link> */}
                  </Col>
                </Row>

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="name"
                        name="email"
                        autoComplete="new-email"
                        value={loginInput.email}
                        onChange={changeHandler}
                        onFocus={handleErrorRemove}
                      />
                    </InputGroup>
                    {inputErrorData.username ? (
                      <ErrorMessage errorMsg={inputErrorData.username} />
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        value={loginInput.password}
                        onChange={changeHandler}
                        onFocus={handleErrorRemove}
                      />
                    </InputGroup>
                    {inputErrorData.password ? (
                      <ErrorMessage errorMsg={inputErrorData.password} />
                    ) : null}
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={onSignInClick}
                    >
                      Sign In
                    </Button>
                  </div>
                </Form>
                <Row className="justify-content-center">
                  <small
                    role="button"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    Forgot your password?
                  </small>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </AuthLayout>
  );
};

export default Signin;
