import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    InputGroup,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useConditiongetService from "../../services/conditiongetService";
import useGetService from "../../services/getservice";
import ConfirmationDialog from "../../components/confirmation.dialog";

const CreateUpdateWaitingApproval = (props) => {
    let { approvalId } = useParams();
    let navigate = useNavigate();
    let [fromData, setFromData] = useState({
        id: approvalId || '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        npi_number: '',
        is_active: true,
        is_approved: "0",
        role: 0
    });
    console.log("f", fromData);
    let [mode, setMode] = useState("create");
    let [isUpdate, setUpdate] = useState(false);
    const [buttonState, setbuttonState] = useState(false);
    const [updateButtonState, setUpdateButtonState] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isOpen, setisOpen] = useState(false);

    let [error, setError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // password: "",
        // role_id: "",
        phone: "",
        is_active: "0",
        is_approved: "",
        npi_number: ''
    });

    const { response: getRoleDetails } = useGetService(`/api/case/getAllCaseViewList`);
    let { response2 } = useConditiongetService(`api/user/getUserById/${approvalId}`, isUpdate);

    let addPayload = {
        first_name: fromData?.first_name,
        last_name: fromData?.last_name,
        email: fromData?.email,
        phone: fromData?.phone
    }
    let response = usePostService(addPayload, '/api/user/add_user', buttonState);

    let updatePayload = {
        first_name: fromData?.first_name,
        last_name: fromData?.last_name,
        email: fromData?.email,
        phone: fromData?.phone,
        is_approved: fromData?.is_approved,
        role_id: fromData?.role,
	npi_number: fromData?.npi_number
    }
    let updateResponse = usePutService(updatePayload, `/api/user/approval_update/${fromData?.id}`, updateButtonState);

    useEffect(() => {
        if (approvalId) {
            setMode('update');
            // setUpdate(true);
        }
    }, []);

    useEffect(() => {
        if (getRoleDetails.status === "200-") {
            setRoleList(getRoleDetails?.data?.data?.allRoleList.map(roleItem => ({ value: roleItem?.id, label: roleItem?.role })));
            setUpdate(true);
        }
    }, [getRoleDetails]);

    useEffect(() => {
        if (response2?.status === "200-" && isUpdate) {
            let userDetails = response2?.data?.data;
            setFromData({
                id: userDetails.id,
                first_name: userDetails.first_name,
                last_name: userDetails?.last_name,
                email: userDetails?.email,
                phone: userDetails?.phone,
                is_active: userDetails?.is_active,
                is_approved: userDetails?.is_approved,
                npi_number: userDetails?.npi_number,
                role: userDetails?.role_id
            });
            setUpdate(false);
        }
    }, [response2])

    let { first_name, last_name, email, phone, is_active, is_approved, role, npi_number } = fromData;

    let handleInput = (e) => {
        setFromData((preItem) => ({ ...preItem, [e.target.name]: e.target.value }))
    }

    useEffect(() => {
        if (response !== "") {
            setbuttonState(false)
            if (response.status !== "200-" && buttonState) {
                toast.error(response.data.message);
            }
            else {
                toast.success(response.data.message)
                navigate("/user-management");
            }
        }
    }, [response]);

    useEffect(() => {
        if (updateResponse !== "") {
            if (updateResponse.status !== "200-" && updateButtonState) {
                toast.error(updateResponse.data.message);
            }
            else {
                toast.success(updateResponse.data.message)
                navigate("/waiting-approval");
            }
        }
        setUpdateButtonState(false)
    }, [updateResponse]);

    let handleSubmit = () => {
        if (validateCreateUpdateForm()) {
            if (mode === 'create')
                setbuttonState(true);
            else
                setisOpen(true);
        }
    }

    let validateCreateUpdateForm = () => {
        let isError = false,
            errFirstName = "",
            errLastName = "",
            errEmail = "",
            errPhone = "",
            errNpiNumber = ''
        // errRoleId = "";

        if (!first_name) {
            errFirstName = lblErr.enterFirstName;
            isError = true;
        }

        if (!last_name) {
            errLastName = lblErr.enterLastName;
            isError = true;
        }
        if (!npi_number && role === 4) {
            errNpiNumber = lblErr.enterNpiNumber;
            isError = true;
        }

        if (!email) {
            errEmail = lblErr.enterAnEmail;
            isError = true;
        } else {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                errEmail = lblErr.invalidEmail;
                isError = true;
            }
        }

        // if (!role_id) {
        //   errRoleId = lblErr.selectARole;
        //   isError = true;
        // }

        if (
            phone &&
            phone.toString().length != 10
        ) {
            errPhone = lblErr.phoneLength;
            isError = true;
        }

        // if (!password && mode === 'create') {
        //   errPassword = lblErr.enterAPassword;
        //   isError = true;
        // }

        setError({
            ...error,
            first_name: errFirstName,
            last_name: errLastName,
            email: errEmail,
            npi_number: errNpiNumber,
            // role_id: errRoleId,
            phone: errPhone,
        });
        return !isError;
    };

    const onChangeRoleHandler = (value) => {
        setFromData((predata) => ({ ...predata, role: [value] }));
    }
    console.log("e", error);
    console.log("n", npi_number);
    return (
        <AdminLayout>
            <>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                {/* <ToastContainer /> */}

                <ConfirmationDialog
                    isOpen={isOpen}
                    toggle={() => setisOpen(false)}
                    action={"update"}
                    onClick={() => setUpdateButtonState(true)}
                ></ConfirmationDialog>

                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col" >
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        {(mode === 'create') ? 'Create New User' : 'Update User'}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    User information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    First name
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-first-name"
                                                                    placeholder="First name"
                                                                    type="text"
                                                                    name="first_name"
                                                                    autoComplete="nope"
                                                                    value={first_name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.first_name ? (
                                                                    <ErrorMessage errorMsg={error.first_name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Last name
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-last-name"
                                                                    placeholder="Last name"
                                                                    type="text"
                                                                    name="last_name"
                                                                    autoComplete="nope"
                                                                    value={last_name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.last_name ? (
                                                                    <ErrorMessage errorMsg={error.last_name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={`${npi_number === null ? "6" : "4"}`}>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Email address
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-email"
                                                                    placeholder="Email"
                                                                    type="email"
                                                                    name="email"
                                                                    autoComplete="nope"
                                                                    value={email}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.email ? (
                                                                    <ErrorMessage errorMsg={error.email} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        {npi_number !== null &&
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-npi-number"
                                                                    >
                                                                        NPI number
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        defaultValue=""
                                                                        id="input-npi-number"
                                                                        placeholder="NPI number"
                                                                        type="text"
                                                                        name="npi_number"
                                                                        autoComplete="nope"
                                                                        value={npi_number}
                                                                        onChange={(e) => handleInput(e)}
                                                                    />
                                                                    {error.npi_number ? (
                                                                        <ErrorMessage errorMsg={error.npi_number} />
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        }

                                                        <Col lg={`${npi_number === null ? "6" : "4"}`}>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Phone
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-last-name"
                                                                    placeholder="Phone"
                                                                    type="number"
                                                                    name="phone"
                                                                    autoComplete="nope"
                                                                    value={phone === 0 ? "" : phone}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.phone ? (
                                                                    <ErrorMessage errorMsg={error.phone} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Role
                                </label>
                                <Input
                                  type="select"
                                  name="role_id"
                                  id="selectMulti"
                                  value={userCreateUpdate.role_id}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="">--- Select ---</option>
                                  <option value="1">
                                    Hii
                                  </option>
                                  {roles.map((role) => {
                                    return (
                                      <option value={role.id} key={role.id}>
                                        {role.role}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {error.role_id ? (
                                  <ErrorMessage errorMsg={error.role_id} />
                                ) : null}
                              </FormGroup>
                            </Col> */}
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    Status
                                                                </label>
                                                                <Input
                                                                    type="select"
                                                                    name="is_approved"
                                                                    id="selectMulti"
                                                                    value={is_approved}
                                                                    onChange={(e) => handleInput(e)}
                                                                >
                                                                    <option value="1">Approved</option>
                                                                    <option value="0">Rejected</option>
                                                                    <option value="2">Pending</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    Role
                                                                </label>
                                                                <Input
                                                                    type="select"
                                                                    name="role"
                                                                    id="selectMulti"
                                                                    value={role}
                                                                    onChange={(e) => handleInput(e)}
                                                                >
                                                                    {roleList.length > 0 && roleList.map(roleItem => {
                                                                        return (<option value={roleItem?.value}>{roleItem?.label}</option>)

                                                                    })}
                                                                </Input>
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>

                                                </div>
                                                <hr className="my-4" />
                                                <div className="pl-lg-4">
                                                    <FormGroup className="d-flex justify-content-end">
                                                        {
                                                            (mode === 'update') ? <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Update
                                                            </Button> : <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Submit
                                                            </Button>
                                                        }
                                                        <Button
                                                            color="warning"
                                                            className="no-transform"
                                                            onClick={() => navigate("/waiting-approval")}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        </AdminLayout>
    );
};

export default CreateUpdateWaitingApproval;
