import React from "react";
import { getStorage } from "../helpers/apihelper";
import { Navigate } from "react-router-dom";
const PrivateRoute = ({ component: RouteComponent }) => {
  const token = getStorage("token");
  if (token !== null) {
    return <RouteComponent />;
  } else {
    return <Navigate to="/signin" />;
  }
};

export default PrivateRoute;
