import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  Dropdown,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useGetService from "../../services/getservice";
import useDeleteService from "../../services/deleteservice";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationDialog from "../../components/confirmation.dialog";
import useDebounce from "../../components/custom-hooks/useDebounce";
import useConditiongetService from "../../services/conditiongetService";
import Loader from "../../components/Loader/Loader";

const UserList = () => {
  const navigate = useNavigate();
  let [userModal, setUserModal] = useState(false);
  let [userDetails, setUserDetails] = useState({});
  let [userList, setUserList] = useState([]);
  let [searchInput, setSearchInput] = useState('');
  let [deletedId, setDeletedId] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const [firstNameSortOrder, setFirstNameSortOrder] = useState('asc');
  const [lastNameSortOrder, setLastNameSortOrder] = useState('asc');
  const [emailSortOrder, setEmailSortOrder] = useState('asc');
  const [phoneSortOrder, setPhoneSortOrder] = useState('asc');

  const [conditionButton, setConditionButton] = useState(false);
  const [sortName, setSortName] = useState('');
  const [finalsortOrder, setFinalSortOrder] = useState('');

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  // let { response, isFetchItems } = useGetService(`/api/user/getUsers`);
  let { response2, isFetchItems } = useConditiongetService(`/api/user/getUsers?sort_name=${sortName}&search=${debouncedSearchTerm}&sort_order=${finalsortOrder}&size=20&page=${page}`, conditionButton);
  let deletedItem = useDeleteService(`api/user/delete_user/${deletedId}`, isDeleted);

  // useEffect(() => {
  //   if (response?.status === "200-") {
  //     setUserList(response?.data?.data);
  //     settotalPage(Math.ceil(response?.data?.totalCount / 5));
  //   }
  // }, [response]);

  useEffect(() => {
    console.log("Bad");
    if (debouncedSearchTerm !== "") {
      setConditionButton(true);
      setSearchInput(debouncedSearchTerm);
    }
    else if (debouncedSearchTerm === "" && searchInput === "") {
      setConditionButton(true);
      setSearchInput("");
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (deletedItem?.status === "200-") {
      toast.success(deletedItem.data.message);
      setConfirmationModal(false);
      setUserList(userList.filter(item => item.id !== deletedId))
    }
  }, [deletedItem]);

  let viewUser = (id) => {
    setUserDetails(userList.find(item => item.id === id));
    setUserModal(true);
  }

  const handleDelete = (id) => {
    setDeletedId(id);
    setConfirmationModal(true);
  }

  const handleIsDelete = () => {
    setIsDeleted(true);
  }

  const sortItemHandler = (name, direction) => {
    setFinalSortOrder(direction);
    setSortName(name);
    setConditionButton(true);
  }

  // useEffect(() => {
  //   setConditionButton(true);
  // }, [
  //   debouncedSearchTerm
  // ]);

  useEffect(() => {
    if (response2?.status === "200-" && conditionButton) {
      setUserList(response2?.data?.data);
      settotalPage(response2?.data?.totalPages);
      setConditionButton(false);
    }
  }, [
    response2
  ]);

  const onSearchChange = (searchValue) => setSearchInput(searchValue);

  const onPageChange = (value) => {
    setPage(value)
    setConditionButton(true)
  }

  return (
    <AdminLayout name="User Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      <ToastContainer />
      <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Disable"}
        onClick={() => handleIsDelete()}
      ></ConfirmationDialog>
      {/* <ConfirmationDialog
        isOpen={reset2faModal}
        toggle={() => setReset2faModal(false)}
        action={"Reset 2FA"}
        onClick={() => handleReset2faSubmit(selected_id)}
      ></ConfirmationDialog>
      <ConfirmationDialog
        isOpen={resetModal}
        toggle={() => setResetModal(false)}
        action={"Reset Password"}
        onClick={() => handleResetSubmit(selected_id)}
      ></ConfirmationDialog> */}
      <Modal
        className="modal-dialog-centered"
        isOpen={userModal}
        toggle={() => setUserModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            User Information
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUserModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center" responsive>
            {userDetails && (<tbody>
              <tr>
                <th scope="col">Firstname</th>
                <td>{userDetails?.first_name}</td>
              </tr>
              <tr>
                <th scope="col">Lastname</th>
                <td>{userDetails?.last_name}</td>
              </tr>
              <tr>
                <th scope="col">Email</th>
                <td>{userDetails?.email}</td>
              </tr>
              <tr>
                <th scope="col">Phone</th>
                <td>{userDetails?.phone}</td>
              </tr>
              <tr>
                <th scope="col">Status</th>
                <td>{(parseInt(userDetails?.is_active) === 1) ? 'Active' : 'Inactive'}</td>
              </tr>
              {/* <tr>
                <th scope="col">Created At</th>
                <td>
                  Hello
                </td>
              </tr> */}
            </tbody>)}
          </Table>
        </div>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-0">Users List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-8 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "40%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          value={searchInput}
                          onChange={(e) => onSearchChange(e.target.value)}
                        />
                      </div>
                    </div>

                    <UncontrolledDropdown className="inline">
                      <DropdownToggle
                        className="btn-icon-only text-dark"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Active
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Inactive
                        </DropdownItem>

                        <DropdownItem
                          // href="javascript:void(0)"
                          onClick={() => navigate("/createuser")}
                        >
                          Add New User
                        </DropdownItem>

                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Disable
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive style={{
                marginBottom: '25px'
              }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Firstname
                      {firstNameSortOrder === 'asc' ?
                        <i className="fa fa-fw fa-sort" onClick={() => {
                          sortItemHandler('first_name', 'asc');
                          setFirstNameSortOrder('desc')
                        }}></i>
                        :
                        <i className="fa fa-fw fa-sort" onClick={() => {
                          sortItemHandler('first_name', 'desc');
                          setFirstNameSortOrder('asc')
                        }}>
                        </i>}
                    </th>

                    <th scope="col">Lastname {lastNameSortOrder === 'asc' ?
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('last_name', 'asc'); setLastNameSortOrder('desc') }}></i>
                      :
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('last_name', 'desc'); setLastNameSortOrder('asc') }}></i>}
                    </th>

                    <th scope="col">Email {emailSortOrder === 'asc' ?
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('email', 'asc'); setEmailSortOrder('desc') }}></i>
                      :
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('email', 'desc'); setEmailSortOrder('asc') }}></i>}
                    </th>
                    <th scope="col">Phone {phoneSortOrder === 'asc' ?
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('phone', 'asc'); setPhoneSortOrder('desc') }}></i>
                      :
                      <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('phone', 'desc'); setPhoneSortOrder('asc') }}></i>}
                    </th>
                    {/* <th scope="col">Role</th> */}
                    <th scope="col">Status</th>
                    {/* <th scope="col">Created At</th> */}
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {(userList.length > 0) &&
                    userList.map((item) => {
                      return (<tr key={item?.id}>
                        <td>{item?.first_name}</td>
                        <td>
                          {item?.last_name}
                        </td>
                        <td>
                          {item?.email}
                        </td>
                        <td>
                          {item?.phone}
                        </td>
                        <td>
                          {parseInt(item?.is_active) === 1 ? 'Active' : 'Inactive'}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light justify-content-start"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" container="body">
                              <DropdownItem
                                // href="#"
                                onClick={() => viewUser(item?.id)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                // href="#"
                                onClick={() => navigate(`/user-management/${item?.id}`)}
                              >
                                Edit
                              </DropdownItem>
                              {/* <DropdownItem
                                href="#"
                              onClick={() => handleDelete(item?.id)}
                              >
                                Disable
                              </DropdownItem> */}
                              {/* <DropdownItem
                              href="#"
                                onClick={() => {
                                  setSelectedId(user.email);
                                  setResetModal(true);
                                }}
                              >
                                Reset Password
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>)
                    })
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            // className="active"
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

    </AdminLayout>

  );
};

export default UserList;
