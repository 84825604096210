import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs';

// reactstrap components
import { Collapse, NavbarBrand, Navbar, Nav, Container } from 'reactstrap';
import logo from '../../assets/images/header-logo.png';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [isOpen, setIsOpen] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [subcriptionManagement, setSubcriptionManagement] = useState(false);
  const [caseManagement, setcaseManagement] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();
  console.log('case', caseManagement);
  // const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    activeNavber();
  }, []);

  const activeNavber = () => {
    let userManage = [
      'invitation-management',
      'waiting-approval',
      'lawfirm-management',
      'physicians-management',
      'courtreporter-management',
    ];
    let subscriptionManage = [
      'singledepocalls-management',
      'subscriptions-management',
    ];
    let isExistUser = userManage.some(
      (item) => item === location.pathname?.split('/')[1]
    );
    let isExistSubscription = subscriptionManage.some(
      (item) => item === location.pathname?.split('/')[1]
    );
    if (isExistUser) setUserManagement(true);
    if (isExistSubscription) setSubcriptionManagement(true);
  };

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  useEffect(() => {
    if (
      location.pathname === '/case-management' ||
      location.pathname === '/deposition-management'
    ) {
      setcaseManagement(true);
    }
  }, [location]);

  const frontUser = (path) => {
    let modifyPath = path.split('/')[1];
    console.log("modifyPath==>>", modifyPath);
    let frontPath = ['/invitation-management', '/waiting-approval', '/lawfirm-management',
      '/physicians-management', '/courtreporter-management', '/expert-management']
    return frontPath.includes('/' + modifyPath);
  }

  const frontSubscription = (path) => {
    let frontPath = ['/subscriptions-management', '/singledepocalls-management'];
    return frontPath.includes(path);
  }

  const frontCase = (path) => {
    let frontPath = ['/case-management', '/deposition-management']
    return frontPath.includes(path);
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand className="pt-0">
          {/* <img alt="Hii" className="navbar-brand-img" src={logo} /> */}
          <h2 className="mb-0">Easy Depo</h2>
        </NavbarBrand>

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* <Nav navbar className="d-flex">
            <i className="fas fa-tachometer-alt"></i>
            Dashboard
          </Nav> */}

          <hr className="my-3" />
          {/* <Nav
            navbar
            className="d-flex"
            role="button"
            onClick={() => navigate("/category")}
          >
            <i className="fas fa-briefcase"></i>
            category
          </Nav> */}

          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/dashboard') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/dashboard')}
          >
            <i className="fas fa-tachometer-alt"></i>
            Dashboard
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/user-management') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/user-management')}
          >
            <i className="fas fa-briefcase"></i>
            Admin User Management
          </Nav>

          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/role-management') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/role-management')}
          >
            <i className="fas fa-briefcase"></i>
            Role Management
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontUser(location.pathname) ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => setUserManagement(!userManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Front User Management{' '}
            {userManagement ? (
              <BsChevronCompactUp
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            ) : (
              <BsChevronCompactDown
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            )}
          </Nav>
          {userManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/invitation-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/invitation-management')}
              >
                <i className="fas fa-briefcase"></i>
                Invitations
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${'/' + location.pathname.split('/')[1] === '/waiting-approval'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/waiting-approval')}
              >
                <i className="fas fa-briefcase"></i>
                Waiting For Approval
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/lawfirm-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/lawfirm-management')}
              >
                <i className="fas fa-briefcase"></i>
                Attorneys
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/physicians-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/physicians-management')}
              >
                <i className="fas fa-briefcase"></i>
                Physicians
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/courtreporter-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/courtreporter-management')}
              >
                <i className="fas fa-briefcase"></i>
                Court Reporters
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/expert-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/expert-management')}
              >
                <i className="fas fa-briefcase"></i>
                Experts
              </Nav>
            </div>
          )}
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontSubscription(location.pathname) ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => setSubcriptionManagement(!subcriptionManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Subscription Management{' '}
            {subcriptionManagement ? (
              <BsChevronCompactUp
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            ) : (
              <BsChevronCompactDown
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            )}
          </Nav>
          {subcriptionManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/subscriptions-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/subscriptions-management')}
              >
                <i className="fas fa-briefcase"></i>
                Membership
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/singledepocalls-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/singledepocalls-management')}
              >
                <i className="fas fa-briefcase"></i>
                Single Call
              </Nav>
            </div>
          )}
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontCase(location.pathname) ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => setcaseManagement(!caseManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Case Managment{' '}
            {caseManagement ? (
              <BsChevronCompactUp
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            ) : (
              <BsChevronCompactDown
                width={'1em'}
                height={'1.5em'}
                style={{ marginTop: '3px', paddingLeft: '2px' }}
              />
            )}
          </Nav>
          {caseManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/case-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/case-management')}
              >
                <i className="fas fa-briefcase"></i>
                Cases
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === '/deposition-management'
                  ? 'bg-green-color'
                  : null
                  }`}
                role="button"
                onClick={() => navigate('/deposition-management')}
              >
                <i className="fas fa-briefcase"></i>
                Depositions
              </Nav>
            </div>
          )}

          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/physicians-management")}
          >
            <i className="fas fa-briefcase"></i>
            Physicians Management
          </Nav> */}
          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/courtrepresentatives-management")}
          >
            <i className="fas fa-briefcase"></i>
            Court Representatives
          </Nav> */}
          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/manage-pricing")}
          >
            <i className="fas fa-briefcase"></i>
            Setting
          </Nav> */}

          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/commission-management")}
          >
            <i className="fas fa-briefcase"></i>
            Commission Management
          </Nav> */}
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/content-management') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/content-management')}
          >
            <i className="fas fa-briefcase"></i>
            Content Management
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/banner-management') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/banner-management')}
          >
            <i className="fas fa-briefcase"></i>
            Banner
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/contacts') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/contacts')}
          >
            <i className="fas fa-briefcase"></i>
            Request Contact List
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/services') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/services')}
          >
            <i className="fas fa-briefcase"></i>
            Services
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/contact-details') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/contact-details')}
          >
            <i className="fas fa-briefcase"></i>
            Company Contact Details
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/member-details') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/member-details')}
          >
            <i className="fas fa-briefcase"></i>
            Website Support's Details
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/terms-conditions') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/terms-conditions')}
          >
            <i className="fas fa-briefcase"></i>
            Terms & Conditions
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes('/payment-management') ? 'bg-green-color' : ''}`}
            role="button"
            onClick={() => navigate('/payment-management')}
          >
            <i className="fas fa-briefcase"></i>
            Payment Management
          </Nav>
          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/analytics-reports")}
          >
            <i className="fas fa-briefcase"></i>
            Analytics & Reports
          </Nav> */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
