import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import ListHeader from "../../../components/Headers/ListHeader";
import AdminLayout from "../../../layouts/AdminLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetService from "../../../services/getservice";
import Loader from "../../../components/Loader/Loader";
import useConditiongetService from "../../../services/conditiongetService";
import usePostService from "../../../services/postservice";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
const CategoryStep7 = () => {
  const navigate=useNavigate()
  const [searchParams] = useSearchParams();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [existsubCategoryList, setexistSubCategoryList] = useState([]);
  const [leftexistingSubCategory, setleftexistingSubCategory] = useState([]);
  const [getbutton, setgetbutton] = useState(false);
  const [extradata, setextradata] = useState([
    {
      header:"Title",body:"Title"
   },
    {
    header:"Image",body:"Image"
  },
  
  {
    header:"Price",body:"Price"
  }
  ]);
  const [headerList, setheaderList] = useState([]);
  const [formValues,setformvalues]=useState([
    {
      header:"",body:"",optionList:[]
    }
  ]);
  const [active, setactive] = useState("");
  const [getheader, setgetheader] = useState("");
  const [getheaderbutton, setgetheaderbutton] = useState(false);
  const [buttonState, setbuttonState] = useState(false);
  const [errorcategory,seterrorcategory]=useState("");
  const [handleChange, sethandleChange] = useState(false);
  const [nextbuttonState, setnextbuttonState] = useState(false);
  let {response,isFetchItems}=useGetService(`/api/category/newgetSubCategoriesListStep6or7/${searchParams.get("topParentId")}/7`);
  let {response2}=useConditiongetService(`/api/category/newgetInputHeadersListStep6or7/${active}/7`,getbutton);
  let {response2:{responsekey}}=useConditiongetService(`/api/category/newGetApiStep6or7/${active}/${getheader}`,getheaderbutton);
  let response3=usePostService(formValues,`/api/category/newcreateProductsDetailsStep7/${active}`,buttonState);
  let response4=usePostService(null,`/api/category/submitStep7/${searchParams.get("topParentId")}`,nextbuttonState);
  console.log("response3",response3);
useEffect(() => {
  if(response?.status ==="200-"){
    if(response?.data?.normalSubCategories?.length >0){
      setSubCategoryList(response?.data?.normalSubCategories)
      setactive(response?.data?.normalSubCategories[0]?.SubCategoryId)
    }

    if(response?.data?.existingSubCategories?.length >0 ){
    setexistSubCategoryList(response?.data?.existingSubCategories)
    }
  }
}, [response]);
useEffect(() => {
  if(response2?.status ==="200-"&&getbutton){
    if(response2?.data?.headerOptions?.length >0){
      setheaderList(response2?.data?.headerOptions)
    }

    if(response2?.data?.existingheader?.length >0 ){
      setformvalues(response2?.data?.existingheader)
      }
    setgetbutton(false)
  }
  if(response2?.status ==="200-"&&getheaderbutton){
    let temp=[...formValues]
    let index = temp.findIndex(obj => obj.header === getheader);
    if(response2?.data?.optionList?.length >1){
      temp[index].optionList=response2?.data?.optionList
      // setheaderList(response2?.data?.headerOptions)
    }
    else {
      temp[index].optionList=response2?.data?.optionList
      temp[index].body=response2?.data?.optionList[0]
    }
    setformvalues(temp)
    setgetheaderbutton(false)
  }
}, [response2]);
useEffect(() => {
  if(response3?.status ==="200-"){
    toast.success(response3?.data?.message)
    setbuttonState(false)
    let temp=[...existsubCategoryList]
    temp.push(response3?.data?.response)
    setexistSubCategoryList(temp)
    const leftcategory=subCategoryList?.filter(x=>x.SubCategoryId !==active)
    setSubCategoryList(leftcategory)
    setformvalues([{header:"",body:"",optionList:[]}])
    if(leftcategory?.length>0){
      setactive(leftcategory[0]?.SubCategoryId)
    }
    else {
      setactive("")
    }
    sethandleChange(false)
    setleftexistingSubCategory([])
  }
}, [response3]);
useEffect(() => {
  if(response4 !==""){
   setnextbuttonState(false);
   toast.success(response4?.data?.message);
   navigate(`/category`)
  }
}, [response4]);
useEffect(() => {
  if(active !==""){
   setgetbutton(true)
   setheaderList([])
  }
}, [active]);

const handleDelete=(i)=>{
  let newFormValues = [...formValues];
  newFormValues.splice(i, 1);
  setformvalues(newFormValues);
  sethandleChange(true)
  seterrorcategory("")
 }
 const handlemore=(categoryId)=>{
  if (active ===categoryId){
    let temp=[...formValues]
    temp.push( {header:"",body:"",optionList:[]})
    setformvalues(temp)
  }
  // seterrorcategory("")
 }
 console.log("exist",existsubCategoryList);
//  console.log("header",headerList);
//  console.log("formvalues",formValues);
 const handleactive=(i,type)=>{
  let valueExist = subCategoryList.some(obj => obj.SubCategoryId === leftexistingSubCategory[0]?.SubCategoryId);
  if(type ==="new"){
    let temp=[...subCategoryList]
    let temp2=[...existsubCategoryList]
    if(valueExist){
      let value=temp?.filter(x=>x.SubCategoryId !==leftexistingSubCategory[0].SubCategoryId)
      temp=value;
      temp2.push(leftexistingSubCategory[0])
      setexistSubCategoryList(temp2)
      setleftexistingSubCategory([])
    }
    // const temp=[...normalCategories]
    const activecategory=temp?.filter(x=>x.SubCategoryId ===i)
    const leftcategory=temp?.filter(x=>x.SubCategoryId !==i)
    let newcreatecategory={SubCategoryName: activecategory[0]?.SubCategoryName,SubCategoryId: activecategory[0]?.SubCategoryId}
    leftcategory?.unshift(newcreatecategory)
    setSubCategoryList(leftcategory)
    setactive(i)
    setformvalues([{
      header:"",body:"",optionList:[]
    }])
  window.scroll(20,20)
  }
  if(type ==="exist"){
    let temp=[...subCategoryList]
    let temp2=[...existsubCategoryList]
    if(valueExist){
      let value=temp?.filter(x=>x.SubCategoryId !==leftexistingSubCategory[0].SubCategoryId)
      temp=value;
      temp2.push(leftexistingSubCategory[0])
      // setexistingSubCategoryList(temp2)
      // setleftexistingSubCategory([])
    }
    const activecategory=temp2?.filter(x=>x.SubCategoryId ===i)
    const leftcategory=temp2?.filter(x=>x.SubCategoryId !==i)
    setexistSubCategoryList(leftcategory)
    setleftexistingSubCategory(activecategory);
    let newcreatecategory={SubCategoryName: activecategory[0]?.SubCategoryName,SubCategoryId: activecategory[0]?.SubCategoryId}
     temp.unshift(newcreatecategory)
     setSubCategoryList(temp);
     setactive(i)
    setformvalues([{
      header:"",body:"",optionList:[]
    }])
    window.scroll(20,20)
  }
  seterrorcategory("")
 }
 const handleInput=(e,i,type)=>{
  let newFormValues = [...formValues];
  let headerExists = newFormValues.some(obj => obj.header === e.target.value);
  let bodyExists = newFormValues.some(obj => obj.body === e.target.value);
  if(type ==="header"&&!headerExists){
    newFormValues[i].header=e.target.value;
    setgetheader(e.target.value)
     setformvalues(newFormValues);
     setgetheaderbutton(true)
     sethandleChange(true)
  }
  if(type ==="body"&&!bodyExists){
    newFormValues[i].body=e.target.value;
     setformvalues(newFormValues);
     sethandleChange(true)
  }
 seterrorcategory("")
}
console.log("for",formValues);
const validate=()=>{
  let isError=false;
  let validation = formValues?.every(item => item.header && item.body);
 if(!validation){
  seterrorcategory("Product Detail item can't be empty")
  isError=true;
 } 
 return isError;
}
const handleSave=()=>{
  let isError = validate();
    if (!isError) {
      setbuttonState(true)
      seterrorcategory("");
    }
 }
 const validate2=()=>{
  let isError2=false;
  let validation2 = formValues?.every(item => item.header && item.body);
 if(subCategoryList?.length >1){
  seterrorcategory("Save product details before moving to next page")
  window.scroll(20,20)
  isError2=true;
 } 
 if(subCategoryList?.length ===1 && !validation2){
  seterrorcategory("Save product details before moving to next page")
  window.scroll(20,20)
  isError2=true;
 } 
 if(handleChange){
  seterrorcategory("Save product details moving to next page")
  window.scroll(20,20)
  isError2=true;
 }
 return isError2;
}
//  const handlenext=()=>{
//   let isError2 = validate2();
//   if (!isError2) {
//     navigate(
//       `/categorystep?step=7&topParentId=${searchParams.get(
//         "topParentId"
//       )}`
//     )
//   }
//  }
 const handleExample=()=>{
  window.open(`http://dev81.developer24x7.com:3055/example/${active}`, "_blank");
 }
 const handlesubmit=()=>{
  let isError2 = validate2();
  if (!isError2) {
    setnextbuttonState(true)
    // navigate(`/categorystep?step=7&topParentId=${searchParams.get("topParentId")}`)
    // sethandleChange(false)
  }
 }
// console.log("Sub",subCategoryList);
  return (
    <AdminLayout name="Category">
      <>
        {isFetchItems && <Loader></Loader>}
        <ListHeader />
        {/* <ToastContainer /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                          Choose display items in product detail
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form className="justify-content-center">
                        {subCategoryList?.map((item,i8) => (
                          <div key={item.SubCategoryId}>
                            <Row>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end"
                              >
                                <label
                                // className="form-control-label"
                                // htmlFor="input-first-name"
                                >
                                  {item?.SubCategoryName}
                                </label>
                              </Col>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end padding-manage"
                              >
                                {active !==item?.SubCategoryId ?
                                  <div
                                  className="btn btn-lg btn-icon-edit"
                                  onClick={() => handleactive(item?.SubCategoryId,"new")}
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 100.000000 100.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M807 920 c-14 -11 -29 -20 -34 -20 -4 0 -153 -145 -329 -322 l-321
                                       -323 -33 -88 c-22 -60 -29 -92 -22 -99 7 -7 39 0 99 22 l88 33 323 321 c177
                                        176 322 325 322 329 0 5 9 20 20 34 27 35 26 78 -5 108 -30 31 -73 32 -108 5z
                                        m81 -32 c17 -17 15 -43 -4 -58 -13 -11 -19 -9 -41 12 -16 16 -23 32 -20 42 7
                                       19 48 21 65 4z m-75 -74 c20 -19 37 -38 37 -41 0 -4 -10 -17 -22 -30 l-22 -23
                                        -43 42 -43 42 22 23 c12 13 24 23 27 23 3 0 23 -16 44 -36z m-78 -84 l39 -40
                                        -254 -255 c-140 -140 -260 -255 -267 -255 -6 0 -13 12 -15 27 -2 22 -9 29 -31
                                        31 -15 2 -27 9 -27 15 0 11 499 517 510 517 3 0 24 -18 45 -40z m-539 -534 c1
                                        0 5 -11 8 -23 6 -19 2 -25 -19 -33 -54 -21 -66 -9 -45 45 8 21 14 25 33 19 12
                                        -3 23 -7 23 -8z"
                                      />
                                    </g>
                                  </svg>
                                </div>:null
                            }
                              
                              </Col>
                            </Row>
                           
                            {active === item.SubCategoryId ? (
                              <>
                               {extradata?.map((data2,i7)=>(
                                 <Row key={i7}>
                                 <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Header
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        value={data2?.header}
                                      
                                      >
                                        <option value="">
                                          {data2?.header}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Body
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        value={data2?.body}
                                        // onChange={(e) => handleInput(e, i)}
                                      >
                                        <option value="">
                                          {data2?.body}
                                        </option>
                                        
                                        {/* {headerList?.map((item3, i2) => (
                                          <option value={item3} key={i2}>
                                            {item3}
                                          </option>
                                        ))} */}
                                      </Input>
                                      {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                    </FormGroup>
                                  </Col>
    
                                
                              </Row>
                            ))}
                             {formValues?.map((item2, i) => (
                                <Row key={i}>
                                  <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Header
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        value={item2?.header}
                                        onChange={(e) => handleInput(e, i,"header")}
                                      >
                                        <option value="">
                                          Choose From key
                                        </option>
                                        {headerList?.map((item3, i2) => (
                                          <option value={item3} 
                                          key={i2}
                                          className={formValues.some(obj => obj.header === item3)?"option-select-property":null}
                                          >
                                            {item3}
                                          </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Body
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        value={item2.body}
                                        onChange={(e) => handleInput(e, i,"body")}
                                      >
                                        <option value="">
                                          Choose From key
                                        </option>
                                        {item2?.optionList?.map((item4,i3)=>(
                                             <option value={item4} key={i3}
                                             className={formValues.some(obj => obj.body === item4)?"option-select-property":null}
                                             >
                                             {item4}
                                           </option>
                                        ))}
                                       
                                      </Input>
                                      {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="2">
                                      <div
                                        className="btn btn-lg btn-icon"
                                        onClick={() => handleDelete(i)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </div>
                                    </Col>
                                </Row>
                              ))}
                              </>
                              
                            ) : (
                              <Row>
                                 <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Header
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        // value={userCreateUpdate.role_id}
                                      
                                      >
                                        <option value="">
                                          Choose From key
                                        </option>
                                        {headerList?.map((item3, i2) => (
                                          <option value={item3} key={i2}>
                                            {item3}
                                          </option>
                                        ))}
                                      </Input>
                                      {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Body
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        // value={userCreateUpdate.role_id}
                                        // onChange={(e) => handleInput(e, i)}
                                      >
                                        <option value="">
                                          Choose From key
                                        </option>
                                        
                                        {/* {headerList?.map((item3, i2) => (
                                          <option value={item3} key={i2}>
                                            {item3}
                                          </option>
                                        ))} */}
                                      </Input>
                                      {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                    </FormGroup>
                                  </Col>

                                
                              </Row>
                            )}
                            <Row className="mb-2">
                              <Col lg="12">
                              {errorcategory!=="" && i8===0 ? (
                                 <ErrorMessage errorMsg={errorcategory} />
                               ) : null}
                              </Col>
                            </Row>
                             <Row>
                            <Col
                                lg="4"
                                className=""
                              >
                               
                                <Button
                                  className="add-more-icon"
                                  onClick={() => handlemore(item.SubCategoryId)}
                                  disabled={active !== item.SubCategoryId}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                  </svg>
                                  <span>Add More</span>
                                </Button>
                                
                              </Col>
                              <Col lg="4">
                              <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled={active !== item.SubCategoryId}
                                  onClick={handleExample}
                                >
                                  See Example
                                </Button>
                              </Col>
                              <Col lg="4"
                                className="d-flex justify-content-end">
                                  <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled={active !== item.SubCategoryId}
                                  onClick={handleSave}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                            <hr className="my-4" />
                          </div>
                        ))}
                        {existsubCategoryList?.map((data)=>(
                          <div key={data?.SubCategoryId}>
                            <Row>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end"
                              >
                                <label
                                // className="form-control-label"
                                // htmlFor="input-first-name"
                                >
                                  {data?.SubCategoryName}
                                </label>
                              </Col>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end padding-manage"
                              >
                                <div
                                  className="btn btn-lg btn-icon-edit"
                                  onClick={() => handleactive(data?.SubCategoryId,"exist")}
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 100.000000 100.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M807 920 c-14 -11 -29 -20 -34 -20 -4 0 -153 -145 -329 -322 l-321
                                       -323 -33 -88 c-22 -60 -29 -92 -22 -99 7 -7 39 0 99 22 l88 33 323 321 c177
                                        176 322 325 322 329 0 5 9 20 20 34 27 35 26 78 -5 108 -30 31 -73 32 -108 5z
                                        m81 -32 c17 -17 15 -43 -4 -58 -13 -11 -19 -9 -41 12 -16 16 -23 32 -20 42 7
                                       19 48 21 65 4z m-75 -74 c20 -19 37 -38 37 -41 0 -4 -10 -17 -22 -30 l-22 -23
                                        -43 42 -43 42 22 23 c12 13 24 23 27 23 3 0 23 -16 44 -36z m-78 -84 l39 -40
                                        -254 -255 c-140 -140 -260 -255 -267 -255 -6 0 -13 12 -15 27 -2 22 -9 29 -31
                                        31 -15 2 -27 9 -27 15 0 11 499 517 510 517 3 0 24 -18 45 -40z m-539 -534 c1
                                        0 5 -11 8 -23 6 -19 2 -25 -19 -33 -54 -21 -66 -9 -45 45 8 21 14 25 33 19 12
                                        -3 23 -7 23 -8z"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              
                              </Col>
                            </Row>
                            {extradata?.map((data2,i7)=>(
                                 <Row key={i7}>
                                 <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Header
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        value={data2?.header}
                                      
                                      >
                                        <option value="">
                                          {data2?.header}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    lg="5"
                                    // className="d-flex justify-content-end "
                                  >
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Item Body
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        disabled
                                        value={data2?.body}
                                        // onChange={(e) => handleInput(e, i)}
                                      >
                                        <option value="">
                                          {data2?.body}
                                        </option>
                                        
                                        {/* {headerList?.map((item3, i2) => (
                                          <option value={item3} key={i2}>
                                            {item3}
                                          </option>
                                        ))} */}
                                      </Input>
                                      {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                    </FormGroup>
                                  </Col>
    
                                
                              </Row>
                            ))}
                            {data?.existingdata?.map((data3,i6)=>(
                               <Row key={i6}>
                               <Col
                                  lg="5"
                                  // className="d-flex justify-content-end "
                                >
                                  <FormGroup className="form-group">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Item Header
                                    </label>
                                    <Input
                                      type="select"
                                      name="role_id"
                                      id="selectMulti"
                                      disabled
                                      value={data3?.header}
                                    
                                    >
                                      <option value="">
                                        {data3?.header}
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col
                                  lg="5"
                                  // className="d-flex justify-content-end "
                                >
                                  <FormGroup className="form-group">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Item Body
                                    </label>
                                    <Input
                                      type="select"
                                      name="role_id"
                                      id="selectMulti"
                                      disabled
                                      value={data3?.body}
                                      // onChange={(e) => handleInput(e, i)}
                                    >
                                      <option value="">
                                        {data3?.body}
                                      </option>
                                      
                                      {/* {headerList?.map((item3, i2) => (
                                        <option value={item3} key={i2}>
                                          {item3}
                                        </option>
                                      ))} */}
                                    </Input>
                                    {/* {error.role_id ? (
                            <ErrorMessage errorMsg={error.role_id} />
                          ) : null} */}
                                  </FormGroup>
                                </Col>
  
                              
                            </Row>
                            ))}
                            
                          <Row>
                            <Col
                                lg="4"
                                className=""
                              >
                                <Button
                                  className="add-more-icon"
                                  disabled
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                  </svg>
                                  <span>Add More</span>
                                </Button>
                                
                              </Col>
                              <Col lg="4">
                              <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled
                                >
                                  See Example
                                </Button>
                              </Col>
                              <Col lg="4"
                                className="d-flex justify-content-end">
                                  <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                            <hr className="my-4" />
                          </div>
                        ))}
                          <FormGroup className="d-flex justify-content-between">
                          <Button
                            color="info"
                            className="no-transform"
                            onClick={() =>
                              navigate(
                                `/categorystep?step=6&topParentId=${searchParams.get(
                                  "topParentId"
                                )}`
                              )
                            }
                          >
                            &#10229; Previous Step
                          </Button>
                          <Button
                            color="warning"
                            className="no-transform"
                            onClick={handlesubmit}
                          >
                            Submit
                          </Button>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </AdminLayout>
  );
};

export default CategoryStep7;
