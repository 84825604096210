import React,{useState,useEffect} from 'react'
import AuthLayout from "../../layouts/AuthLayout";
import {
    Card,
    Table,
    Container,
    Row
  } from "reactstrap";
import { useParams } from 'react-router-dom';
import useGetService from '../../services/getservice';
import Loader from '../../components/Loader/Loader';
const ExampleProductComparison = () => {
    let {id} = useParams();
    let {response,isFetchItems}=useGetService(`/api/category/getExampleProductListStep6or7/${id}`);
    const [exampledata, setexampledata] = useState([]);
    useEffect(() => {
      if(response?.status ==="200-"){
        setexampledata(response?.data?.exampleArray)
      }
    }, [response]);
    console.log("response",exampledata);
    // console.log("res",id);
  return (
    <AuthLayout data="Product Comparison">
        <Container className="mt--7" fluid>
        {isFetchItems && <Loader></Loader>}
        <Row>
          <div className="col">
            <Card className="shadow" style={{
                overflow: 'hidden'}} >
              <Table className="align-items-center table-flush c-table-product" responsive style={{
                marginBottom: '25px'
              }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Header</th>
                    <th scope="col">Body key</th>
                    <th scope="col">Product1</th>
                    <th scope="col">Product2</th>
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody>
                    {exampledata?.map((item,i)=>(
                        <tr key={i}>
                        <td>{item?.header}</td>
                        <td>{item?.body}</td>
                        <td>
                          {item?.header ==="Image"?
                           <img src={item?.product1value} width="200" height="150" alt="Product1" />
                          :item?.header==="Price"?<>${item?.product1value}</>:item?.product1value}
                          
                        </td>
                        <td>
                        {item?.header ==="Image"?
                        <img src={item?.product2value} width="200" height="150" alt="Product2" />
                        :item?.header==="Price"?<>${item?.product2value}</>:item?.product2value}
                        </td>
                    </tr>
                    ))}
                   
                  
               
                </tbody>
              </Table>
              
            </Card>
          </div>
        </Row>
      </Container>
    
    </AuthLayout>
  )
}

export default ExampleProductComparison