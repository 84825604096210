/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useGetService from "../../services/getservice";
import usePutService from "../../services/putservice";
import useConditiongetService from "../../services/conditiongetService";
import useDebounce from "../../components/custom-hooks/useDebounce";
import Loader from "../../components/Loader/Loader";
import { DateFormat } from "../../helpers/utils";

const InvitationManagement = () => {
  const [invitationList, setInvitationList] = useState([]);

  const [asc, setAsc] = useState("asc");

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [updatedInvitation, setUpdatedInvitation] = useState(false);

  const [addInvitation, setAddInvitation] = useState({
    email: "",
  });

  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const [conditionButton, setConditionButton] = useState(false);
  const [sortName, setSortName] = useState('');
  const [finalsortOrder, setFinalSortOrder] = useState('');

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      setConditionButton(true);
      setSearchInput(debouncedSearchTerm);
    }
    else if (debouncedSearchTerm === "" && searchInput === "") {
      setConditionButton(true);
      setSearchInput("");
    }
  }, [debouncedSearchTerm]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    // if (searchInput !== "") {
    //   const filteredData = invitationList.filter((item) => {
    //     return Object.values(item)
    //       .join("")
    //       .toLowerCase()
    //       .includes(searchInput.toLowerCase());
    //   });
    //   setFilteredResults(filteredData);
    // } else {
    //   setFilteredResults(invitationList);
    // }
  };

  // useEffect(() => {
  //   if (asc === "asc") {
  //     let sorted = invitationList.sort((a, b) => a.id - b.id);
  //     setFilteredResults(sorted);
  //   } else {
  //     let sorted = invitationList.sort((a, b) => b.id - a.id);
  //     setFilteredResults(sorted);
  //   }
  // }, [asc]);

  const handleSort = (field) => {
    setSortName(field)
    if (asc === "asc") {
      setAsc("desc");
    } else {
      setAsc("asc");
    }
    setConditionButton(true);
  };

  const { response2: getAllInvitations, isFetchItems } = useConditiongetService(
    `api/invitation/getInvitees?sort_name=${sortName}&search=${debouncedSearchTerm}&sort_order=${asc}&size=20&page=${page}`, conditionButton
  );

  const updateInvitation = usePutService(
    addInvitation,
    `api/invitation/resendInvite`,
    updatedInvitation
  );

  useEffect(() => {
    if (getAllInvitations?.status === "200-" && conditionButton) {
      setInvitationList(getAllInvitations?.data?.data);
      settotalPage(getAllInvitations?.data?.totalPages);
      setConditionButton(false);
    }
  }, [getAllInvitations]);

  const resendInvitation = (invitation) => {
    setAddInvitation({
      email: invitation.email,
    });
    setUpdatedInvitation(true);
  };

  useEffect(() => {
    if (updateInvitation.status !== "200-" && updateInvitation !== "") {
      toast.error(updateInvitation.data.message);
    } else if (updateInvitation !== "") {
      toast.success(updateInvitation.data.message);
    }
    setUpdatedInvitation(false);
    setAddInvitation({
      email: "",
    });
  }, [updateInvitation]);

  const onPageChange = (value) => {
    setPage(value)
    setConditionButton(true)
  }

  return (
    <AdminLayout name="Invitation Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="mb-0">Invitation List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "80%",
                        display: "inline-block",
                        marginLift: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div>
                      <button onClick={() => handleSort()}>
                        <i className="fa fa-fw fa-sort"></i>
                        Sort
                      </button>
                    </div>
                  </div> */}
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col">ID</th> */}
                    <th scope="col">
                      Name
                      <i
                        onClick={() => handleSort("first_name")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Email
                      <i
                        onClick={() => handleSort("email")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Role
                      <i
                        onClick={() => handleSort("role_id")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Send-Date
                      <i
                        onClick={() => handleSort()}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col"></th>


                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                {
                  // searchInput.length > 0
                  //   ? invitationList.map((invitation, index) => {
                  //     return (
                  //       <tr key={invitation?.id}>
                  //         <td>
                  //           {invitation.first_name} {invitation.last_name}
                  //         </td>
                  //         <td>{invitation.email}</td>
                  //         <td>{invitation.role_name}</td>
                  //         <td>{invitation.updatedAt}</td>
                  //         <td>{(parseInt(invitation.status) === 0) && 'Pending'}</td>
                  //         <td><UncontrolledDropdown>
                  //           <DropdownToggle
                  //             className="btn-icon-only text-light justify-content-start"
                  //             href="#pablo"
                  //             role="button"
                  //             size="sm"
                  //             color=""
                  //             onClick={(e) => e.preventDefault()}
                  //           >
                  //             <i className="fas fa-ellipsis-v" />
                  //           </DropdownToggle>
                  //           <DropdownMenu className="dropdown-menu-arrow" container="body">
                  //             <DropdownItem
                  //               href="#"
                  //               style={{ display: 'none' }}
                  //               onClick={() => viewUser(item?.id)}
                  //             >
                  //               View
                  //             </DropdownItem>
                  //             <DropdownItem
                  //               href="#"
                  //               style={{ display: 'none' }}
                  //               onClick={() => navigate(`/user-management/${item?.id}`)}
                  //             >
                  //               Edit
                  //             </DropdownItem>
                  //             <DropdownItem
                  //               href="#"
                  //               style={{ display: 'none' }}
                  //               onClick={() => handleDelete(item?.id)}
                  //             >
                  //               Disable
                  //             </DropdownItem>
                  //             <DropdownItem
                  //               href="#"
                  //               onClick={(e) => {
                  //                 resendInvitation(invitation);
                  //               }}
                  //             >
                  //               Resend Invite
                  //             </DropdownItem>
                  //           </DropdownMenu>
                  //         </UncontrolledDropdown></td>
                  //       </tr>
                  //     );
                  //   })
                  //   :
                  invitationList.length > 0 &&
                  invitationList.map((invitation, index) => {
                    return (
                      <tr key={invitation?.id}>
                        <td>
                          {invitation.first_name} {invitation.last_name}
                        </td>
                        <td>{invitation.email}</td>
                        <td>{invitation.role_name}</td>
                        <td>
                            {invitation?.updatedAt
                              ? moment(
                                DateFormat(invitation?.updatedAt)
                              ).format('MM/DD/yyyy H:mm')
                              : '--'}
                          </td>
                        <td>{(parseInt(invitation.status) === 0) && 'Pending'}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light justify-content-start"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" container="body">
                              <DropdownItem
                                // href="#"
                                style={{ display: 'none' }}
                                onClick={() => viewUser(item?.id)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                style={{ display: 'none' }}
                                onClick={() => navigate(`/user-management/${item?.id}`)}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                style={{ display: 'none' }}
                                onClick={() => handleDelete(item?.id)}
                              >
                                Disable
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={(e) => {
                                  resendInvitation(invitation);
                                }}
                              >
                                Resend Invite
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>

                      </tr>
                    );
                  })}
              </Table>
              <CardFooter className="py-4">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            // className="active"
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default InvitationManagement;
