import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import ListHeader from "../../../components/Headers/ListHeader";
import AdminLayout from "../../../layouts/AdminLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetService from "../../../services/getservice";
import Loader from "../../../components/Loader/Loader";
import useConditiongetService from "../../../services/conditiongetService";
import usePostService from "../../../services/postservice";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import CategoryStep4Modal from "../../../components/Modal/CategoryStep4Modal";
const CategoryStep4 = () => {
  const navigate=useNavigate();
  const [searchParams] = useSearchParams();
  const [normalCategories, setnormalCategories] = useState([]);
  const [existingCategoryList, setexistingCategoryList] = useState([]);
  const [getbutton, setgetbutton] = useState(false);
  const [active, setactive] = useState("");
  const [subChildArrayList, setsubChildArrayList] = useState([]);
  const [buttonState, setbuttonState] = useState(false);
  const [nextbuttonState, setnextbuttonState] = useState(false);
  const [handleChange, sethandleChange] = useState(false);
  const [payload, setpayload] = useState([]);
  const [errorcategory,seterrorcategory]=useState("");
  const [UploadImageModal, setUploadImageModal] = useState(false);
  const [leftexistingCategory, setleftexistingCategory] = useState([]);
  const [formValues,setformvalues]=useState([{
    DisplayName: "",
      Id: "",
      amazonDisplayName:"",
      categoryId:null,
  }]);
  let {response,isFetchItems}=useGetService(`/api/category/getNormalCategoriesListStep4/${searchParams.get("topParentId")}`);
  let {response2}=useConditiongetService(`/api/category/subChildArrayFromCategoryIdStep4/${active}`,getbutton);
  let response3=usePostService(formValues,`/api/category/createCategoryStep4/${active}`,buttonState);
  let response4=usePostService(payload,`/api/category/submitStep4/${searchParams.get("topParentId")}`,nextbuttonState);
  useEffect(() => {
    if(response4 !==""){
     setnextbuttonState(false);
     navigate(`/categorystep?step=5&topParentId=${searchParams.get("topParentId")}`)
    }
  }, [response4]);
  useEffect(() => {
    if(response3?.status ==="200-" && buttonState){
      toast.success(response3?.data?.message)
      setbuttonState(false)
      // setactive("")
      // if(response?.data?.normalCategoryList?.length >0){
      //   setnormalCategories(response?.data?.normalCategoryList)
      //   setactive(response?.data?.normalCategoryList[0]?.categoryId)
      // }
      let temp=[...existingCategoryList]
      temp.push(response3?.data?.response)
      setexistingCategoryList(temp)
      const leftcategory=normalCategories?.filter(x=>x.categoryId !==active)
      setnormalCategories(leftcategory)
      setformvalues([{
        DisplayName: "",
        Id: "",
        categoryId:null,
        amazonDisplayName:""
      }])
      if(leftcategory?.length>0){
        setactive(leftcategory[0]?.categoryId)
      }
      else {
        setactive("")
      }
      sethandleChange(false)
      setleftexistingCategory([])
      // if(response?.data?.existingCategoryList?.length >0 ){
      
      // }
    }
    else if(buttonState){
      seterrorcategory(response2?.data?.message)
      setbuttonState(false)
    }
  }, [response3]);
  useEffect(() => {
    if(response?.status ==="200-"){
      if(response?.data?.normalCategoryList?.length >0){
        setnormalCategories(response?.data?.normalCategoryList)
        setactive(response?.data?.normalCategoryList[0]?.categoryId)
      }

      if(response?.data?.existingCategoryList?.length >0 ){
      setexistingCategoryList(response?.data?.existingCategoryList)
      }
    }
  }, [response]);
  useEffect(() => {
    if(active !==""){
     setgetbutton(true)
    }
  }, [active]);
  useEffect(() => {
    if(response2?.status ==="200-"){
      if(response2?.data?.inputSubChildArrayList?.length >0){
        setsubChildArrayList(response2?.data?.inputSubChildArrayList)
      }

      if(response2?.data?.existingSubChildArrayList?.length >0 ){
      setformvalues(response2?.data?.existingSubChildArrayList)
      }
      setgetbutton(false)
    }
  }, [response2]);
  console.log("left",leftexistingCategory);
  console.log("exist",existingCategoryList);
  console.log("normal",normalCategories);
  const handleInput=(e,i,type)=>{
    let newFormValues = [...formValues];
     let idExists = newFormValues.some(obj => obj.Id === e.target.value);
    if (type ==="id"&&!idExists){
      const value=subChildArrayList?.filter(x=>x.Id ===e.target.value)
      newFormValues[i].Id =value[0].Id;
      newFormValues[i].amazonDisplayName=value[0].amazonDisplayName
      newFormValues[i].DisplayName=value[0].amazonDisplayName
    }
     else if(newFormValues[i].Id !=="") {
    newFormValues[i].DisplayName=e.target.value;
     }
     setformvalues(newFormValues);
     seterrorcategory("");
     sethandleChange(true)
  }
  const handlemore=(categoryId)=>{
    if (active ===categoryId){
      let temp=[...formValues]
      temp.push({DisplayName: "",Id: "",categoryId:null,amazonDisplayName:""})
      setformvalues(temp)
    }
    seterrorcategory("")
   }
   const handleDelete=(i2)=>{
    let newFormValues = [...formValues];
    newFormValues.splice(i2, 1);
    setformvalues(newFormValues);
    sethandleChange(true)
    seterrorcategory("");
   }
   const validate=()=>{
    let isError=false;
    let validation = formValues?.every(item => item.DisplayName && item.Id);
    let valueArr = formValues.map(function(item){ return item.DisplayName });
   let isDuplicate = valueArr.some(function(item, idx){ 
    return valueArr.indexOf(item) !== idx 
    });
   if(!validation){
    seterrorcategory("Sub category can't be empty")
    isError=true;
   } 
   if(isDuplicate){
    seterrorcategory("Displayname should be unique for subcategories")
    isError=true;
   } 
   return isError;
  }
   const handleSave=()=>{
    let isError = validate();
    if (!isError) {
      setbuttonState(true)
      seterrorcategory("");
    }
   }
   const handleactive=(i,type)=>{
    let valueExist = normalCategories.some(obj => obj.categoryId === leftexistingCategory[0]?.categoryId);
    if(type ==="new"){
      let temp=[...normalCategories]
      let temp2=[...existingCategoryList]
      if(valueExist){
        let value=temp?.filter(x=>x.categoryId !==leftexistingCategory[0].categoryId)
        temp=value;
        temp2.push(leftexistingCategory[0])
        setexistingCategoryList(temp2)
        setleftexistingCategory([])
      }
      // const temp=[...normalCategories]
      const activecategory=temp?.filter(x=>x.categoryId ===i)
      const leftcategory=temp?.filter(x=>x.categoryId !==i)
      let newcreatecategory={categoryId:activecategory[0].categoryId,
      browseNodeId:activecategory[0].browseNodeId,DisplayName:activecategory[0].DisplayName,
      amazonDisplayName:activecategory[0].amazonDisplayName}
      leftcategory?.unshift(newcreatecategory)
      setnormalCategories(leftcategory)
      setactive(i)
    setformvalues([{
      DisplayName: "",
      Id: "",
      categoryId:null,
      amazonDisplayName:""
    }])
    window.scroll(20,20)
    }
    if(type ==="exist"){
      let temp=[...normalCategories]
      let temp2=[...existingCategoryList]
      if(valueExist){
        let value=temp?.filter(x=>x.categoryId !==leftexistingCategory[0].categoryId)
        temp=value;
        temp2.push(leftexistingCategory[0])
        // setexistingCategoryList(temp2)
        // setleftexistingSubCategory([])
      }
      const activecategory=temp2?.filter(x=>x.categoryId ===i)
      const leftcategory=temp2?.filter(x=>x.categoryId !==i)
      setexistingCategoryList(leftcategory)
      setleftexistingCategory(activecategory)
      let newcreatecategory={categoryId:activecategory[0].categoryId,
        browseNodeId:activecategory[0].browseNodeId,
        DisplayName:activecategory[0].DisplayName,amazonDisplayName:activecategory[0].amazonDisplayName}
       temp.unshift(newcreatecategory)
       setnormalCategories(temp);
       setactive(i)
       window.scroll(20,20)
       setformvalues([{
        DisplayName: "",
        Id: "",
        categoryId:null,
        amazonDisplayName:""
      }])
    }
    seterrorcategory("");
   }
   const validate2=()=>{
    let isError2=false;
    let validation2 = formValues?.every(item => item.DisplayName && item.Id);
   if(normalCategories?.length >1){
    seterrorcategory("Save sub categories before moving to next page")
    window.scroll(20,20)
    isError2=true;
   } 
   if(normalCategories?.length ===1 && !validation2){
    seterrorcategory("Save sub categories before moving to next page")
    window.scroll(20,20)
    isError2=true;
   } 
   if(handleChange){
    seterrorcategory("Save sub categories before moving to next page")
    window.scroll(20,20)
    isError2=true;
   }
   return isError2;
  }
   const handlenext=()=>{
    let isError2 = validate2();
    if (!isError2) {
      let temp=[...payload]
      if(normalCategories?.length ===1){
        const data= formValues.map(obj => obj?.categoryId);
        temp.push(...data)
      }
      const output = existingCategoryList.flatMap(({ subCategoryList, ...rest }) =>
    subCategoryList.map(item => ({ ...item}))
);
const result= output.map(obj => obj?.categoryId);
temp.push(...result)
setpayload(temp)
setnextbuttonState(true)
    }
   }
   const handleImage=()=>{
     setUploadImageModal(true)
   }
  return (
    <AdminLayout name="Category">
        {isFetchItems && <Loader></Loader>}
        <ListHeader />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            Choose sub categories
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form className="justify-content-center">
                        {normalCategories?.map((item, i) => (
                          <div key={i}>
                            <Row>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end"
                              >
                                <label
                                // className="form-control-label"
                                // htmlFor="input-first-name"
                                >
                                  {item?.DisplayName}
                                </label>
                              </Col>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end padding-manage"
                              >
                                {active !==item.categoryId ?  <div
                                  className="btn btn-lg btn-icon-edit"
                                  onClick={() => handleactive(item.categoryId,"new")}
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 100.000000 100.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M807 920 c-14 -11 -29 -20 -34 -20 -4 0 -153 -145 -329 -322 l-321
                                     -323 -33 -88 c-22 -60 -29 -92 -22 -99 7 -7 39 0 99 22 l88 33 323 321 c177
                                     176 322 325 322 329 0 5 9 20 20 34 27 35 26 78 -5 108 -30 31 -73 32 -108 5z
                                     m81 -32 c17 -17 15 -43 -4 -58 -13 -11 -19 -9 -41 12 -16 16 -23 32 -20 42 7
                                     19 48 21 65 4z m-75 -74 c20 -19 37 -38 37 -41 0 -4 -10 -17 -22 -30 l-22 -23
                                       -43 42 -43 42 22 23 c12 13 24 23 27 23 3 0 23 -16 44 -36z m-78 -84 l39 -40
                                      -254 -255 c-140 -140 -260 -255 -267 -255 -6 0 -13 12 -15 27 -2 22 -9 29 -31
                                     31 -15 2 -27 9 -27 15 0 11 499 517 510 517 3 0 24 -18 45 -40z m-539 -534 c1
                                  0 5 -11 8 -23 6 -19 2 -25 -19 -33 -54 -21 -66 -9 -45 45 8 21 14 25 33 19 12 -3 23 -7 23 -8z"/>
                                    </g>
                                  </svg>
                                </div>:null}
                           
                              </Col>
                            </Row>
                            {active === item.categoryId ? (
                              formValues?.map((item2, i2) => (
                                <Row key={i2}>
                                  <Col lg="5">
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Sub Category
                                      </label>
                                      <Input
                                        type="select"
                                        name="role_id"
                                        id="selectMulti"
                                        value={item2.Id}
                                        onChange={(e) =>
                                          handleInput(e, i2, "id")
                                        }
                                      >
                                        <option value="">
                                          Choose from Sub category
                                        </option>
                                        {subChildArrayList?.map((item3, i3) => (
                                          <option value={item3.Id}
                                           key={i3}
                                           className={formValues.some(obj => obj.Id === item3.Id)?"option-select-property":null}
                                           >
                                            {item3.amazonDisplayName}
                                          </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="5">
                                    <FormGroup className="form-group">
                                      <label
                                        className="form-control-label"
                                        // htmlFor="input-first-name"
                                      >
                                        Display Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Display Name"
                                        value={item2.DisplayName}
                                        onChange={(e) =>
                                          handleInput(e, i2, "name")
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  {formValues?.length>1 && (
                                    <Col lg="2">
                                      <div
                                        className="btn btn-lg btn-icon"
                                        onClick={() => handleDelete(i2)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="currentColor"
                                          className="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fillRule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              ))
                            ) : (
                              <Row>
                                <Col lg="5">
                                  <FormGroup className="form-group">
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Sub Category
                                    </label>
                                    <Input
                                      type="select"
                                      name="role_id"
                                      id="selectMulti"
                                      disabled
                                      // value={userCreateUpdate.role_id}
                                      // onChange={(e) => handleInput(e)}
                                    >
                                      <option value="">
                                        Choose from Sub category
                                      </option>

                                      {/* {roles.map((role) => {
                                return (
                                  <option value={role.id} key={role.id}>
                                    {role.role}
                                  </option>
                                );
                              })} */}
                                    </Input>
                                    {/* {error.role_id ? (
                              <ErrorMessage errorMsg={error.role_id} />
                            ) : null} */}
                                  </FormGroup>
                                </Col>
                                <Col lg="5">
                                  <FormGroup className="form-group">
                                    <label
                                      className="form-control-label"
                                      // htmlFor="input-first-name"
                                    >
                                      Display Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Display Name"
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}

                            <Row>
                              <Col
                                lg="4"
                                className=""
                              >
                                 {errorcategory!=="" && i===0 ? (
                                 <ErrorMessage errorMsg={errorcategory} />
                               ) : null}
                                <Button
                                  className="add-more-icon "
                                  onClick={() => handlemore(item.categoryId)}
                                  disabled={active !== item.categoryId}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                  </svg>
                                  <span>Add More</span>
                                </Button>
                                
                              </Col>
                              <Col lg="4">
                              <Button
                                  color="warning"
                                  className="no-transform ml-4"
                                  onClick={handleImage}
                                >
                                  Add Image
                                </Button>
                              
                              </Col>
                              <Col lg="4"
                                className="d-flex justify-content-end">
                                  <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled={active !== item.categoryId}
                                  onClick={handleSave}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>

                            <hr className="my-4" />
                          </div>
                        ))}
                        {existingCategoryList?.length >0 && existingCategoryList?.map((data)=>(
                          <div key={data?.categoryId}>
                             <Row>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end"
                              >
                                <label
                                // className="form-control-label"
                                // htmlFor="input-first-name"
                                >
                                  {data?.DisplayName}
                                </label>
                              </Col>
                              <Col
                                lg="6"
                                className="d-flex justify-content-end padding-manage"
                              >
                                <div
                                  className="btn btn-lg btn-icon-edit"
                                  onClick={() => handleactive(data?.categoryId,"exist")}
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 100.000000 100.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                                      fill="#000000"
                                      stroke="none"
                                    >
                                      <path
                                        d="M807 920 c-14 -11 -29 -20 -34 -20 -4 0 -153 -145 -329 -322 l-321
                                     -323 -33 -88 c-22 -60 -29 -92 -22 -99 7 -7 39 0 99 22 l88 33 323 321 c177
                                     176 322 325 322 329 0 5 9 20 20 34 27 35 26 78 -5 108 -30 31 -73 32 -108 5z
                                     m81 -32 c17 -17 15 -43 -4 -58 -13 -11 -19 -9 -41 12 -16 16 -23 32 -20 42 7
                                     19 48 21 65 4z m-75 -74 c20 -19 37 -38 37 -41 0 -4 -10 -17 -22 -30 l-22 -23
                                       -43 42 -43 42 22 23 c12 13 24 23 27 23 3 0 23 -16 44 -36z m-78 -84 l39 -40
                                      -254 -255 c-140 -140 -260 -255 -267 -255 -6 0 -13 12 -15 27 -2 22 -9 29 -31
                                     31 -15 2 -27 9 -27 15 0 11 499 517 510 517 3 0 24 -18 45 -40z m-539 -534 c1
                                  0 5 -11 8 -23 6 -19 2 -25 -19 -33 -54 -21 -66 -9 -45 45 8 21 14 25 33 19 12 -3 23 -7 23 -8z"/>
                                    </g>
                                  </svg>
                                </div>
                              </Col>
                            </Row>
                            {data?.subCategoryList?.map((data2)=>(
                                 <Row key={data2?.categoryId}>
                                 <Col lg="5">
                                   <FormGroup className="form-group">
                                     <label
                                       className="form-control-label"
                                       htmlFor="input-first-name"
                                     >
                                       Sub Category
                                     </label>
                                     <Input
                                       type="select"
                                       name="role_id"
                                       id="selectMulti"
                                       disabled
                                       // value={userCreateUpdate.role_id}
                                       // onChange={(e) => handleInput(e)}
                                     >
                                       <option value={data2?.categoryId}>
                                         {data2.amazonDisplayName}
                                       </option>
      
                                      
                                     </Input>
                                     
                                   </FormGroup>
                                 </Col>
                                 <Col lg="5">
                                   <FormGroup className="form-group">
                                     <label
                                       className="form-control-label"
                                       // htmlFor="input-first-name"
                                     >
                                       Display Name
                                     </label>
                                     <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Display Name"
                                       disabled
                                       value={data2.DisplayName}
                                     />
                                   </FormGroup>
                                 </Col>
                               </Row>
                            ))}
                         
                         <Row>
                              <Col
                                lg="12"
                                className="d-flex justify-content-between"
                              >
                                <Button
                                  className="add-more-icon"
                                  // onClick={() => handlemore(item.categoryId)}
                                  disabled
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                  </svg>
                                  <span>Add More</span>
                                </Button>
                                <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled
                                  // onClick={handleSave}
                                >
                                  Add Image
                                </Button>
                                <Button
                                  color="warning"
                                  className="no-transform mr-6"
                                  disabled
                                  // onClick={handleSave}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                            <hr className="my-4" />
                         </div>
                        ))}

                        <FormGroup className="d-flex justify-content-between">
                          <Button
                            color="info"
                            className="no-transform"
                            onClick={() =>
                              navigate(
                                `/categorystep?step=3&topParentId=${searchParams.get(
                                  "topParentId"
                                )}`
                              )
                            }
                          >
                            &#10229; Previous Step
                          </Button>
                          <Button
                            color="info"
                            className="no-transform"
                            onClick={handlenext}
                          >
                            Next Step &#10230;
                          </Button>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
        {UploadImageModal ?
       <CategoryStep4Modal
       isOpen={UploadImageModal}
       toggle={() => setUploadImageModal(false)}
      categoryId={active}
     ></CategoryStep4Modal>
    :null
    }
    </AdminLayout>
  );
};

export default CategoryStep4;
