import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import ConfirmationDialog from "../../components/confirmation.dialog";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import AdminLayout from "../../layouts/AdminLayout";
import useConditiongetService from "../../services/conditiongetService";
import useDeleteService from "../../services/deleteservice";
import useGetService from "../../services/getservice";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useDebounce from "../../components/custom-hooks/useDebounce";
import Loader from "../../components/Loader/Loader";
import { validate_email } from "../../helpers/utils";
import ViewDetailsModal from "./ViewDetailsModal";
import AddPhysicianModal from "./AddPhysicianModal";
import EditPhysicianModal from "./EditPhysicianModal";

const PhysiciansList = () => {
  // const [addedPhysician, setAddedPhysician] = useState(false);
  // const [updatedPhysician, setUpdatedPhysician] = useState(false);
  const [deletePhysician, setDeletePhysician] = useState(false);
  let [userModal, setUserModal] = useState(false);
  const [addPhysician, setAddPhysician] = useState({
    first_name: "",
    last_name: "",
    phone: 0,
    email: "",
    created_by: 1,
    role: 0,
    status: 1,
    city: '',
    state: '',
    street_address: '',
    zipcode: '',
    npi_number: ''
  });
  const [editModal, setEditModal] = useState("");
  const [physicianList, setPhysicianList] = useState([]);

  const [roleList, setRoleList] = useState([]);

  const [selectedPhysicianList, setSelectedPhysicianList] = useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [sortName, setSortName] = useState("");

  const [asc, setAsc] = useState("asc");
  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);

  // const [error, setError] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   created_by: 1,
  //   phone: "",
  //   role: 0,
  //   city: '',
  //   state: '',
  //   street_address: '',
  //   zipcode: '',
  //   npi_number: ''
  // });

  const [getListData, setListData] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      setListData(true);
      setSearchInput(debouncedSearchTerm);
    }
    else if (debouncedSearchTerm === "" && searchInput === "") {
      setListData(true);
      setSearchInput("");
    }
  }, [debouncedSearchTerm]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
  };

  useEffect(() => {
    if (asc === "asc") {
      let sorted = physicianList.sort((a, b) => a.id - b.id);
      setFilteredResults(sorted);
    } else {
      let sorted = physicianList.sort((a, b) => b.id - a.id);
      setFilteredResults(sorted);
    }
  }, [asc]);

  const handleSort = (sort) => {
    setSortName(sort);
    if (asc === "asc") {
      setAsc("desc");
    } else {
      setAsc("asc");
    }
    setListData(true);
  };


  const { response } = useGetService(`/api/case/getAllCaseViewList`);

  const { response2: getPhysicians, isFetchItems } = useConditiongetService(
    `api/attendee/physicians?sort_name=${sortName}&sort_order=${asc}&search=${searchInput}&size=20&page=${page}`,
    getListData
  );
  // const { response, refetch } = useGetService(`api/attendee/physicians`);
  const deletedPhysician = useDeleteService(
    `api/attendee/delete_physician/${selectedPhysicianList?.id}`,
    deletePhysician
  );

  // let requestAttorney = {
  //   first_name: addPhysician.first_name,
  //   last_name: addPhysician.last_name,
  //   // phone: addPhysician.phone,
  //   email: addPhysician.email,
  //   // role_id: addPhysician.role[0]?.value,
  //   created_by: 1,
  // }

  // let updateRequestAttorney = {
  //   first_name: addPhysician.first_name,
  //   last_name: addPhysician.last_name,
  //   phone: addPhysician?.phone?.length == 0 ? null : addPhysician?.phone,
  //   email: addPhysician.email,
  //   role_id: String(addPhysician.role),
  //   is_suspend: addPhysician.status + '',
  //   city: addPhysician.city,
  //   state: addPhysician.state,
  //   street_address: addPhysician.street_address,
  //   zipcode: addPhysician.zipcode,
  //   npi_number: addPhysician.npi_number
  // }

  // const addNewPhysician = usePostService(
  //   requestAttorney,
  //   "api/attendee/add_physician",
  //   addedPhysician
  // );
  // const updatePhysician = usePutService(
  //   updateRequestAttorney,
  //   `api/attendee/update_physician/${selectedPhysicianList?.id}`,
  //   updatedPhysician
  // );

  let viewUser = (phycisins) => {
    setSelectedPhysicianList(phycisins);
    setUserModal(true);
  };
  let [createModal, setCreateModal] = useState(false);
  let createPhysicians = (id) => {
    setCreateModal(true);
  };

  useEffect(() => {
    if (response.status === "200-") {
      setRoleList(response?.data?.data?.allRoleList.map(roleItem => ({ value: roleItem?.id, label: roleItem?.role })));
      setListData(true);
    }
  }, [response]);

  useEffect(() => {
    if (getPhysicians?.status === "200-" && getListData) {
      setPhysicianList(getPhysicians.data.data);
      settotalPage(getPhysicians.data.totalPages);
      setListData(false);
    }
  }, [getPhysicians]);

  // useEffect(() => {
  //   if (addNewPhysician.status !== "200-" && addedPhysician) {
  //     toast.error(addNewPhysician.data.message);
  //   } else if (addNewPhysician !== "") {
  //     toast.success("Physician invitation was sent successfully.");
  //     setCreateModal(false);
  //     setListData(true);
  //   }
  //   setAddedPhysician(false);
  // }, [addNewPhysician]);

  // useEffect(() => {
  //   if (updatePhysician.status !== "200-" && updatePhysician !== "") {
  //     toast.error("Physician Updated Error");
  //   } else if (updatePhysician !== "") {
  //     toast.success("Physician Updated Successfully");
  //     setCreateModal(false);
  //     setListData(true);
  //   }
  //   setUpdatedPhysician(false);
  // }, [updatePhysician]);

  useEffect(() => {
    if (deletedPhysician?.status !== "200-" && deletedPhysician !== "") {
      toast.error("Physician Deleted Error");
    } else if (deletedPhysician !== "") {
      toast.success("Physician Deleted Successfully");
      setListData(true);
    }
    setSelectedPhysicianList(null);
    setDeletePhysician(false);
  }, [deletedPhysician]);

  // const changeHandler = (e) => {
  //   const temp = { ...addPhysician };
  //   temp[e.target.name] = e.target.value;
  //   setAddPhysician(temp);
  // };

  const handleDelete = (id) => {
    setDeletePhysician(true);
  };

  // const validateCreateUpdateForm = () => {
  //   const { first_name, last_name, email, phone, npi_number } = addPhysician;
  //   let isError = false,
  //     errFirstName = "",
  //     errLastName = "",
  //     errEmail = "",
  //     errPhone = "",
  //     npierr = "";

  //   if (first_name?.trim().length < 3 || first_name?.trim()?.length > 10) {
  //     errFirstName = "Firstname can't be less than 3 characters and more than 10 characters";
  //     isError = true;
  //   }

  //   if (last_name?.trim().length < 3 || last_name?.trim()?.length > 10) {
  //     errLastName = "Lastname can't be less than 3 characters and more than 10 characters";
  //     isError = true;
  //   }

  //   if (!npi_number) {
  //     npierr = "NPI number can't be empty";
  //     isError = true;
  //   }

  //   if (!email || !validate_email(email)) {
  //     errEmail = "Enter an email";
  //     isError = true;
  //   } else {
  //     let lastAtPos = email.lastIndexOf("@");
  //     let lastDotPos = email.lastIndexOf(".");

  //     if (
  //       !(
  //         lastAtPos < lastDotPos &&
  //         lastAtPos > 0 &&
  //         email.indexOf("@@") === -1 &&
  //         lastDotPos > 2 &&
  //         email.length - lastDotPos > 2
  //       )
  //     ) {
  //       errEmail = "Enter a valid email";
  //       isError = true;
  //     }
  //   }
  //   if (editModal !== 'add') {
  //     if (phone !== '') {
  //       if (phone.toString().length != 10) {
  //         errPhone = "Phone must be 10 digit";
  //         isError = true;
  //       }
  //     }
  //   }

  //   setError({
  //     ...error,
  //     first_name: errFirstName,
  //     last_name: errLastName,
  //     email: errEmail,
  //     phone: errPhone,
  //     npi_number: npierr
  //   });
  //   return !isError;
  // };

  // const onChangeRoleHandler = (value) => {
  //   setAddPhysician((predata) => ({ ...predata, role: [value] }));
  // }

  const onPageChange = (value) => {
    setPage(value)
    setListData(true);
  }

  // const checkboxHandler = (e) => {
  //   const temp = { ...addPhysician };
  //   temp[e.target.name] = addPhysician.status == 0 ? 1 : 0;
  //   setAddPhysician(temp);
  // }

  // const onSubmitHandler = (evt) => {
  //   evt.preventDefault();
  //   if (validateCreateUpdateForm()) {
  //     if (editModal === "add") {
  //       setAddedPhysician(true);
  //     } else {
  //       setUpdatedPhysician(true);
  //     }
  //   }
  // }

  return (
    <AdminLayout name="Physicians Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => {
          setConfirmationModal(false);
          handleDelete();
        }}
      ></ConfirmationDialog>

      {/* <Modal
        className="modal-dialog-centered"
        isOpen={userModal}
        toggle={() => setUserModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Physician Information
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUserModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center" responsive>
            <tbody>
              <tr>
                <th scope="col">Id</th>
                <td>{selectedPhysicianList?.id}</td>
              </tr>
              <tr>
                <th scope="col">Name</th>
                <td>
                  {selectedPhysicianList?.first_name}{" "}
                  {selectedPhysicianList?.last_name}
                </td>
              </tr>
              <tr>
                <th scope="col">Email ID</th>
                <td> {selectedPhysicianList?.email}</td>
              </tr>
              <tr>
                <th scope="col">Phone No.</th>
                <td> {selectedPhysicianList?.phone || '--'}</td>
              </tr>
              <tr>
                <th scope="col">Role</th>
                <td> {roleList.find(roleItem => roleItem.value === selectedPhysicianList?.role_id)?.label}</td>
              </tr>
              <tr>
                <th scope="col">NPI Number</th>
                <td>{selectedPhysicianList?.npi_number || '--'}</td>
              </tr>
              <tr>
                <th scope="col">Street Address</th>
                <td>{selectedPhysicianList?.street_address || '--'}</td>
              </tr>
              <tr>
                <th scope="col">State</th>
                <td>{selectedPhysicianList?.state || '--'}</td>
              </tr>
              <tr>
                <th scope="col">City</th>
                <td>{selectedPhysicianList?.city || '--'}</td>
              </tr>
              <tr>
                <th scope="col">Zip Code</th>
                <td>{selectedPhysicianList?.zipcode || '--'}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal> */}
      {/* <Modal
        className="modal-dialog-centered"
        isOpen={createModal}
        toggle={() => {
          setAddPhysician({
            first_name: "",
            last_name: "",
            // phone: 0,
            email: "",
            created_by: 1,
          });
          setCreateModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editModal === "add" ? "Add" : "Update"} Physicians
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setAddPhysician({
                first_name: "",
                last_name: "",
                phone: 0,
                email: "",
              });
              setCreateModal(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form role="form" onSubmit={(e) => onSubmitHandler(e)}>
            <InputGroup className="mb-3">
              <Input
                defaultValue=""
                id="input-first-name"
                placeholder="First name"
                type="text"
                name="first_name"
                required
                maxLength={10}
                minLength={3}
                pattern="[A-Za-z]+"
                autoComplete="nope"
                value={addPhysician.first_name}
                onChange={(e) => {
                  if (!e.target.value.includes(' '))
                    changeHandler(e)
                }}
              />
              {error.first_name ? (
                <ErrorMessage errorMsg={error.first_name} />
              ) : null}
            </InputGroup>
            <InputGroup className="mb-3">
              <Input
                defaultValue=""
                id="input-last-name"
                placeholder="Last name"
                type="text"
                required
                maxLength={10}
                minLength={3}
                pattern="[A-Za-z]+"
                name="last_name"
                autoComplete="nope"
                value={addPhysician.last_name}
                onChange={(e) => {
                  if (!e.target.value.includes(' '))
                    changeHandler(e)
                }}
              />
              {error.last_name ? (
                <ErrorMessage errorMsg={error.last_name} />
              ) : null}
            </InputGroup>

            <InputGroup className="mb-3">
              <Input
                id="input-email"
                placeholder="Email"
                type="email"
                name="email"
                autoComplete="nope"
                value={addPhysician?.email}
                onChange={changeHandler}
              />
              {error.email ? <ErrorMessage errorMsg={error.email} /> : null}
            </InputGroup>

            {
              editModal !== 'add' && (<>
                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    autoComplete="nope"
                    value={addPhysician?.phone}
                    onChange={changeHandler}
                  />
                  {error.phone ? <ErrorMessage errorMsg={error.phone} /> : null}
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    type="select"
                    name="role"
                    id="selectMulti"
                    value={addPhysician?.role}
                    onChange={changeHandler}
                  >
                    {roleList.length > 0 && roleList.map(roleItem => {
                      return (<option value={roleItem?.value}>{roleItem?.label}</option>)

                    })}
                  </Input>
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="NPI Number"
                    type="text"
                    name="npi_number"
                    autoComplete="nope"
                    value={addPhysician?.npi_number}
                    onChange={changeHandler}
                  />
                  {error.npi_number ? <ErrorMessage errorMsg={error.npi_number} /> : null}
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="City"
                    type="text"
                    name="city"
                    autoComplete="nope"
                    value={addPhysician?.city}
                    onChange={changeHandler}
                  />
                  {error.city ? <ErrorMessage errorMsg={error.city} /> : null}
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="State"
                    type="text"
                    name="state"
                    autoComplete="nope"
                    value={addPhysician?.state}
                    onChange={changeHandler}
                  />
                  {error.state ? <ErrorMessage errorMsg={error.state} /> : null}
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="Zip Code"
                    type="text"
                    name="zipcode"
                    maxLength={6}
                    autoComplete="nope"
                    value={addPhysician?.zipcode}
                    onChange={changeHandler}
                  />
                  {error.zipcode ? <ErrorMessage errorMsg={error.zipcode} /> : null}
                </InputGroup>

                <InputGroup className="mb-3">
                  <Input
                    id="input-email"
                    placeholder="Street Address"
                    type="textarea"
                    name="street_address"
                    autoComplete="nope"
                    value={addPhysician?.street_address}
                    onChange={changeHandler}
                  />
                  {error.street_address ? <ErrorMessage errorMsg={error.street_address} /> : null}
                </InputGroup>

                <InputGroup>
                  <p className="ml-4">Suspend</p>
                  <Input
                    type="checkbox"
                    name="status"
                    id="selectMulti"
                    style={{ marginLeft: '0', width: '15px', height: '16px' }}
                    checked={addPhysician.status == 1 ? true : false}
                    onChange={checkboxHandler}
                  >
                  </Input>
                </InputGroup>

              </>)
            }

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="submit"
              >
                {editModal === "add" ? "Create" : "Update"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-0">Physicians List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-8 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "40%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          value={searchInput}
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                    <div>
                      <button onClick={() => handleSort()}>
                        <i className="fa fa-fw fa-sort"></i>
                        Sort
                      </button>
                    </div>
                  </div> */}
                    {/* <div className="col-md-2">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditModal("add");
                        createPhysicians(1);
                      }}
                    >
                      <h3 className="mb-0"> Send Invitation</h3>
                    </div>
                  </div> */}
                    <UncontrolledDropdown className="inline">
                      <DropdownToggle
                        className="btn-icon-only text-dark"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Active
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Inactive
                        </DropdownItem>

                        <DropdownItem
                          // href="javascript:void(0)"
                          onClick={() => {
                            setEditModal("add");
                            createPhysicians(1);
                          }}
                        >
                          Send Invitation
                        </DropdownItem>

                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col">
                      ID
                      <i
                        onClick={() => handleSort("id")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th> */}
                    <th scope="col">
                      Name
                      <i
                        onClick={() => handleSort("first_name")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Email ID
                      <i
                        onClick={() => handleSort("email")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Phone No
                    </th>
                    {/* <th scope="col">
                      NPI number
                    </th> */}
                    <th scope="col">Approved</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {
                  // searchInput.length > 1
                  //   ? physicianList.map((phycisins, index) => {
                  //     return (
                  //       <tr key={phycisins.id}>
                  //         <td>{phycisins.id}</td>
                  //         <td>
                  //           {phycisins.first_name} {phycisins.last_name}
                  //         </td>
                  //         <td>{phycisins.email}</td>
                  //         <td>{phycisins.phone}</td>
                  //         <td>
                  //           {(phycisins.is_active == 1) ? 'Active' : 'Inactive'}
                  //         </td>
                  //         <td className="text-right">
                  //           <UncontrolledDropdown>
                  //             <DropdownToggle
                  //               className="btn-icon-only text-light"
                  //               href="#pablo"
                  //               role="button"
                  //               size="sm"
                  //               color=""
                  //               onClick={(e) => e.preventDefault()}
                  //             >
                  //               <i className="fas fa-ellipsis-v" />
                  //             </DropdownToggle>
                  //             <DropdownMenu
                  //               className="dropdown-menu-arrow"
                  //               container="body"
                  //             >
                  //               <DropdownItem
                  //                 onClick={() => viewUser(phycisins)}
                  //               >
                  //                 View
                  //               </DropdownItem>
                  //               <DropdownItem
                  //                 onClick={() => {
                  //                   setEditModal("edit");
                  //                   setAddPhysician({
                  //                     first_name: phycisins.first_name,
                  //                     last_name: phycisins.last_name,
                  //                     phone: phycisins.phone,
                  //                     email: phycisins.email,
                  //                     role: phycisins.role_id,
                  //                   });
                  //                   createPhysicians(1);
                  //                   setSelectedPhysicianList(phycisins);
                  //                 }}
                  //               >
                  //                 Edit
                  //               </DropdownItem>
                  //               {/* <DropdownItem
                  //                 onClick={() => {
                  //                   setConfirmationModal(true);
                  //                   setSelectedPhysicianList(phycisins);
                  //                 }}
                  //               >
                  //                 Delete
                  //               </DropdownItem> */}
                  //             </DropdownMenu>
                  //           </UncontrolledDropdown>
                  //         </td>
                  //       </tr>
                  //     );
                  //   })
                  //   : 
                  physicianList.length > 0 &&
                  physicianList.map((phycisins, index) => {
                    return (
                      <tr key={phycisins.id}>
                        {/* <td>{phycisins.id}</td> */}
                        <td>
                          {phycisins.first_name} {phycisins.last_name}
                        </td>
                        <td>{phycisins.email}</td>
                        <td>{phycisins?.phone || '--'}</td>
                        <td>{(phycisins.is_approved == 1) ? 'Active' : 'Inactive'}</td>
                        <td>{(phycisins.is_suspend == 1) ? 'Suspend' : phycisins.is_active == 1 ? 'Active' : 'Inactive'}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem
                                onClick={() => viewUser(phycisins)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  setAddPhysician({
                                    first_name: phycisins.first_name,
                                    last_name: phycisins.last_name,
                                    phone: phycisins.phone || '',
                                    email: phycisins.email,
                                    role: phycisins.role_id,
                                    status: phycisins.is_suspend,
                                    // city: phycisins.city,
                                    city: phycisins?.city ? [{
                                      value: phycisins.city,
                                      label: phycisins.city,
                                    }] : [],
                                    // state: phycisins.state,
                                    state: phycisins?.state ? [{
                                      value: phycisins.state,
                                      label: phycisins.state,
                                    }] : [],
                                    street_address: phycisins.street_address,
                                    zipcode: phycisins.zipcode,
                                    npi_number: phycisins.npi_number
                                  });
                                  createPhysicians(1);
                                  setSelectedPhysicianList(phycisins);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              {/* <DropdownItem
                                href="#"
                              onClick={() => {
                                setSelectedId(user.email);
                                setResetModal(true);
                              }}
                              >
                                Reset Password
                              </DropdownItem> */}
                              {/* <DropdownItem
                                onClick={() => {
                                  setConfirmationModal(true);
                                  setSelectedPhysicianList(phycisins);
                                  // {

                                  // }
                                  // // onClick={() => handleDelete(court?.id)}
                                }}
                              >
                                Delete
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                }
              </Table>
              <CardFooter className="py-4">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            // className="active"
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {userModal && <ViewDetailsModal title={'Physician Information'} isOpen={userModal} attorneyDetail={selectedPhysicianList} roleList={roleList} viewHandler={() => setUserModal(false)} />}

      {editModal === 'add' && <AddPhysicianModal isOpen={editModal === 'add'} addModalHandler={(isSuccess) => {
        if (isSuccess == 1) {
          setEditModal("")
          setListData(true)
        } else { setEditModal("") }
      }} />}

      {editModal === 'edit' && <EditPhysicianModal
        userId={selectedPhysicianList?.id}
        isOpen={editModal === 'edit'}
        roleList={roleList}
        attorneyDetails={addPhysician}
        editModalHandler={(isSuccess) => {
          if (isSuccess == 1) {
            setEditModal("")
            setListData(true)
          } else {
            setEditModal("")
          }
        }} />}

    </AdminLayout>
  );
};

export default PhysiciansList;
