import React, { useState, useEffect } from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import ListHeader from '../../components/Headers/ListHeader';
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader/Loader";
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
} from "reactstrap";
import useConditiongetService from '../../services/conditiongetService';
import useDebounce from '../../components/custom-hooks/useDebounce';
import { getFormattedDate } from '../../helpers/utils';

const PaymentManagement = () => {
    const navigate = useNavigate();
    const [sortName, setSortName] = useState("");

    const [asc, setAsc] = useState("");
    const [totalPage, settotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState("");
    const [paymentList, setPaymentList] = useState([]);

    const [getListData, setListData] = useState(false);

    const debouncedSearchTerm = useDebounce(searchInput, 500);

    const { response2, isFetchItems } = useConditiongetService(
        `/api/admin/payment/getAllPaymentList?sort_name=${sortName}&sort_order=${asc}&search=${debouncedSearchTerm}&size=${20}&page=${page}`,
        getListData
    );

    useEffect(() => {
        if (response2?.status == '200-' && getListData == true) {
            setListData(false)
        }
    }, [response2])


    useEffect(() => {
        if (debouncedSearchTerm !== "") {
            setListData(true);
            setSearchInput(debouncedSearchTerm);
        }
        else if (debouncedSearchTerm === "" && searchInput === "") {
            setListData(true);
            setSearchInput("");
        }
    }, [
        debouncedSearchTerm
    ]);

    useEffect(() => {
        if (response2 != "") {
            setPaymentList(response2.data.data);
            settotalPage(response2?.data?.totalPages);
            setListData(false);
        }
    }, [response2]);

    const handleSort = (sort) => {
        setSortName(sort);
        if (asc === "asc") {
            setAsc("desc");
        } else {
            setAsc("asc");
        }
        setListData(true);
        setPage(1);
    };

    const onPageChange = (value) => {
        setPage(value)
        setListData(true);
    }
const handleSearch=(e)=>{
 setSearchInput(e.target.value);
 setPage(1);
 setSortName("");
 setAsc("");
}
    return (
        <AdminLayout name="Payment Management">
            {isFetchItems && <Loader></Loader>}
            <ListHeader />


            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h3 className="mb-0">Payment List</h3>
                                    </div>
                                    <div
                                        style={{ textAlign: "right" }}
                                        className="col-md-8 align-right"
                                    >
                                        <div
                                            className="mb-0 form-group inline"
                                            style={{
                                                width: "40%",
                                                display: "inline-block",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <div className="input-group-alternative input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    placeholder="Search"
                                                    type="text"
                                                    className="form-control"
                                                    value={searchInput}
                                                    onChange={handleSearch}
                                                   // onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                                style={{
                                    marginBottom: "25px",
                                }}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">
                                            Deponent Name
                                        </th>
                                        <th scope="col">
                                            Payee Name
                                        </th>
                                        <th scope="col">
                                            Receiver
                                        </th>

                                        <th scope="col">Transaction Date
                                            <i
                                                onClick={() => handleSort("payment_received_date")}
                                                className="fa fa-fw fa-sort"
                                            ></i>
                                        </th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                {
                                    paymentList.length > 0 &&
                                    paymentList.map((payment, index) => {
                                        return (
                                            <tr key={payment.id}>
                                                <td>
                                                    {payment?.case_deposition?.deponent_name}
                                                </td>
                                                <td>{payment?.case_deposition?.user?.first_name} {payment?.case_deposition?.user?.last_name}</td>
                                                <td>{payment?.user?.first_name || ''} {payment?.user?.last_name || ''}</td>
                                                <td>{payment?.payment_received_date ? getFormattedDate(payment?.payment_received_date) : '--'}</td>
                                                <td>{payment?.status == '0' ? 'Pending' : payment?.status == '2' ? 'Cancel' : payment?.status == '3' ? 'Refund' : 'Paid'}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className="btn-icon-only text-light"
                                                            href="#pablo"
                                                            role="button"
                                                            size="sm"
                                                            color=""
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            <i className="fas fa-ellipsis-v" />
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-arrow"
                                                            container="body"
                                                        >
                                                            <DropdownItem
                                                                onClick={() => navigate(`/payment-management/payment-details/${payment.id}`)}
                                                            >
                                                                Change Payment Status
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </Table>
                            <CardFooter className="py-4">
                                {totalPage > 1 ?
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                // className="disabled"
                                                className={page < 2 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        // className="active"
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                // className="disabled"
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout>
    )
}

export default PaymentManagement;