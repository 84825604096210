import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Form,
  Input,
  Button,
} from "reactstrap";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useGetService from "../../services/getservice";
import usePutService from "../../services/putservice";

const CommissionManagementList = () => {
  const [commissionList, setCommissionList] = useState(null);

  const [updatedCommission, setUpdatedCommission] = useState(false);
  const [selectedCommissionList, setSelectedCommissionList] = useState(null);
  const [addCommission, setAddCommission] = useState({
    name: "Transcript File",
    commission: 0,
    updated_by: 1,
  });

  const { response: commissionManagement } = useGetService(
    `api/commission/getCommissionSetting`
  );

  const updateCommissionManagement = usePutService(
    addCommission,
    `api/commission/updateCommissionSetting/${selectedCommissionList?.id}`,
    updatedCommission
  );

  useEffect(() => {
    if (commissionManagement !== "") {
      setAddCommission({
        name: commissionManagement.data.data.name,
        commission: commissionManagement.data.data.commission,
        updated_by: 1,
      });
      setCommissionList(commissionManagement.data.data);
    }
  }, [commissionManagement]);

  useEffect(() => {
    if (
      updateCommissionManagement.status !== "200-" &&
      updateCommissionManagement !== ""
    ) {
      toast.error(updateCommissionManagement.data.message);
    } else if (updateCommissionManagement !== "") {
      toast.success(updateCommissionManagement.data.message);
    }
    setUpdatedCommission(false);
    setSelectedCommissionList(null);
  }, [updateCommissionManagement]);

  const changeHandler = (e) => {
    const temp = { ...addCommission };
    temp[e.target.name] = e.target.value;
    setAddCommission(temp);
  };
  return (
    <AdminLayout name=" Commission Management">
      {/* {loading && <Loader></Loader>} */}
      <ListHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="mb-0">Commission List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-3 align-right"
                  ></div>
                </div>
              </CardHeader>

              <div className="modal-body">
                <Form role="form">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Court Reporter Commission
                    </label>

                    <Input
                      defaultValue=""
                      id="commission"
                      placeholder="commission"
                      type="text"
                      name="commission"
                      value={addCommission.commission}
                      autoComplete="nope"
                      onChange={changeHandler}
                    />
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={() => {
                        setSelectedCommissionList(commissionList);
                        setUpdatedCommission(true);
                      }}
                    >
                      {" "}
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default CommissionManagementList;
