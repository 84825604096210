import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
import ConfirmationDialog from "../../components/confirmation.dialog";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useConditiongetService from "../../services/conditiongetService";
import useDeleteService from "../../services/deleteservice";
import useGetService from "../../services/getservice";
import useDebounce from "../../components/custom-hooks/useDebounce";
import Loader from "../../components/Loader/Loader";
import ViewDetailsModal from "../lawfirm_management/ViewDetailsModal";
import AddModal from "./AddModal";
import EditModal from "./EditModal";

const ExpertsList = () => {
  const [deletePhysician, setDeletePhysician] = useState(false);
  let [userModal, setUserModal] = useState(false);
  const [addPhysician, setAddPhysician] = useState({
    first_name: "",
    last_name: "",
    phone: 0,
    email: "",
    created_by: 1,
    role: 0,
    status: 1,
    city: '',
    state: '',
    zipcode: '',
    street_address: ''
  });
  const [editModal, setEditModal] = useState("");
  const [physicianList, setPhysicianList] = useState([]);

  const [roleList, setRoleList] = useState([]);

  const [selectedPhysicianList, setSelectedPhysicianList] = useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [sortName, setSortName] = useState("id");

  const [asc, setAsc] = useState("ASC");
  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const [getListData, setListData] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      setListData(true);
      setSearchInput(debouncedSearchTerm);
    }
    else if (debouncedSearchTerm === "" && searchInput === "") {
      setListData(true);
      setSearchInput("");
    }
  }, [debouncedSearchTerm]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
  };

  useEffect(() => {
    if (asc === "asc") {
      let sorted = physicianList.sort((a, b) => a.id - b.id);
      setFilteredResults(sorted);
    } else {
      let sorted = physicianList.sort((a, b) => b.id - a.id);
      setFilteredResults(sorted);
    }
  }, [asc]);

  const handleSort = (sort) => {
    setSortName(sort);
    if (asc === "asc") {
      setAsc("desc");
    } else {
      setAsc("asc");
    }
    setListData(true);
  };


  const { response } = useGetService(`/api/case/getAllCaseViewList`);

  const { response2: getExperts, isFetchItems } = useConditiongetService(
    `/api/attendee/getExperts?sort_name=${sortName}&sort_order=${asc}&search=${searchInput}&size=${20}&page=${page}`,
    getListData
  );

  const deletedPhysician = useDeleteService(
    `api/attendee/deleteExpert/${selectedPhysicianList?.id}`,
    deletePhysician
  );

  let viewUser = (phycisins) => {
    setSelectedPhysicianList(phycisins);
    setUserModal(true);
  };
  let [createModal, setCreateModal] = useState(false);
  let createPhysicians = (id) => {
    setCreateModal(true);
  };

  useEffect(() => {
    if (response?.status === "200-") {
      setRoleList(response?.data?.data?.allRoleList.map(roleItem => ({ value: roleItem?.id, label: roleItem?.role })));
      setListData(true);
    }
  }, [response]);

  useEffect(() => {
    if (getExperts?.status === "200-" && getListData) {
      setPhysicianList(getExperts.data.data);
      settotalPage(getExperts?.data?.totalPages);
      setListData(false);
    }
  }, [getExperts]);

  useEffect(() => {
    if (deletedPhysician.status !== "200-" && deletedPhysician !== "") {
      toast.error("Expert Deleted Error");
    } else if (deletedPhysician !== "") {
      toast.success("Expert Deleted Successfully");
      setListData(true);
    }
    setSelectedPhysicianList(null);
    setDeletePhysician(false);
  }, [deletedPhysician]);

  // const changeHandler = (e) => {
  //   const temp = { ...addPhysician };
  //   temp[e.target.name] = e.target.value;
  //   setAddPhysician(temp);
  // };

  const handleDelete = (id) => {
    setDeletePhysician(true);
  };

  const onPageChange = (value) => {
    setPage(value)
    setListData(true);
  }

  return (
    <AdminLayout name="Experts Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => {
          setConfirmationModal(false);
          handleDelete();
        }}
      ></ConfirmationDialog>

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-0">Experts List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-8 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "40%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          value={searchInput}
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                    <div>
                      <button onClick={() => handleSort()}>
                        <i className="fa fa-fw fa-sort"></i>
                        Sort
                      </button>
                    </div>
                  </div> */}
                    {/* <div className="col-md-2">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditModal("add");
                        createPhysicians(1);
                      }}
                    >
                      <h3 className="mb-0"> Send Invitation</h3>
                    </div>
                  </div> */}
                    <UncontrolledDropdown className="inline">
                      <DropdownToggle
                        className="btn-icon-only text-dark"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Active
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Inactive
                        </DropdownItem>

                        <DropdownItem
                          // href="javascript:void(0)"
                          onClick={() => {
                            setEditModal("add");
                            createPhysicians(1);
                          }}
                        >
                          Send Invitation
                        </DropdownItem>

                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col">
                      ID
                      <i
                        onClick={() => handleSort("id")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th> */}
                    <th scope="col">
                      Name
                      <i
                        onClick={() => handleSort("first_name")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Email ID
                      <i
                        onClick={() => handleSort("email")}
                        className="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Phone No
                      {/* <i
                        onClick={() => handleSort("phone")}
                        className="fa fa-fw fa-sort"
                      ></i> */}
                    </th>

                    <th scope="col">Approved</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {
                  // searchInput.length > 1
                  //   ? physicianList.map((phycisins, index) => {
                  //     return (
                  //       <tr key={phycisins.id}>
                  //         <td>{phycisins.id}</td>
                  //         <td>
                  //           {phycisins.first_name} {phycisins.last_name}
                  //         </td>
                  //         <td>{phycisins.email}</td>
                  //         <td>{phycisins.phone}</td>
                  //         <td>
                  //           {(phycisins.is_active == 1) ? 'Active' : 'Inactive'}
                  //         </td>
                  //         <td className="text-right">
                  //           <UncontrolledDropdown>
                  //             <DropdownToggle
                  //               className="btn-icon-only text-light"
                  //               href="#pablo"
                  //               role="button"
                  //               size="sm"
                  //               color=""
                  //               onClick={(e) => e.preventDefault()}
                  //             >
                  //               <i className="fas fa-ellipsis-v" />
                  //             </DropdownToggle>
                  //             <DropdownMenu
                  //               className="dropdown-menu-arrow"
                  //               container="body"
                  //             >
                  //               <DropdownItem
                  //                 onClick={() => viewUser(phycisins)}
                  //               >
                  //                 View
                  //               </DropdownItem>
                  //               <DropdownItem
                  //                 onClick={() => {
                  //                   setEditModal("edit");
                  //                   setAddPhysician({
                  //                     first_name: phycisins.first_name,
                  //                     last_name: phycisins.last_name,
                  //                     phone: phycisins.phone,
                  //                     email: phycisins.email,
                  //                     role: phycisins.role_id,
                  //                   });
                  //                   createPhysicians(1);
                  //                   setSelectedPhysicianList(phycisins);
                  //                 }}
                  //               >
                  //                 Edit
                  //               </DropdownItem>
                  //               {/* <DropdownItem
                  //                 onClick={() => {
                  //                   setConfirmationModal(true);
                  //                   setSelectedPhysicianList(phycisins);
                  //                 }}
                  //               >
                  //                 Delete
                  //               </DropdownItem> */}
                  //             </DropdownMenu>
                  //           </UncontrolledDropdown>
                  //         </td>
                  //       </tr>
                  //     );
                  //   })
                  //   : 
                  physicianList.length > 0 &&
                  physicianList.map((phycisins, index) => {
                    return (
                      <tr key={phycisins.id}>
                        {/* <td>{phycisins.id}</td> */}
                        <td>
                          {phycisins.first_name} {phycisins.last_name}
                        </td>
                        <td>{phycisins.email}</td>
                        <td>{phycisins?.phone || '--'}</td>
                        <td>{(phycisins.is_approved == 1) ? 'Active' : 'Inactive'}</td>
                        <td>{(phycisins.is_suspend == 1) ? 'Suspend' : phycisins.is_active == 1 ? 'Active' : 'Inactive'}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem
                                onClick={() => viewUser(phycisins)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  setAddPhysician({
                                    first_name: phycisins.first_name,
                                    last_name: phycisins.last_name,
                                    phone: phycisins?.phone || '',
                                    email: phycisins.email,
                                    role: phycisins?.role_id || 8,
                                    status: phycisins.is_suspend,
                                    // city: phycisins.city,
                                    city: phycisins?.city ? [{
                                      value: phycisins.city,
                                      label: phycisins.city,
                                    }] : [],
                                    // state: phycisins.state,
                                    state: phycisins?.state ? [{
                                      value: phycisins.state,
                                      label: phycisins.state,
                                    }] : [],
                                    street_address: phycisins.street_address,
                                    zipcode: phycisins.zipcode
                                  });
                                  createPhysicians(1);
                                  setSelectedPhysicianList(phycisins);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              {/* <DropdownItem
                                href="#"
                              onClick={() => {
                                setSelectedId(user.email);
                                setResetModal(true);
                              }}
                              >
                                Reset Password
                              </DropdownItem> */}
                              {/* <DropdownItem
                                onClick={() => {
                                  setConfirmationModal(true);
                                  setSelectedPhysicianList(phycisins);
                                  // {

                                  // }
                                  // // onClick={() => handleDelete(court?.id)}
                                }}
                              >
                                Delete
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                }
              </Table>
              <CardFooter className="py-4">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            // className="active"
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {userModal && <ViewDetailsModal title={'Expert Information'} isOpen={userModal} attorneyDetail={selectedPhysicianList} roleList={roleList} viewHandler={() => setUserModal(false)} />}

      {editModal === 'add' && <AddModal isOpen={editModal === 'add'} addModalHandler={(isSuccess) => {
        if (isSuccess == 1) {
          setEditModal("")
          setListData(true)
        } else { setEditModal("") }
      }} />}

      {editModal === 'edit' && <EditModal
        userId={selectedPhysicianList?.id}
        isOpen={editModal === 'edit'}
        roleList={roleList}
        attorneyDetails={addPhysician}
        editModalHandler={(isSuccess) => {
          if (isSuccess == 1) {
            setEditModal("")
            setListData(true)
          } else {
            setEditModal("")
          }
        }} />}

    </AdminLayout>
  );
};

export default ExpertsList;
